import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as Highcharts from 'highcharts';
import { DataPoint } from 'src/app/reports/reports-live/report-live-securitycase/chartModels';
import { graphconstants } from 'src/app/reports/reports-live/report-live-securitycase/graphconstants';
import { constants } from '../../constants';
import { Case } from '../../model/itsm';
import { WidgetService } from '../../services/widget.service';

@Component({
  selector: 'app-incidents-by-attribute',
  templateUrl: './incidents-by-attribute.component.html',
  styleUrls: ['./incidents-by-attribute.component.scss']
})
export class IncidentsByAttributeComponent implements OnInit {

  //data
  @Input()
  currentCases: Case[];
  @Input()
  previousCases: Case[];
  @Input()
  currentPeriodName: string;
  currentPeriodNameExpanded: string;
  @Input()
  previousPeriodName: string;
  previousPeriodNameExpanded: string;


  //navigation
  tarView: 'ring' | 'column' = 'ring';

  attributesCategories = [
    'no_impact',
    'confidentiality',
    'integrity',
    'availability',
  ];

  //Highcharts
  Highcharts: typeof Highcharts = Highcharts;

  //Pie Charts
  attributesDonutChartOptions = JSON.parse(JSON.stringify(graphconstants.pieChartOption));
  previousAttributesDonutChartOptions = JSON.parse(JSON.stringify(graphconstants.pieChartOption));
  updateDonutFlag = false;

  //Ring Chart
  columnComparisonChartOptions = JSON.parse(JSON.stringify(graphconstants.columnComparisonChartOptions));

  // show Cases
  showIncidentByAttributeCases: Case[] = [];
  showIncidentByAttributeColumnCase: Case[] = [];

  constructor(private translateService: TranslateService, private widgetService: WidgetService) {}

  ngOnInit(): void {
    this.currentPeriodNameExpanded = this.currentPeriodName === 'last30days' ?
      this.translateService.instant('pages.generic.last30days') :
      this.translateService.instant('pages.generic.lastFullCalendarMonth');
    this.previousPeriodNameExpanded = this.previousPeriodName === 'previous30days' ?
      this.translateService.instant('pages.generic.previous30days') :
      this.translateService.instant('pages.generic.previousFullCalendarMonth');

    const preparedData = this.prepareDataForTwoChart(this.currentCases, this.previousCases, 'attributes', 'Attributes', this.attributesCategories);
    this.attributesDonutChartOptions.series = [preparedData.dp1];
    this.attributesDonutChartOptions.title.text = '<strong>' + preparedData.totalCount1 + '<br/>cases</strong>';

    this.previousAttributesDonutChartOptions.series = [preparedData.dp2];
    this.previousAttributesDonutChartOptions.title.text = '<strong>' + preparedData.totalCount2 + '<br/>cases</strong>';

    //removing % from tooltip
    this.attributesDonutChartOptions.tooltip.pointFormat = '{point.name}: {point.y}';
    this.previousAttributesDonutChartOptions.tooltip.pointFormat = '{point.name}: {point.y}';

    this.attributesDonutChartOptions.plotOptions.series = {
      events : {
        click : (e) => {
          this.selectCasesByPeriod('current', e.point.name);
        }
      }
    };
    this.previousAttributesDonutChartOptions.plotOptions.series = {
      events : {
        click : (e) => {
          this.selectCasesByPeriod('previous', e.point.name);
        }
      }
    };

    //column comparison
    const attributesColors =  constants.graphColors;
    const getData = (data,current: boolean) => data.map((attribute, i) => ({
      ...attribute,
      color: current ? attributesColors[i] : 'rgba(158, 159, 163, 0.5)'
    }));
    const self = this;
    this.columnComparisonChartOptions.series = [{
      color: 'rgba(158, 159, 163, 0.5)',
      pointPlacement: -0.2,
      linkedTo: 'main',
      data: getData(preparedData.serie2, false),
      name: this.previousPeriodName,
      events :{
        click(e) {self.selectColumnCasesByPeriod('previous', e.point.options.name);}
      }
    }, {
      name: this.currentPeriodName,
      id: 'main',
      dataLabels: [{
          enabled: true,
          inside: true,
          style: {
              fontSize: '16px'
          }
      }],
      data: getData(preparedData.serie1, true),
      events :{
        click(e) {self.selectColumnCasesByPeriod('current', e.point.options.name);}
      }
    }];
    this.columnComparisonChartOptions.plotOptions.point = {
      events : {
        click: (e) => { },
        select: (e) => { }
      }
    };
    this.widgetService.updateWidgetStyle(this.previousAttributesDonutChartOptions, null);
    this.widgetService.updateWidgetStyle(this.attributesDonutChartOptions, null);
  }

  prepareDataForTwoChart(data1: Case[], data2: Case[], fieldName: string, dispName: string, categories: string[]){
    const dic = {};
    categories && categories.forEach(category => {
      dic[category] = {data1 :0, data2: 0};
    });

    data1.forEach((item) => {
      if (item[fieldName] && (!categories || categories.includes(item[fieldName]))){
        if(dic[item[fieldName].toLowerCase()]!= undefined){
          dic[item[fieldName].toLowerCase()].data1 = dic[item[fieldName].toLowerCase()].data1 + 1;
        }
        else{
          dic[item[fieldName].toLowerCase()] = { data1:1, data2:null};
        }
      }
    });
    data2.forEach((item) => {
      if (item[fieldName]&& (!categories || categories.includes(item[fieldName]))){
        if(dic[item[fieldName].toLowerCase()]==undefined) {
          dic[item[fieldName].toLowerCase()] = { data1:null, data2:1};
        }
        else if(dic[item[fieldName].toLowerCase()].data2 != undefined){
          dic[item[fieldName].toLowerCase()].data2 = dic[item[fieldName].toLowerCase()].data2 + 1;
        }
        else{
          dic[item[fieldName].toLowerCase()].data2 = 1;
        }
      }
    });

    let totalCount1 = 0;
    let totalCount2 = 0;
    const dp1 = new DataPoint();
    const dp2 = new DataPoint();
    dp1.name = dispName;
    dp1.type = 'pie';
    dp1.innerSize = '50%';
    dp1.data = [];
    dp2.name = dispName;
    dp2.type = 'pie';
    dp2.innerSize = '50%';
    dp2.data = [];
    Object.keys(dic).forEach((item: any, i)=>{
      if(dic[item]?.data1) {
        totalCount1 = totalCount1 + dic[item].data1;
      }
      if(dic[item]?.data2) {
        totalCount2 = totalCount2 + dic[item].data2;
      }
    });
    Object.keys(dic).forEach((item: any, i)=>{
      if(dic[item]?.data1) {
        dp1.data.push({
          name: item,
          y: dic[item].data1,
          color: constants.graphColors[i],
          percentage : dic[item].data1 / totalCount1,
        });
      }
      if(dic[item]?.data2) {
        dp2.data.push({
          name: item,
          y: dic[item].data2,
          color: constants.graphColors[i],
          percentage : dic[item].data2 / totalCount2
        });
      }
    });
    return {dp1, dp2, totalCount1, totalCount2, serie1: dp1.data, serie2: dp2.data};
  }

  selectCasesByPeriod(period: 'current' | 'previous', selected: string) {
    if(period === 'current') {
      this.showIncidentByAttributeCases = this.currentCases.filter( c => c.attributes === selected);
    } else {
      this.showIncidentByAttributeCases = this.previousCases.filter( c => c.attributes === selected);
    }
  }

  selectColumnCasesByPeriod(period: 'current' | 'previous', selected: string) {
    if(period === 'current') {
      this.showIncidentByAttributeColumnCase = this.currentCases.filter(c => c.attributes === selected);
    } else {
      this.showIncidentByAttributeColumnCase = this.previousCases.filter(c => c.attributes === selected);
    }
  }

  resetCases() {
    this.showIncidentByAttributeCases = [];
    this.showIncidentByAttributeColumnCase = [];
  }
}

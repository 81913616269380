<div class="col-12">
  <app-tabbar [currentTab]="tab" [tabs]="tabs"></app-tabbar>
  @if(tab === 'overview'){
  <div
    class="container bg-body-tertiary p-3 border-0 tab-content"
    style="max-width: 1800px"
  >
    <div class="row justify-content-between">
      <div class="col-12 col-md-6">
        <app-card class="h-100 mw-100">
          <app-areaovertime
            [isStacking]="true"
            [data]="incidentsOverTimedata"
            title="{{
              'pages.cybersocxdr.dashboard.fieldTitles.incidentbyseverityovertime'
                | translate
            }}"
            titleY="Number of incidents"
            suffix="incidents"
          />
        </app-card>
      </div>
      <div class="col-12 col-md-6 mt-3 m-md-0">
        <app-filtermenu>
          <app-card class="h-100">
            <app-incidentbyseveritycategory
              title="{{
                'pages.cybersocxdr.dashboard.fieldTitles.heatmapbyseverityandcategory'
                  | translate
              }}"
              [data]="categoryHeatMapData"
            />
          </app-card>
        </app-filtermenu>
      </div>
    </div>

    <div class="row">
      <div class="col-md-7 col-12">
        <div class="w-100">
          <app-filtermenu>
            <app-card class="h-100">
              <app-packedbubblehost
                [data]="bubbleHostnameData"
                title="{{
                  'pages.cybersocxdr.dashboard.fieldTitles.packedbubblecategoryandseverity'
                    | translate
                }}"
              />
            </app-card>
          </app-filtermenu>
        </div>
      </div>
      <div class="col-md-5 col-12 mt-md-0 mt-3">
        <app-card
          title="pages.cybersocxdr.dashboard.top5OpenedIncidentsBySeverity"
        >
          <div class="table-responsive">
            <table
              class="table table-striped table-hover"
              aria-describedby="top5OpenedIncidentsBySeverityTitle"
            >
              <thead>
                <tr>
                  <th scope="col">
                    {{ 'pages.cybersocxdr.dashboard.type' | translate }}
                  </th>
                  <th scope="col">
                    {{ 'pages.cybersocxdr.dashboard.severity' | translate }}
                  </th>
                  <th scope="col">
                    {{ 'pages.cybersocxdr.dashboard.count' | translate }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of top5OpenedIncidents | slice : 0 : 5">
                  <td>{{ item[0] }}</td>
                  <td>{{ item[1] }}</td>
                  <td>{{ item[2] }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </app-card>
      </div>
    </div>
  </div>
  } @else if (tab === 'splitView') {
  <div class="row gx-2 p-3">
    @if(repartitionClassifierNames.length > 1){
    <div class="row justify-content-between">
      <div class="col-md-8 col-12">
        <app-filtermenu>
          <app-card
            class="mw-100"
            title="{{
              'pages.cybersocxdr.dashboard.openedIncidentsBy'
                | translate
                  : {
                      groupBy:
                        'pages.cybersocxdr.filters.' +
                          repartitionClassifierNames[1]
                        | translate
                        | lowercase
                    }
            }}"
          >
            <app-barovertime
              title="{{
                'pages.cybersocxdr.dashboard.detailedView.incidentsbycountry'
                  | translate
              }}"
              [isStacking]="false"
              [data]="incidentsByEntityData"
              suffix="jours"
              titleY="Opened incident"
            />
          </app-card>
        </app-filtermenu>
      </div>
      <div class="col-md-4 col-12 mt-3 mt-md-0">
        <app-card
          title="{{
            'pages.cybersocxdr.dashboard.top5OpenedIncidentsBy'
              | translate
                : {
                    groupBy:
                      'pages.cybersocxdr.filters.' +
                        repartitionClassifierNames[1]
                      | translate
                      | lowercase
                  }
          }}"
        >
          <div class="table-responsive">
            <table
              class="table table-striped table-hover"
              aria-describedby="topIncidentsByEntityTitle"
            >
              <thead>
                <tr>
                  <th scope="col">
                    {{
                      'pages.cybersocxdr.filters.' +
                        repartitionClassifierNames[1]
                        | translate
                        | titlecase
                    }}
                  </th>
                  <th scope="col">
                    {{ 'pages.cybersocxdr.dashboard.nbIncidents' | translate }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of topIncidentByEntity | slice : 0 : 5">
                  <th scope="row">{{ item.name }}</th>
                  <td>{{ item.volume }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </app-card>
      </div>
    </div>
    } @if(repartitionClassifierNames.length > 0){
    <div class="row mb-3 d-flex justify-content-between">
      <div class="col-md-5 col-12 ms-1 d-flex align-items-center">
        <h3 class="m-0">
          {{
            'pages.cybersocxdr.dashboard.view'
              | translate
                : {
                    field:
                      'pages.cybersocxdr.filters.' +
                        repartitionClassifierNames[0]
                      | translate
                      | titlecase
                  }
          }}
        </h3>
      </div>
    </div>
    <div class="row justify-content-between">
      <div class="col-md-8 col-12">
        <app-card class="mw-100">
          <app-worldmap
            title="{{
              'pages.cybersocxdr.dashboard.detailedView.incidentsbycountry'
                | translate
            }}"
            titleY="Handled Incidents"
            suffix="incidents"
            [data]="incidentsRepartitionData"
          />
        </app-card>
      </div>
      <div class="col-md-4 col-12 mt-md-0 mt-3">
        <app-card
          title="{{
            'pages.cybersocxdr.dashboard.top5OpenedIncidentsBy'
              | translate
                : {
                    groupBy:
                      'pages.cybersocxdr.filters.' +
                        repartitionClassifierNames[0]
                      | translate
                      | lowercase
                  }
          }}"
        >
          <div class="table-responsive">
            <table
              class="table table-striped table-hover"
              aria-describedby="topIncidentsByCountryTitle"
            >
              <thead>
                <tr>
                  <th scope="col">
                    {{
                      'pages.cybersocxdr.filters.' +
                        repartitionClassifierNames[0] | translate
                    }}
                  </th>
                  <th scope="col">
                    {{ 'pages.cybersocxdr.dashboard.nbIncidents' | translate }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of topIncidentByCountry | slice : 0 : 5">
                  <th scope="row">{{ item.name }}</th>
                  <td>{{ item.volume }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </app-card>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4 col-12">
        <app-filtermenu>
          <app-card class="mw-100">
            <app-pie
              [data]="groupedDashboardIncidentsRepartition"
              [drilldownSeries]="groupedDashboardIncidentsRepartitionDrilldown"
              title="{{
                'pages.cybersocxdr.dashboard.handledIncidentsRepartition'
                  | translate
              }}"
              innerSize="0"
              suffix=""
            />
          </app-card>
        </app-filtermenu>
      </div>
      <div class="col-md-8 col-12 mt-md-0 mt-3">
        <app-filtermenu>
          <app-card
            class="mw-100"
            title="{{
              'pages.cybersocxdr.dashboard.openedIncidentsBy'
                | translate
                  : {
                      groupBy:
                        'pages.cybersocxdr.filters.' +
                          repartitionClassifierNames[0]
                        | translate
                        | lowercase
                    }
            }}"
          >
            <div class="mb-4">
              <app-barovertime
                title="{{
                  'pages.cybersocxdr.dashboard.detailedView.incidentsbycountry'
                    | translate
                }}"
                [isStacking]="false"
                [data]="incidentsByCountryData"
                suffix="jours"
                titleY="Opened incident"
              />
            </div>
          </app-card>
        </app-filtermenu>
      </div>
    </div>
    }
  </div>
  }
</div>

/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Output, OnInit, ViewEncapsulation, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { UserClaims } from '@okta/okta-auth-js';
import { UserAuthService } from 'src/app/shared/services/user-auth.service';
import { constants } from 'src/app/shared/constants';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ContextService } from 'src/app/shared/services/context-service';
import { OktaUserService } from 'src/app/shared/services/oktauser.service';
import { UserPreferenceService } from 'src/app/shared/services/userpreferences.service';
import { UtilsService } from 'src/app/shared/services/utils.service';

interface UserObj extends UserClaims { groups: string[] };

@Component({
  selector: 'app-user-account',
  templateUrl: './user-account.component.html',
  styleUrls: ['./user-account.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserAccountComponent implements OnInit {

  @Output() clickFile = new EventEmitter<File>();
  @Output() refreshFile = new EventEmitter<File>();

  public email: string;
  public tenants: string[];
  public userTenants: string[];
  public adminTenants: string[];

  private roles: string[];
  private id: string;
  private picture: File;

  public url;
  public profilePictureAction = '';

  public pageLoading = true;
  public updating = false;
  public maxFileSize = false;
  public maxFileSizeValue = constants.profilePictureMaxMoSize;
  public errorFile: string;

  public userRoles = {
    SOC_User: [],
    CyberSOC_User: [],
    Report_Viewing_Permissions: [],
    Permitted_Actions: [],
    Additional_Permissions: []
  };

  public userForm = new FormGroup({
    email: new FormControl({ value: '', disabled: true }),
    verified: new FormControl({ value: false, disabled: true }),
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required)
  });

  constructor(
    private userAuthService: UserAuthService,
    private oktaUser: OktaUserService,
    private alertService: AlertService,
    public contextService: ContextService,
    private userPreferenceService: UserPreferenceService,
    private translateService: TranslateService,
    private utilsService: UtilsService
  ) { }

  get firstName() { return this.userForm.get('firstName'); }
  get lastName() { return this.userForm.get('lastName'); }

  async ngOnInit() {
    this.pageLoading = true;

    // init component with user details
    const details = this.userAuthService.userDetails;
    this.email = details.email;

    this.userForm.setValue({ email: details.email, verified: details.email_verified, firstName: details.given_name, lastName: details.family_name });

    this.tenants = details.groups
      .filter((g: string) => g.startsWith('USERS_') || g.startsWith('ADMINS_'))
      .map(g => g.split('_')[1])
      .filter((g, i, arr) => arr.indexOf(g) === i);
    this.userTenants = details.groups.filter((g: string) => g.startsWith('USERS_')).map(g => g.split('_')[1]);
    this.adminTenants = details.groups.filter((g: string) => g.startsWith('ADMINS_')).map(g => g.split('_')[1]);

    this.roles = details.groups
      .filter((g: string) => g.startsWith('APPROLE_') || g.startsWith('COMPROLE_'))
      .map(g => /^[^_]*_[^_]*_(.*)$/.exec(g)[1]);
    this.distributeRoles(this.roles);
    this.id = details.sub;

    // load profile picture
    this.userPreferenceService.loadProfilePicture().then(
      () => {
        this.url = this.userPreferenceService.profilePicture;
        this.pageLoading = false;
      }
    );

    this.pageLoading = false;
  }

  submit() {
    let promises: Promise<any>[] = [];
    this.updating = true;

    if (this.userForm.dirty) {
      promises.push(this.oktaUser.updateUserProfile(this.id, this.userForm.value.firstName, this.userForm.value.lastName));
    }

    // check if user made action on his picture profile
    if (this.profilePictureAction === ProfilPictureAction.updated) {
      promises.push(this.userPreferenceService.updateProfilePicture(this.picture));
    } else if (this.profilePictureAction === ProfilPictureAction.deleted) {
      promises.push(this.userPreferenceService.deleteProfilePicture());
    }

    this.valid(Promise.all(promises))

  }

  /**
   * run all update and display alert 
   */
  async valid(promises: Promise<any[]>) {

    try {
      await promises;
      this.alertService.addSuccess(this.translateService.instant('pages.dashboard.user.userUpdated'));
    } catch (error) {
      this.alertService.handlerError(error);
    } finally {
      this.updating = false;
    }

    this.profilePictureAction = '';

  }

  distributeRoles(roles: string[]) {
    this.userRoles.SOC_User = roles.filter(r =>
      r === 'Access_SOC_Role' ||
      r === 'Access_SOC_Reports'
    );
    this.userRoles.CyberSOC_User = roles.filter(r =>
      r === 'Access_CSOC_Role' ||
      r === 'Access_CSOC_Reports'
    );
    this.userRoles.Report_Viewing_Permissions = roles.filter(r =>
      r === 'Access_Restricted_Reports' ||
      r === 'Access_Internal_Reports' ||
      r === 'Access_Confidential_Reports'
    );
    this.userRoles.Permitted_Actions = roles.filter(r =>
      r === 'Access_Change_Request' ||
      r === 'Create_Change_Request' ||
      r === 'Access_Service_Request' ||
      r === 'Create_Service_Request' ||
      r === 'Access_Incident' ||
      r === 'Create_Incident'
    );
    this.userRoles.Additional_Permissions = roles.filter(r =>
      r === 'WorldWatch' ||
      r === 'MicroSOCXDR'
    );
  }

  public onFileClick(file) {
    this.clickFile.emit(file);
  }

  public onRefreshFile(file) {
    this.refreshFile.emit(file);
  }

  /**
   * on file drop handler
   */
  public onFileDropped($event) {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  public fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  public deleteFile() {
    delete this.picture;
    delete this.url;
    this.maxFileSize = false;
    this.profilePictureAction = ProfilPictureAction.deleted;
  }

  /**
  * Convert Files list to normal array list
  * @param files (Files List)
  */
  async prepareFilesList(files: File[]) {

    const authorizedExtension = ['png', 'jpg']
    this.errorFile = undefined;

    this.picture = files[0];
    const fileExtension = this.picture.name.split(".").pop().toLowerCase();

    if (!authorizedExtension.includes(fileExtension)) {
      // unauthorized file extension
      this.errorFile = this.translateService.instant('pages.dashboard.user.fileExtension');
    } else if ((this.picture.size / 1000) > this.maxFileSizeValue) {
      // file too big
      this.errorFile = this.translateService.instant('pages.dashboard.user.fileMaxSize', { size: this.maxFileSizeValue });
    } else {
      this.profilePictureAction = ProfilPictureAction.updated;
      this.url = await this.utilsService.getImageAsUrl(files[0]);
    }
  }

}


export class Picture {
  file: File;
  status: boolean;
}

export enum ProfilPictureAction {
  updated = 'updated',
  deleted = 'deleted'
}


<div
  [ngClass]="['rounded', isDarkThemeEnabled ? 'bg-black' : 'bg-white']"
  [attr.style]="
    isDarkThemeEnabled ? 'background-color: rgb(20,20,20) !important;' : ''
  "
>
  @if ((actionRoute && actionTitle) || title) {
  <div class="d-flex justify-content-between flex-wrap align-items-center">
    @if (title) {
    <h3 class="m-0">
      {{ title | translate }}
    </h3>
    }
    <!-- Link to another page -->
    @if (actionRoute && actionTitle) {
    <div style="display: flex">
      <a
        class="link-chevron"
        (click)="changeCreatedFromDateAndNavigate()"
        style="
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          cursor: pointer;
        "
        [attr.title]="getTitle() | translate"
      >
        <!-- Ajustez max-width selon les besoins -->
        {{ countOlderThan1Month }} {{ actionTitle | translate }}
      </a>
    </div>
    }
    <!-- Optional children for those who need actions on the top bar -->
    <ng-content select="[action]" />
  </div>
  }

  <ng-content />
</div>

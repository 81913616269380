import { CommonModule } from '@angular/common';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ContextService } from 'src/app/shared/services/context-service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-xtended-faq-list',
  standalone: true,
  imports: [CommonModule, FontAwesomeModule, TranslateModule],
  templateUrl: './xtended-faq-list.component.html',
  styleUrls: ['./xtended-faq-list.component.scss'],
})
export class XtendedFaqListComponent {
  @Input() faqs: Array<{ question: string; answer: string }> = [];
  @Output() faqSelected = new EventEmitter<number>();

  constructor(public contextService: ContextService) {}

  viewFaq(index: number) {
    this.faqSelected.emit(index);
  }
}

<app-card
  title="pages.cybersocxdr.last24h.last24HoursInformations"
  actionTitle="pages.cybersocxdr.last24h.viewAllIncidents"
  actionRoute="/xtended-soc/incidents"
>
  <div class="row">
    <div class="d-flex flex-wrap gap-3">
      <div class="fw-bold d-flex align-items-center gap-2">
        <span class="badge rounded-pill fs-5 text-bg-primary">{{
          last24HData?.criticalIncidents ?? ''
        }}</span>

        <app-route-withfacets
          actionTitle=" {{
            'pages.cybersocxdr.last24h.criticalIncidents' | translate
          }}"
          actionRoute="/xtended-soc/incidents"
          buttonId="criticalIncidents"
        >
        </app-route-withfacets>
      </div>
      <div class="fw-bold d-flex align-items-center gap-2">
        <span class="badge rounded-pill fs-5 text-bg-primary">{{
          last24HData?.newIncidents ?? ''
        }}</span>
        <app-route-withfacets
          actionTitle=" {{
            'pages.cybersocxdr.last24h.newIncidents' | translate
          }}"
          actionRoute="/xtended-soc/incidents"
          buttonId="newIncidents"
        >
        </app-route-withfacets>
      </div>
    </div>
  </div>
</app-card>

<div
  class="container p-4 rounded-3 shadow-sm text-dark"
  style="max-width: 1800px"
>
  <div class="row">
    <div class="col-12">
      <div class="accordion mb-4" id="releaseNotesAccordion">
        @for (releaseNote of releaseNotes; track releaseNote.id; let i = $index)
        {
        <div class="accordion-item mb-3 border-0 rounded-3 overflow-hidden">
          <h1 class="accordion-header" [id]="'heading' + i">
            <button
              class="accordion-button fw-bold text-primary text-dark"
              type="button"
              data-bs-toggle="collapse"
              [attr.data-bs-target]="'#collapse' + i"
              [attr.aria-expanded]="showPatchNotes[releaseNote.id]"
              [attr.aria-controls]="'collapse' + i"
              (click)="togglePatchNotes(releaseNote.id)"
            >
              <span class="me-2">
                {{ releaseNote.version }} - {{ releaseNote.title }}
              </span>
            </button>
          </h1>
          <div
            [id]="'collapse' + i"
            class="accordion-collapse collapse"
            [class.show]="showPatchNotes[releaseNote.id]"
            [attr.aria-labelledby]="'heading' + i"
          >
            <div class="accordion-body text-dark">
              <p class="lead mb-4">{{ releaseNote.description }}</p>
              <p class="text-dark mb-4">
                <i class="fas fa-calendar-alt me-2"></i>Date de publication :
                {{ releaseNote.releaseDate }}
              </p>

              <ul class="list-unstyled mx-3">
                @for (patchNote of releaseNote.patchNotes; track patchNote.id) {
                <li class="mb-3 p-3 rounded-3">
                  <div
                    class="d-flex justify-content-between align-items-center mb-2"
                  >
                    <h4 class="mb-0 text-primary">{{ patchNote.title }}</h4>
                    <span
                      class="badge bg-secondary rounded-3"
                      [ngClass]="{
                        'bg-danger': patchNote.type.id === 1,
                        'bg-info text-white': patchNote.type.id === 2
                      }"
                      >{{ patchNote.type.title }}</span
                    >
                  </div>
                  <p class="mb-0 text-dark">
                    {{ patchNote.description }}
                  </p>
                </li>
                }
              </ul>
            </div>
          </div>
        </div>
        }
      </div>
    </div>
  </div>
</div>

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from '../layout/layout/layout.component';
import { SupportDashboardComponent } from './supportdashboard/supportdashboard.component';
import { SupportLogComponent } from './supportlog/supportlog.component';
import { SupportViewComponent } from './supportview/supportview.component';
import { oktaAuthGuard } from '../app.guard';


const routes: Routes = [
  {
    path: 'support',
    component: LayoutComponent,
    canActivate: [oktaAuthGuard],
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      {
        path: 'log',
        component: SupportLogComponent,
        pathMatch: 'full',
        data: {
          title: 'menu.support.log',
          urls: [{ title: 'menu.support.title', url: '/support/dashboard/'  }, { title: 'menu.support.log' }],
        }
      },
      {
        path: 'view/:caseRef',
        component: SupportViewComponent,
        pathMatch: 'full',
        data: {
          title: 'menu.support.view',
          urls: [{ title: 'menu.support.title', url: '/support/dashboard/'  }, { title: 'menu.support.view' }],
        }
      },
    ]
  },
  {
    path: 'support',
    component: LayoutComponent,
    canActivate: [oktaAuthGuard],
    children: [
      {
        path: 'dashboard',
        component: SupportDashboardComponent,
        pathMatch: 'full',
        data: {
          title: 'menu.support.overview',
          urls: [{ title: 'menu.support.title', url: '/support/dashboard/'  }, { title: 'menu.support.overview' }],
        }
      },
      {
        path: 'dashboard/:loadpage',
        component: SupportDashboardComponent,
        pathMatch: 'full',
        data: {
          title: 'menu.support.overview',
          urls: [{ title: 'menu.support.title', url: '/support/dashboard/'  }, { title: 'menu.support.overview' }],
        }
      },
    ]
  },
  // {
  //   path: 'support',
  //   component: LayoutComponent,
  //   canActivate: [oktaAuthGuard],
  //   children: [
  //     {
  //       path: 'contacts',
  //       component: SupportcontactsComponent,
  //       pathMatch: 'full',
  //       data: {
  //         title: 'menu.support.contact',
  //         urls: [{ title: 'menu.support.title', url: '/support/contacts/'  }, { title: 'menu.support.contact' }],
  //       }
  //     },
  //     {
  //       path: 'contacts/addcontacts',
  //       component: SupportcontactsaddComponent,
  //       pathMatch: 'full',
  //       data: {
  //         title: 'menu.support.contact',
  //         urls: [{ title: 'menu.support.title' }, { title: 'menu.support.contact' }],
  //       },
  //   }
  //   ]
  // },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SupportRoutingModule { }

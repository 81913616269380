import { Injectable } from '@angular/core';
import { CssColor, UserTheme } from '../model/shared-items';
import { ContextService } from './context-service';

@Injectable({
  providedIn: 'root'
})
export class WidgetService {

  // widget color settings (black theme by default)

  private blackColorSettings = {
    fontColor: CssColor.white,
    backgroundColor: CssColor.gray900,
    borderColor: CssColor.gray500
  };

  private whiteColorSettings = {
    fontColor: CssColor.black,
    backgroundColor: CssColor.gray300,
    borderColor: CssColor.gray500
  };

  constructor(
    private contextService: ContextService
  ) { }

  /**
   * update style depending of 'light' or 'dark' theme selected
   * @param chartOptions
   * @param theme
   */
  public updateWidgetStyle(chartOptions, theme?) {

    // get theme
    if (!theme) {
      theme = this.contextService.userSelectedTheme;
    }
    const colorSettings = theme === UserTheme.light ? this.whiteColorSettings : this.blackColorSettings;

    // CHART
    chartOptions.chart.backgroundColor = colorSettings.backgroundColor;
    chartOptions.chart.borderRadius = 15;

    // TITLE
    if(chartOptions.title) {
      chartOptions.title.style = {
        color: colorSettings.fontColor,
        fontWeight: 'bold',
      };
    }

    // SUB TITLE
    if(chartOptions.subtitle) {
      chartOptions.subtitle.style = {
        color: colorSettings.fontColor,
        fontWeight: 'bold',
      };
    }

    // LEGEND
    if(chartOptions.legend) {
      chartOptions.legend.itemStyle = {
        fontWeight: 'bold',
        fontSize: '15px',
        color: colorSettings.fontColor
      };

      chartOptions.legend.itemHoverStyle = {
        fontWeight: 'bold',
        fontSize: '15px',
        color: CssColor.primary
      };
    }

    // AXIS
    const axisStyle = {
      style: {
        fontWeight: 'bold',
        fontSize: '14px',
        color: colorSettings.fontColor,
      }
    };

    if (chartOptions.xAxis) {
      if (chartOptions.xAxis.title) {
        chartOptions.xAxis.title = axisStyle;
      }
      chartOptions.xAxis.labels = axisStyle;
      chartOptions.xAxis.lineColor = colorSettings.fontColor;
    }

    if (chartOptions.yAxis) {
      const yAxisTitle = chartOptions.yAxis.title?.text ?? 'Values';
      chartOptions.yAxis.title = axisStyle;
      chartOptions.yAxis.labels = axisStyle;
      chartOptions.yAxis.title.text = yAxisTitle;
    }

    // DRILLDOWN
    if(chartOptions.drilldown) {
      chartOptions.drilldown.activeAxisLabelStyle = {
        color : colorSettings.fontColor,
        cursor: 'pointer',
        fontWeight: 'bold',
        textDecoration:'none'
      };
      chartOptions.drilldown.activeDataLabelStyle = {
        color : colorSettings.fontColor,
        cursor: 'pointer',
        fontWeight: 'bold',
        textDecoration:'none'
      };
    }

    // DATA LABELS
    if(chartOptions.series.length > 0) {
      for(const serie of chartOptions.series) {
        serie.dataLabels = {
          connectorWidth:2,
          position: 'center',
          style: {
            fontSize: '0.9em',
            textOutline: 'none'
          }
        } 
      }

    }

  }

  /**
   * add legend to an existing highcharts options
   * @param options 
   */
  public addlegend(options: Highcharts.Options) {

    const colorSettings = this.contextService.userSelectedTheme === UserTheme.light ? this.whiteColorSettings : this.blackColorSettings;

    options.series.forEach(s => {
      s.showInLegend = true;
    })

    options.legend =  {
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'bottom',
      itemMarginTop: 10,
      itemMarginBottom: 10,
      className: 'legend',
      itemStyle: {
        fontWeight: 'bold',
        fontSize: '0.9em',
        textOverflow: 'clip',
        color: colorSettings.fontColor
      },
      itemHoverStyle: {
        fontWeight: 'bold',
        color: '#FF7900',
      },
    };

  }

}

/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit } from '@angular/core';
import { CybersocxdrService } from '../../../shared/services/xtendedsoc/cybersocxdr.service';
import { InsightData } from 'src/app/shared/model/cybersocxdr/insight';
import { IncidentScale } from 'src/app/shared/model/cybersocxdr/incidentScale';
import { XtendedChartService } from 'src/app/shared/services/xtendedsoc/xtended-chart.service';
import { XtendedFiltersService } from '../../xtendedfilters.service';
import { XtendedSearchable } from '../xtended-page';
import { CybersocXDRDashboardSearchCriteria } from 'src/app/shared/model/searchCriteria';
import { BacklogStatus } from 'src/app/shared/model/cybersocxdr/backlogStatus';
import { Last24HData } from 'src/app/shared/model/cybersocxdr/last24HData';
import { CaseXDR } from 'src/app/shared/model/cybersocxdr/microsocticket';
import { XtendedFacet } from 'src/app/shared/model/cybersocxdr/facets';
import { PointOptionsObject } from 'highcharts';
import { XtendedHighchartService } from '../../xtended-highchart.service';
import { Fields4A3W } from 'src/app/shared/model/cybersocxdr/fields4A3W';
import { handlePromises } from '../../utils';

@Component({
  selector: 'app-xtended-home-page',
  templateUrl: './home.component.html',
})
export class XtendedSOCHomeComponent implements OnInit, XtendedSearchable {
  constructor(
    private cybersocxdrService: CybersocxdrService,
    private xtendedChartService: XtendedChartService,
    private globalFilters: XtendedFiltersService,
    private xtendedHighchartService: XtendedHighchartService
  ) {}

  insightPeriod: 'last7d' | 'last30d' = 'last30d';
  insights: InsightData[] = [];

  pyramidIncidentData: IncidentScale;
  incidentsRepartitionData: any[];
  backlogStatus: BacklogStatus;
  last24HData: Last24HData;
  lastIncidentsNeedingActions: CaseXDR[];

  actions: PointOptionsObject[];
  hasAnsweredSurvey: boolean = true;

  async ngOnInit() {
    const searchCriteria = this.globalFilters.searchCriteria;
    this.hasAnsweredSurvey = await this.cybersocxdrService.getHasAnsweredSurvey();
    this.onSearch(searchCriteria);
  }

  async onInsightPeriodChange(period: 'last7d' | 'last30d') {
    this.insightPeriod = period;
    this.insights = await this.cybersocxdrService.getClientInsights({
      ...new CybersocXDRDashboardSearchCriteria(),
      createdFrom: this.insightPeriod,
    });
  }

  async onSearch(searchCriteria: CybersocXDRDashboardSearchCriteria) {
    [
      this.insights,
      this.pyramidIncidentData,
      this.incidentsRepartitionData,
      this.actions,
      this.backlogStatus,
      this.last24HData,
      this.lastIncidentsNeedingActions,
    ] = await handlePromises([
      this.cybersocxdrService.getClientInsights({
        ...new CybersocXDRDashboardSearchCriteria(),
        createdFrom: this.insightPeriod,
        incidentDataSource: searchCriteria.incidentDataSource,
      }),
      this.cybersocxdrService.getIncidentCaseScale(searchCriteria),
      this.cybersocxdrService.getIncidentsRepartitions(searchCriteria),
      this.xtendedHighchartService.drillPointMapper(
        await this.xtendedChartService.get4A3WByField(searchCriteria, Fields4A3W.ACTION),
        Fields4A3W.ACTION
      ),
      this.cybersocxdrService.getBacklogStatus(searchCriteria.incidentDataSource),
      this.cybersocxdrService.getLast24HData(searchCriteria.incidentDataSource),
      this.cybersocxdrService.getLastIncidentsNeedingActions(searchCriteria.incidentDataSource),
    ]);
  }
}

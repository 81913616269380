import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { firstValueFrom, Observable, shareReplay } from 'rxjs';
import { Feedback } from 'src/app/dashboard/feedbacks/add-feedback/add-feedback.component';
import { TenantAccess, TenantInfo } from '../model/TenantInfo';
import { Survey, SurveyReport } from '../model/feedback';

@Injectable({
  providedIn: 'root'
})
export class HttpUsersPreferencesService {

  private userprefsURL =  environment.endpoints.userpreferences;

  constructor(
    private httpClient: HttpClient
  ) { }

  /**
   * GET /preferences
   */
  async getUserPreferences(): Promise<Object> {
    const url = this.userprefsURL + 'preferences';
    const $preferences = this.httpClient.get(url, {
      reportProgress: true,
      responseType: 'json',
    });
    return await firstValueFrom($preferences);
  }

  /**
   * GET /profile-picture
   */
  async getProfilePicture(): Promise<Blob> {
    const url = this.userprefsURL + 'profile-picture';
    const $picture = this.httpClient.get(url, {
      reportProgress: true,
      responseType: 'blob',
    });
    return await firstValueFrom($picture);
  }

  /**
   *  POST /profile-picture
   */
  async updateProfilePicture(file: File): Promise<Object> {
    const url = this.userprefsURL + 'profile-picture';
    const formData = new FormData();
    formData.append('file', file);
    const $update = this.httpClient.post(url, formData, {
      reportProgress: true,
      responseType: 'json',
    });
    return await firstValueFrom($update);
  }

  /**
   * DELETE /profile-picture
   */
  async deleteProfilePicture(): Promise<Object> {
    const url = this.userprefsURL + 'profile-picture';
    const $deletion = this.httpClient.delete(url);
    return await firstValueFrom($deletion);
  }

  /**
   * POST /updateSinglePreference
   */
  async userPrefencesUpdateSinglePrivate(criteria): Promise<Object> {
    const url = environment.endpoints.userpreferences + 'updateSinglePreference';
    const $update = this.httpClient.post(url, criteria);
    return await firstValueFrom($update);
  }

  /**
   * POST /updateArrayPreference
   */
  async userPrefencesUpdateArray(criteria): Promise<Object> {
    const url = environment.endpoints.userpreferences + 'updateArrayPreference';
    const $update = this.httpClient.post(url, criteria);
    return await firstValueFrom($update);
  }

  /**
   * POST /updateLastLogin
   */
  async userPrefencesUpdateLastLogin(): Promise<Object> {
    const url = environment.endpoints.userpreferences + 'updateLastLogin';
    const $update = this.httpClient.post(url, {});
    return await firstValueFrom($update);
  }

  /**
   * POST /updateStringReferences
   */
  async userPrefencesUpdateReferences (references): Promise<Object> {
    const url = environment.endpoints.userpreferences + 'updateStringReferences';
    const $update = this.httpClient.post(url, references);
    return await firstValueFrom($update);
  }

  /**
   * GET getNotifPreferences
   */
  async userPrefencesGetNotificationPreferences(): Promise<Object> {
    const url = environment.endpoints.userpreferences + 'getNotifPreferences';
    const $notifications = this.httpClient.get(url).pipe(shareReplay())
    return await firstValueFrom($notifications);
  }

  /**
   * POST /updateNotifPreference
   */
  async updateNotificationPreference(criteria): Promise<Object> {
    const url = environment.endpoints.userpreferences + 'updateNotifPreference';
    const $update = this.httpClient.post(url, criteria);
    return await firstValueFrom($update);
  }

  /**
   * GET /updateUserNotif/{id}
   */
  async getUserNotifications(id): Promise<Object> {
    const url = environment.endpoints.userpreferences + 'updateUserNotif' + '/' + id;
    const $notifications = this.httpClient.get(url);
    return await firstValueFrom($notifications);
  }

  /**
   * GET /initialize
   */
  async userPrefencesInitialize(): Promise<any> {
    const url = environment.endpoints.userpreferences + 'initialize';
    const $update = this.httpClient.get(url);
    return await firstValueFrom($update);
  }

  /**
   * GET /highestRank
   */
  async userPrefencesHighestRank(): Promise<any> {
    const url = environment.endpoints.userpreferences + 'highestRank';
    const $highestRank = this.httpClient.get(url);
    return await firstValueFrom($highestRank);
  }

  /**
   * GET /notifications/all
   */
  async getAllNotifications(): Promise<any> {
    const url = environment.endpoints.userpreferences + 'notifications/all';
    const $notifications = this.httpClient.get(url);
    return await firstValueFrom($notifications);
  }

  /**
   * GET /feedback
   */
  async getfeedback(type: string): Promise<any> {
    const url = environment.endpoints.userpreferences + 'feedback/'+ type;
    const $feedback = this.httpClient.get(url);
    return await firstValueFrom($feedback);
  }

  /**
   * POST /feedback
   */
  async postfeedback(feedback: Feedback): Promise<any> {
    const url = environment.endpoints.userpreferences + 'feedback';
    const $feedback = this.httpClient.post(url, feedback);
    return await firstValueFrom($feedback);
  }

  /**
   * DELETE /feedback/:id
   */
  async deletefeedback(id: string): Promise<any> {
    const url = environment.endpoints.userpreferences + 'feedback/' + id;
    const $feedback = this.httpClient.delete(url);
    return await firstValueFrom($feedback);
  }

  /**
   * GET /feedback
   */
  public getAllSurveys(type: string): Observable<Survey[]> {
    const url = environment.endpoints.userpreferences + 'survey/'+ type;
    return this.httpClient.get<Survey[]>(url);
  }

  /**
   * GET /survey/response/:id
   */
  public getSurveyResults(id: string): Observable<SurveyReport> {
    const url = environment.endpoints.userpreferences + 'survey/response/'+ id;
    return this.httpClient.get<SurveyReport>(url);
  }

  /**
   * POST /feedback
   */
  async postSurvey(survey): Promise<any> {
    const url = environment.endpoints.userpreferences + 'survey';
    const $response = this.httpClient.post(url, survey);
    return await firstValueFrom($response);
  }

  /**
   * GET list of accesses
   */
  async getTenantAccesses(): Promise<TenantAccess> {
    const url = environment.endpoints.userpreferences + 'tenantAccesses'
    const tenantAccesses$: Observable<TenantAccess> = this.httpClient.get(url) as Observable<TenantAccess>;
    return await firstValueFrom(tenantAccesses$);
  }

  /**
   * GET list of tenant info
   */
  getTenantInfo(): Observable<TenantInfo> {
    const url = environment.endpoints.userpreferences + 'tenantInfo'
    return this.httpClient.get(url) as Observable<TenantInfo>;
  }
  

}

import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DragAndDropDirective } from 'src/app/shared/directives/dragAndDrop.directive';
import { IconsModule } from 'src/app/shared/modules/icons/icons.module';
import { environment } from 'src/environments/environment';
import { constants } from '../../constants';

@Component({
  selector: 'app-file-import',
  standalone: true,
  imports: [CommonModule, IconsModule, TranslateModule, DragAndDropDirective],
  templateUrl: './internal-file-transfer.component.html',
  styleUrl: './internal-file-transfer.component.scss'
})
export class InternalFileTransfertComponent {

  public loading = false;
  public file;
  public error: string;

  @Output() onAddFile = new EventEmitter<File>();

  constructor(
    private translateService: TranslateService
  ) { }

  public onFileClick(file) {
    this.onAddFile.emit(file);
  }

  /**
   * on file drop handler
   */
  public onFileDropped($event) {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  public fileBrowseHandler(file) {
    this.prepareFilesList(file);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  public deleteFile() {
    this.file = undefined;
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  private prepareFilesList(files: Array<any>) {

    this.error = null;

    if (files.length !== 1) {
      this.error = this.translateService.instant('pages.shared.oneFileAuthorized');
    } else {
      const file: File = files[0];
      const allowedFileType = environment.allowedFileTypes.includes(file.type);
      const extensionFiles = file.type === '' && file.name.split('.')[file.name.split('.').length - 1];
      const maxSize = constants.fileMaxMbSize;
      const sizeInMBytes = file.size / 1000 / 1000; //bytes to MBytes

      if (sizeInMBytes > maxSize) {
        this.error = this.translateService.instant('pages.shared.invalidSizeInfo', { maxSize: maxSize });
      } else if (!allowedFileType && extensionFiles !== 'p7b' && extensionFiles !== 'pcap') {
        this.error = this.translateService.instant('pages.shared.invalidFileTypeInfo');
      } else {
        this.file = file;
        this.onAddFile.emit(this.file);
      }
    }
  }

  public removeError() {
    this.error = null;
  }

}

<div class="content">
  <!-- since last login-->
  <div class="card news">
    <ul class="p-0 m-0 news" style="width: 100%">
      <a class="click" (click)="loadNewSinceLastVisit()">
        <li class="mb-2">
          <span [ngClass]="{'new-cases': true, 'dark': !isDarkTheme}">
            {{ casesCreatedSinceLastLogin }}
          </span>
          {{ 'pages.support.dashboard.sinceLastVisitNewPost' | translate }}
        </li>
      </a>
      <a class="click" (click)="loadUpdatedSinceLastVisit()">
        <li>
          <span class="updated-cases" [ngClass]="!isDarkTheme && 'dark'">
            {{ casesUpdatedSinceLastLogin }}
          </span>
          {{ 'pages.support.dashboard.sinceLastVisitUpdatePost' | translate }}
        </li>
      </a>
    </ul>
  </div>

  <!-- Display filters toggle under a specified width -->
  @if (displayFiltersToggle) {
  <label class="custom-control-label" for="toggleFilters">
    {{ 'pages.generic.filters' | translate }}
  </label>
  <div
    class="form-check form-switch custom-switch right toggle-filters mb-1"
    data-toggle="tooltip"
    data-placement="top"
    title="{{ 'pages.support.dashboard.toggleFiltersDesc' | translate }}">
    <input
      class="form-check-input"
      type="checkbox"
      role="switch"
      id="toggleFilters"
      [(ngModel)]="displayFilters"/>
    
  </div>
  }
  <!-- filters -->
  @if (displayFilters) {
  <div class="card">
    <div class="card-body">
      <div class="search-checkboxes">
        <!-- hide child case checkbox -->
        <div
          class="form-check form-switch custom-switch right me-3"
          data-toggle="tooltip"
          data-placement="top"
          title="{{ 'pages.support.dashboard.childCasesDesc' | translate }}"
        >
          <input
            class="form-check-input"
            type="checkbox"
            role="switch"
            id="childCases"
            [(ngModel)]="hideChildCases"
          />
          <label class="custom-control-label" for="childCases"
            >{{ 'pages.generic.childCases' | translate }}
          </label>
        </div>

        <!-- advanced search checkbox -->
        <div
          class="form-check form-switch custom-switch right me-3"
          data-toggle="tooltip"
          data-placement="top"
          title="{{ 'pages.support.dashboard.enableAdSearchDesc' | translate }}"
        >
          <input
            class="form-check-input"
            type="checkbox"
            role="switch"
            id="advanceSearch"
            [(ngModel)]="advSearch"
          />
          <label class="custom-control-label" for="advanceSearch"
            >{{ 'pages.generic.advancedSearch' | translate }}
          </label>
        </div>

        <!-- saved searches checkbox -->
        <div
          class="form-check form-switch custom-switch right"
          data-toggle="tooltip"
          data-placement="top"
          title="{{
            'pages.support.dashboard.enableSavedSearchDeas' | translate
          }}"
        >
          <input
            class="form-check-input"
            type="checkbox"
            id="hideSaveSearch"
            [(ngModel)]="hideSaveSearch"
          />
          <label class="custom-control-label" for="hideSaveSearch">{{
            'pages.generic.savedSearches' | translate
          }}</label>
        </div>
      </div>
      <form>

        <div class="row">
          <!-- case number -->
          <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12 mb-3" data-toggle="tooltip" data-placement="top"
            title="{{ 'pages.support.dashboard.caseNumSearchDesc' | translate }}">
            <label for="caseNumberInput">{{ 'pages.support.dashboard.fieldTitles.reference' | translate }} </label>
            <input type="text" class="form-control" id="caseNumberInput" name="caseNumber" placeholder=""
              [(ngModel)]="caseNumber" />
          </div>

          <!-- subject -->
          <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12 mb-3">
            <label for="subjectInput">{{ 'pages.support.dashboard.fieldTitles.subject' | translate }}</label>
            <input type="text" class="form-control" id="subjectInput" name="subject" [(ngModel)]="subject" />
          </div>

          <!-- description -->
          <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12 mb-3">
            <label for="descriptionInput">{{ 'pages.support.dashboard.fieldTitles.description' | translate }}</label>
            <input type="text" class="form-control" id="descriptionInput" name="description" [(ngModel)]="description" />
          </div>

        <!-- type -->
        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12 mb-3">
          <div class="custom-label" id="typeFilterDesc">{{ 'pages.support.dashboard.fieldTitles.type' | translate }}</div>
          <ng-multiselect-dropdown
            [settings]="dropdownSettings"
            [data]="caseTypeCV"
            [(ngModel)]="type"
            name="typeFilter"
            aria-labelledBy="typeFilterDesc">
          </ng-multiselect-dropdown>
        </div>

      </div>

        <div class="row">
          <!-- Services -->
          <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12 mb-3">
            <app-infinite-select
              [labelKey]="infiniteSelectLabels.services"
              [selectedOptionInput]="servicesSelectInput"
              [tmpOptions]="servicesDropdown"
              [maxOptionsCount]="servicesTotalCount"
              [isDarkTheme]="isDarkTheme"
              [clearSelection]="clearSelectedService"
              (scrollEvent)="loadServices($event)"
              (selectEvent)="setSelectedService($event)"
              (searchEvent)="searchServices($event)">
            </app-infinite-select>
          </div> 

          <!-- true / false positives -->
          <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12 mb-3">
            <label for="trueFalsePositives" id="trueFalsePositivesLabel">
              {{ 'pages.support.dashboard.fieldTitles.trueFalsePositive' | translate }}
            </label>
            <span class="visually-hidden" id="trueFalsePositivesDesc">
              {{ 'pages.support.dashboard.fieldTitles.trueFalsePositiveDesc' | translate }}
            </span>
            <app-help message="{{ 'pages.support.dashboard.trueFalsePositivesHelpDesc' | translate }}"></app-help>
            <select
              class="form-select"
              id="trueFalsePositives"
              name="trueFalsePositives"
              aria-labelledby="trueFalsePositivesLabel"
              aria-describedby="trueFalsePositivesDesc"
              title="{{ 'pages.support.dashboard.fieldTitles.trueFalsePositiveDesc' | translate }}"
              [(ngModel)]="trueFalsePositives">
              <option value="0"></option>
              <option value="1">True</option>
              <option value="2">False</option>
            </select>
          </div>
        </div>

        <div class="row">
          <!-- priority -->
          <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12 mb-3" data-toggle="tooltip" data-placement="top"
            title="{{ 'pages.support.dashboard.prioritySearchDesc' | translate }}">
            <div class="custom-label" id="priorityFilterLabel">
              {{ 'pages.support.dashboard.fieldTitles.priority' | translate }}
            </div>
            <span class="visually-hidden" id="priorityFilterDesc">
              {{ 'pages.support.dashboard.fieldTitles.pages.support.dashboard.prioritySearchDesc' | translate }}
            </span>
            <ng-multiselect-dropdown
              [settings]="dropdownSettings"
              [data]="casePriorityCV"
              [(ngModel)]="selectedPriorities"
              name="priorityFilter"
              aria-labelledby="priorityFilterLabel"
              aria-describedby="priorityFilterDesc">
            </ng-multiselect-dropdown>
          </div>

          <!-- status -->
          <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12 mb-3">
            <div class="custom-label" id="statusFilterLabel">{{ 'pages.support.dashboard.fieldTitles.status' | translate }}</div>
            <ng-multiselect-dropdown [settings]="dropdownSettingsStatus" [data]="statusSelectDropdown" [(ngModel)]="selectedStatus"
              name="statusFilter" aria-labelledby="statusFilterLabel">
            </ng-multiselect-dropdown>
          </div>

          <!-- contact -->
          <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12 mb-3">
            <div class="custom-label" id="contactsFilterLabel">{{ 'pages.support.dashboard.fieldTitles.contact' | translate }}</div>
            <ng-multiselect-dropdown
              [settings]="dropdownSettingsContact"
              [data]="contacts"
              [(ngModel)]="contactEmail"
              name="contactsFilter"
              aria-labelledby="contactsFilterLabel"
              (onSelect)="contactFilterChange($event)">
            </ng-multiselect-dropdown>
          </div>
        </div>

        <!-- ADVANCED SEARCH -->
        <div [ngbCollapse]="!advSearch" id="advSearch">
          <div class="row">
            
            <!-- asset -->
            <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12 mb-3">
              <app-infinite-select
                [labelKey]="infiniteSelectLabels.assets"
                [selectedOptionInput]="assetsSelectInput"
                [tmpOptions]="caseAssetCV"
                [maxOptionsCount]="assetsTotalCount"
                [isDarkTheme]="isDarkTheme"
                [clearSelection]="clearSelectedAsset"
                (scrollEvent)="loadAssets($event)"
                (selectEvent)="setSelectedAsset($event)"
                (searchEvent)="searchAssets($event)">
              </app-infinite-select>
            </div>    

            <!-- escalation -->
            <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12 mb-3">
              <label for="escalationSelect">{{ 'pages.support.dashboard.fieldTitles.escalation' | translate }}</label>
              <select class="form-select" id="escalationSelect" name="escalation" [(ngModel)]="escalation">
                <option></option>
                <option value="To Customer">
                  {{
                    'pages.support.dashboard.fieldTitles.escalationToUs'
                      | translate
                  }}
                </option>
                <option value="from Customer">
                  {{
                    'pages.support.dashboard.fieldTitles.escalationToOCD'
                      | translate
                  }}
                </option>
              </select>
            </div>

            <!-- created -->
            <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12 mb-3">
              <label for="createdPeriodSelect">{{ 'pages.support.dashboard.fieldTitles.created' | translate }}</label>
              <select class="form-select" id="createdPeriodSelect" name="createdPeriod" [(ngModel)]="createdPeriod">
                <option></option>
                <option value="1w">
                  {{ 'pages.generic.thisWeek' | translate }}
                </option>
                <option value="2w">
                  {{ 'pages.generic.lastTwoWeeks' | translate }}
                </option>
                <option value="thismonth">
                  {{ 'pages.generic.thisMonth' | translate }}
                </option>
                <option value="lastmonth">
                  {{ 'pages.generic.lastMonth' | translate }}
                </option>
              </select>
            </div>

            <!-- created by -->
            <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12 mb-3">
              <div class="custom-label" id="createdByLabel">{{ 'pages.support.dashboard.fieldTitles.createdBy' | translate }}</div>
              <ng-multiselect-dropdown [settings]="dropdownSettingsCreatedBy" [data]="contactsCreatedBy"
                [(ngModel)]="createdByEmail" name="createdByFilter" aria-labelledby="createdByLabel">
              </ng-multiselect-dropdown>
            </div>
          </div>

          <div class="row">

            <!-- case created from/to -->
            <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12 mb-3" data-toggle="tooltip" data-placement="top"
              title="{{ 'pages.support.dashboard.createDateSearchDesc' | translate }}">
              <div class="custom-label" id="createdFromToLabel">
                {{ 'pages.support.dashboard.fieldTitles.createdToFrom' | translate }}
                <app-help message="{{ 'pages.generic.dateRangeHelpDesc' | translate }}"></app-help>
              </div>
              <div class="row">
                <span class="visually-hidden" id="createdFromLabel">{{ 'pages.support.dashboard.fieldTitles.createdFrom' | translate }}</span>
                <div class="col-6">
                  <input type="date" class="form-control" name="createdFrom" aria-labelledby="createdFromLabel"
                    [(ngModel)]="createdFrom" (change)="onDateChange()"
                    [ngClass]="{'is-invalid': !validDateRanges['created']}"/>
                </div>
                <span class="visually-hidden" id="createdToLabel">{{ 'pages.support.dashboard.fieldTitles.createdTo' | translate }}</span>
                <div class="col-6" style="padding-left: 0.5rem">
                  <input type="date" class="form-control" name="createdTo" aria-labelledBy="createdToLabel"
                    [(ngModel)]="createdTo" (change)="onDateChange()"
                    [ngClass]="{'is-invalid': !validDateRanges['created']}" />
                </div>
              </div>
            </div>

            <!-- case updated to from -->
            <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12 mb-3">
              <div class="custom-label" id="updatedFromToLabel">
                {{ 'pages.support.dashboard.fieldTitles.updatedToFrom' | translate }}
                <app-help message="{{ 'pages.generic.dateRangeHelpDesc' | translate }}"></app-help>
              </div>
              <div class="row">
                <span class="visually-hidden" id="updatedFromLabel">{{ 'pages.support.dashboard.fieldTitles.updatedFrom' | translate }}</span>
                <div class="col-6">
                  <input type="date" class="form-control" name="updatedFrom" aria-labelledby="updatedFromLabel"
                    [(ngModel)]="updatedFrom" (change)="onDateChange()"
                    [ngClass]="{'is-invalid': !validDateRanges['updated']}" />
                </div>
                <span class="visually-hidden" id="updatedToLabel">{{ 'pages.support.dashboard.fieldTitles.updatedTo' | translate }}</span>
                <div class="col-6" style="padding-left: 0.5rem">
                  <input type="date" class="form-control" name="updatedTo" aria-labelledby="updatedToLabel"
                    [(ngModel)]="updatedTo" (change)="onDateChange()"
                    [ngClass]="{'is-invalid': !validDateRanges['updated']}" />
                </div>
              </div>
            </div>

            <!-- case close date -->
            <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12 mb-3" data-toggle="tooltip" data-placement="top"
              title="{{ 'pages.support.dashboard.closeDateSearchDesc' | translate }}">
              <div class="custom-label" id="updatedFromToLabel">
                {{ 'pages.support.dashboard.fieldTitles.closedToFrom' | translate }}
                <app-help message="{{ 'pages.generic.dateRangeHelpDesc' | translate }}"></app-help>
              </div>
              <div class="row">
                <span class="visually-hidden" id="closedFromLabel">{{ 'pages.support.dashboard.fieldTitles.closedFrom' | translate }}</span>
                <div class="col-6">
                  <input type="date" class="form-control" name="closedDateFrom" aria-labelledby="closedFromLabel"
                    [(ngModel)]="closedDateFrom" (change)="onDateChange()"
                    [ngClass]="{'is-invalid': !validDateRanges['closedDate']}" />
                </div>
                <span class="visually-hidden" id="closedToLabel">{{ 'pages.support.dashboard.fieldTitles.closedTo' | translate }}</span>
                <div class="col-6" style="padding-right: 0; padding-left: 0.5 rem">
                  <input type="date" class="form-control" name="closedDateTo" aria-labelledby="closedToLabel"
                    [(ngModel)]="closedDateTo" (change)="onDateChange()"
                    [ngClass]="{'is-invalid': !validDateRanges['closedDate']}" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="ms-2 me-1 action-buttons">
          <button
            [disabled]="!allRangesValid"
            class="btn btn-primary"
            (click)="search()"
          >
            {{ 'pages.generic.filter' | translate }}
          </button>
          <button class="btn btn-primary" (click)="reset()">
            {{ 'pages.generic.reset' | translate }}
          </button>
          <button
            [disabled]="!allRangesValid"
            class="btn btn-primary"
            (click)="exportCases()"
            data-toggle="tooltip"
            data-placement="top"
            title="{{ 'pages.support.dashboard.downloadResDesc' | translate }}"
          >
            {{ 'buttons.action.generateReport' | translate }}
          </button>
          <button
            class="btn btn-primary save-search"
            (click)="openSaveSearch()"
            [disabled]="!searched"
            data-toggle="tooltip"
            data-placement="top"
            title="{{ 'pages.support.dashboard.saveSearchDesc' | translate }}"
          >
            {{ 'pages.generic.saveSearch' | translate }}
          </button>
        </div>
      </form>
    </div>
  </div>
  }
  

  <!-- saved filters -->
  <div
    class="card"
    *ngIf="savedSearches && savedSearches.length > 0"
    [ngbCollapse]="!hideSaveSearch"
    id="savedSearch"
  >
    <div class="card-body save-search">
      <div class="col-8" style="margin-left: 0.75rem; margin-right: -1.25rem">
        <!-- save search buttons -->
        <span style="float: left" *ngFor="let search of savedSearches">
          <button
            class="btn btn-secondary mt-1 search"
            title="{{ search.name }}"
            [ngClass]="{
              selected:
                selectedSearchCriteria &&
                search.name === selectedSearchCriteria.name
            }"
            (click)="loadSearch(search)"
          >
            {{ search.name }}
          </button>
        </span>
      </div>
      <div class="col-4 pe-0">
        <!-- delete search button -->
        <button
          class="btn btn-primary save-search mt-1"
          data-toggle="tooltip"
          data-placement="top"
          [disabled]="!selectedSearchCriteria"
          title="{{ 'buttons.action.deleteSearch' | translate }}"
          (click)="deleteSearch()"
        >
          {{ 'buttons.action.deleteSearch' | translate }}
        </button>
        <!-- update search button -->
        <button
          class="btn btn-primary save-search mt-1"
          data-toggle="tooltip"
          data-placement="top"
          [disabled]="!selectedSearchCriteria"
          title="{{ 'buttons.action.updateSearch' | translate }}"
          (click)="updateSearch()"
        >
          {{ 'buttons.action.updateSearch' | translate }}
        </button>
      </div>
    </div>
  </div>

  <!-- list -->
  <div
    class="rowfiller"
    infiniteScroll
    [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="50"
    (scrolled)="loadNextCases()"
  >
    <ul class="nav nav-tabs">
      <li class="nav-item">
        <a
          title="{{ 'pages.support.dashboard.casesDesc' | translate }}"
          class="nav-link"
          [ngClass]="{ active: page === casesPage }"
          (click)="page = casesPage; latestCases()"
          >{{ 'pages.support.dashboard.cases' | translate }}
          <span
            class="tag text-bg-primary ms-2"
            style="padding: 0rem 0.5rem"
            *ngIf="casesCount"
            >{{ casesCount }}</span
          >
        </a>
      </li>
      <li class="nav-item">
        <a
          title="{{ 'pages.support.dashboard.flagDesc' | translate }}"
          class="nav-link"
          [ngClass]="{ active: page === flaggedPage }"
          (click)="loadFlagged()"
          >{{ 'pages.generic.flagged' | translate }}
          <span class="tag text-bg-primary ms-2" style="padding: 0rem 0.5rem">{{
            flagRefs.length
          }}</span>
        </a>
      </li>
    </ul>
    <div class="tools">
      <ng-template #listView>{{
        'pages.support.dashboard.listIconDesc' | translate
      }}</ng-template>
      <ng-template #boxView>{{
        'pages.support.dashboard.boxIconDesc' | translate
      }}</ng-template>
      <ng-template #editColumnsTooltip>
        {{ 'pages.support.dashboard.spannerIconDesc' | translate }}</ng-template
      >
      <fa-icon
        [icon]="['fas', 'wrench']"
        size="2x"
        class="p-1 click"
        (click)="openEditColumnModal()"
        [ngbTooltip]="editColumnsTooltip"
      ></fa-icon>
      <fa-icon
        [icon]="['fas', 'th-list']"
        size="2x"
        class="p-1 click"
        (click)="changeViewType('list')"
        [ngbTooltip]="listView"
      ></fa-icon>
      <fa-icon
        [icon]="['fas', 'th-large']"
        size="2x"
        class="p-1 click"
        (click)="changeViewType('grid')"
        [ngbTooltip]="boxView"
      ></fa-icon>
    </div>
    <p *ngIf="page !== casesPage || !casesCount">&nbsp;</p>

    <div
      class="card-body"
      *ngIf="
        userprefService.userPreferences?.supportViewType === 'list' &&
        (page === casesPage || page === flaggedPage)
      "
    >
      <app-support-list
        [selectedColumns]="selectedColumns"
        [cases]="cases"
        [flagRefs]="flagRefs"
        [flaggingRef]="flaggingRef"
        [loaded]="casesLoaded"
        (flagEmitter)="flag($event)"
        [scrollLoading]="scrollLoading"
        (sortEmitter)="sortResults($event)"
      ></app-support-list>
    </div>
    <div
      class="card-body mt-4"
      *ngIf="
        userprefService.userPreferences?.supportViewType === 'grid' &&
        (page === casesPage || page === flaggedPage)
      "
    >
      <app-support-grid
        [cases]="cases"
        [flagRefs]="flagRefs"
        [flaggingRef]="flaggingRef"
        (flagEmitter)="flag($event)"
        [loaded]="casesLoaded"
        [scrollLoading]="scrollLoading"
      ></app-support-grid>
    </div>
  </div>
</div>

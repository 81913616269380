import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { LoginSectionComponent } from './login-section/login-section.component';
import { AppSharedModule } from '../app-shared.module';
import { GlobalFeedbackComponent } from './feedbacks/global-feedback/global-feedback.component';
import { AddFeedbackComponent } from './feedbacks/add-feedback/add-feedback.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { RadioSingleSelectQuestionComponent } from './survey/question-tools/single-select/single-select.component';
import { ClosedQuestionComponent } from './survey/question-tools/closed-choice/closed-choice.component';
import { RatingQuestionComponent } from './survey/question-tools/rating/rating.component';
import { SurveyListComponent } from './survey/survey-list/survey-list.component';
import { SurveyFormComponent } from './survey/survey-form/survey-form.component';
import { MultiSelectComponent } from './survey/question-tools/multi-select/multi-select.component';
import { AbstractQuestionComponent } from './survey/question-tools/abstract-question/abstract-question.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ListOrderQuestionComponent } from './survey/question-tools/list-order/list-order.component';
import { FreeTextComponent } from './survey/question-tools/free-text/free-text.component';


@NgModule({
  declarations: [
    LoginSectionComponent,
    GlobalFeedbackComponent,
    AddFeedbackComponent,
    SurveyListComponent,
    RadioSingleSelectQuestionComponent,
    ClosedQuestionComponent,
    RatingQuestionComponent,
    FreeTextComponent,
    ListOrderQuestionComponent,
    SurveyFormComponent,
    MultiSelectComponent,
    AbstractQuestionComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    NgOptimizedImage,
    AppSharedModule,
    NgMultiSelectDropDownModule,
    FormsModule,
    DragDropModule
  ],
  exports: [
    LoginSectionComponent
  ]
})
export class DashboardModule { }

import { Component, OnInit } from '@angular/core';
import { PortailReleaseNoteService } from 'src/app/shared/services/portailReleaseNote.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import {
  ReleaseNoteDTO,
  ReleasePatchNoteDTO,
  TypePatchNoteDTO,
} from '../../../shared/model/cybersocxdr/portailReleaseNote';

@Component({
  selector: 'app-portail-release-note',
  templateUrl: './portail-release-note.component.html',
  styleUrls: ['./portail-release-note.component.scss'],
})
export class PortailReleaseNoteComponent implements OnInit {
  releaseNotes: ReleasePatchNoteDTO[] = [];
  typePatchNotes: TypePatchNoteDTO[] = [];
  isLoading: boolean = true;
  showPatchNotes: { [key: number]: boolean } = {};

  constructor(private portailReleaseNoteService: PortailReleaseNoteService, private alertService: AlertService) {}

  ngOnInit(): void {
    this.loadReleaseNotes();
  }

  loadReleaseNotes(): void {
    this.isLoading = true;
    this.portailReleaseNoteService.getReleaseNotes().subscribe({
      next: (data: ReleaseNoteDTO[]) => {
        this.releaseNotes = data;
        if (this.releaseNotes.length > 0) {
          this.showPatchNotes[this.releaseNotes[0].id] = true;
        }

        this.isLoading = false;
      },
      error: (error) => {
        this.alertService.handlerError(error.message);
        this.isLoading = false;
      },
    });
  }

  togglePatchNotes(releaseId: number): void {
    this.showPatchNotes[releaseId] = !this.showPatchNotes[releaseId];
  }
}

import { Component, ElementRef, HostListener, Input, Self } from '@angular/core';
import { XtendedFiltersService } from '../../xtendedfilters.service';
import { XtendedFacet } from 'src/app/shared/model/cybersocxdr/facets';

export interface MenuItem {
  text: string;
  handler: (point: FilterEventItem) => void;
  icon?: string;
}

export interface FilterEventItem {
  id: string;
  name: string;
  value: string | number | [string, string];
  facets: string[];
  facetValues: string[];
}

@Component({
  selector: 'app-filtermenu',
  templateUrl: './filtermenu.component.html',
  styleUrls: ['./filtermenu.component.scss'],
})
export class FiltermenuComponent {
  static activemenu = undefined;
  @Input() disabled = false;

  isMenuVisible: boolean;
  rightClickMenuPositionX: number;
  rightClickMenuPositionY: number;
  filterItem: FilterEventItem | null = null;

  constructor(private globalFilters: XtendedFiltersService, @Self() public element: ElementRef<HTMLDivElement>) {}

  @HostListener('document:click')
  @HostListener('window:scroll', ['$event'])
  closeMenu(): void {
    this.isMenuVisible = false;
  }

  onRightClick(event: any) {
    if (this.disabled) {
      return;
    }

    // This is hacky and could break with an update to highcharts.
    const point = event.target.point;
    if (!point) {
      console.log("Filter menu couldn't find point");
      return;
    }

    //Check if valid facet
    const facet = point.series?.name;
    if (!facet || !Object.values(XtendedFacet).includes(facet)) {
      console.log("Filter menu couldn't find a valid facet");
    }

    event.preventDefault();

    this.rightClickMenuPositionX = event.clientX;
    this.rightClickMenuPositionY = event.clientY;

    this.filterItem = {
      id: point.id,
      name: point.name,
      value: point.value,
      facets: point.facets,
      facetValues: point.facetValues,
    };

    if (FiltermenuComponent.activemenu) {
      FiltermenuComponent.activemenu.isMenuVisible = false;
    }
    FiltermenuComponent.activemenu = this;
    this.isMenuVisible = true;
  }

  getRightClickMenuStyle() {
    return {
      position: 'fixed',
      left: `${this.rightClickMenuPositionX}px`,
      top: `${this.rightClickMenuPositionY}px`,
    };
  }

  filterIn(index: number) {
    const facet = this.filterItem.facets[index];
    const facetValue = this.filterItem.facetValues[index];

    if (facet && facetValue && facetValue !== 'unknown') {
      this.globalFilters.filterIn(facet as XtendedFacet, facetValue);
    }
  }

  filterOut() {
    this.filterItem.facets.forEach((facet, i) => {
      const facetValue = this.filterItem.facetValues[i];

      if (facet && facetValue) {
        this.globalFilters.filterOut(facet as XtendedFacet, facetValue);
      }
    });
  }
}

import * as Highcharts from 'highcharts';
import { Component, Input } from '@angular/core';
import * as worldMapData from '@highcharts/map-collection/custom/world.topo.json';
import { BaseChartComponent } from '../basechart/basechart.component';

@Component({
  selector: 'app-worldmap',
  templateUrl: '../basechart/basechart.component.html',
})
export class WorldmapComponent extends BaseChartComponent<
  { iso3: string; volume: number; name: string; realName: string }[]
> {
  @Input() titleY: string;
  @Input() suffix: string;
  options: Highcharts.Options = {
    chart: {
      map: worldMapData,
    },

    title: {
      text: undefined,
    },

    legend: {
      enabled: false,
    },
    mapNavigation: {
      enabled: true,
      buttonOptions: {
        verticalAlign: 'bottom',
      },
      enableMouseWheelZoom: false,
    },
    series: undefined,
  };

  /* Use a non default factory function to create the chart */
  createChart(renderingId: string, options: Highcharts.Options): void {
    this.chart = Highcharts.mapChart(renderingId, options);
  }

  draw(renderingId: string) {
    this.options.title.text = this.title;

    const formattedData = this.formatData(this.data);
    this.options.series = [
      {
        type: 'map',
        name: 'Countries',
        color: '#E0E0E0',
        enableMouseTracking: false,
        states: {
          inactive: {
            enabled: false,
          },
        },
      },
      {
        type: 'mapbubble',
        name: this.titleY,
        joinBy: ['iso-a3', 'code3'],
        data: formattedData,
        minSize: 4,
        maxSize: '12%',
        tooltip: {
          // on mouse hover: "FR: 10 units"
          pointFormat: `{point.realName}: {point.z} ${this.suffix}`,
        },
      },
    ];
  }

  formatData(data: { name: string; iso3: string; volume: number }[]) {
    return data
      .filter((entry: { iso3: string; volume: number; name: string }) => typeof entry.iso3 === 'string')
      .map((entry: { iso3: string; volume: number; name: string }) => ({
        code3: entry.iso3,
        z: entry.volume,
        realName: entry.name,
      }));
  }
}

import { Component, effect, Input, OnChanges, OnInit, Signal, SimpleChanges } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import * as Highcharts from 'highcharts';
import { ChartColors, ChartType } from 'src/app/shared/model/activity';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ChartService } from 'src/app/shared/services/chart.service';
import { ItsmService } from 'src/app/shared/services/itsm.service';
import { WidgetService } from 'src/app/shared/services/widget.service';
import { Mtt, MttDTO, MttScope, MttType, ScopeWiseData } from 'src/app/shared/model/itsm';
import { Observable } from 'rxjs';
import { HighchartsChartModule } from 'highcharts-angular';
import { AppSharedModule } from 'src/app/app-shared.module';
import { ContextService } from 'src/app/shared/services/context-service';
import { UserTheme } from 'src/app/shared/model/shared-items';
import { FilterParameters } from 'src/app/shared/model/filterParameters';
import { UserAuthService } from 'src/app/shared/services/user-auth.service';

@Component({
  selector: 'app-mtt-report',
  standalone: true,
  imports: [TranslateModule, HighchartsChartModule, AppSharedModule],
  templateUrl: './mtt-report.component.html',
  styleUrl: './mtt-report.component.scss'
})
export class MttReportComponent implements OnChanges, OnInit {

  @Input() smallScreenMode: boolean;
  @Input() mttType: MttType;
  @Input({ required: true }) filterSignal!: Signal<FilterParameters>;

  public graphMaxWidth: string;
  public title: string;
  public mttLoading = true;
  public Highcharts: typeof Highcharts = Highcharts;
  public chartOptions: Highcharts.Options;
  public errorMessage: string = null;
  public theme: UserTheme;
  public tooltipText = '';

  protected timePeriod = 1;

  protected mttScope: MttScope = MttScope.INDUSTRY;

  private readonly chartSeries: Highcharts.SeriesColumnOptions[] = [];
  private priorityMap: Map<string, string>;
  private readonly mttPriorityMap = new Map<string, string>([
    ['high', '2'],
    ['medium', '3'],
    ['low', '4'],
  ]);
  private readonly mttRespondPriorityMap = new Map<string, string>([
    ['high', '[EVAL] - CSOC - Mean time to React - P2'],
    ['medium', '[EVAL] - CSOC - Mean time to React - P3'],
    ['low', '[EVAL] - CSOC - Mean time to React - P4'],
  ]);
  private readonly priorityColorMap = new Map<string, string>([
    ['critical', ChartColors.red],
    ['high', ChartColors.orange],
    ['medium', ChartColors.yellow],
    ['low', ChartColors.blue],
    ['planning', ChartColors.green]
  ]);

  constructor (
    protected itsmService: ItsmService,
    protected alertService: AlertService,
    protected translateService: TranslateService,
    protected widgetService: WidgetService,
    protected chartService: ChartService,
    protected context: ContextService,
    protected userAuthService: UserAuthService,
  ) {
    effect(() => { 
      this.filterData(this.filterSignal());
    });
    this.theme = this.context.userSelectedTheme;
  }

  ngOnInit() {
    this.title = `pages.dashboard.${this.mttType}`;
    this.tooltipText = `pages.reports.CSOCReports.${this.mttType}Desc`;
  }

  ngOnChanges(changes: SimpleChanges): void {
    // for responsive
    if (changes.smallScreenMode && !changes.smallScreenMode.firstChange) {
      if (this.smallScreenMode) {
        this.graphMaxWidth = (window.innerWidth * 80/100) + 'px';
        this.setupGraph();
      } else {
        this.graphMaxWidth = '';
      }
    }
  }

  protected initMtt() {
    // lookup for CSOCAccess property in child components
    if (this.hasOwnProperty('CSOCAccess') && !this['CSOCAccess']) {
      this.mttLoading = false;
      return;
    }
    this.mttLoading = true;
    let getMTT: Observable<MttDTO>;
    switch(this.mttType) {
      case MttType.MTTA:
        getMTT = this.itsmService.getMTTA(this.timePeriod);
        break;
      case MttType.MTTRESPOND:
        getMTT = this.itsmService.getMTTRespond(this.timePeriod);
        break;
      case MttType.MTTRESOLVE:
        getMTT = this.itsmService.getMTTResolve(this.timePeriod);
        break;
      default: 
        getMTT = new Observable<MttDTO>();
    }
    getMTT.subscribe({
      next: (mttData: MttDTO) => {
        this.setupData(mttData);
        this.mttLoading = false;
      },
      error: () => {
        const reportName = this.translateService.instant(this.title);
        this.alertService.handlerError(this.translateService.instant('pages.reports.CSOCReports.cantFetchData', {report: reportName}));
        this.errorMessage = this.errorMessage = this.translateService.instant('pages.securitycase.noDataAvailable');
        this.mttLoading = false;
      }
    });
  }

  protected setupData(mttData: MttDTO) {
    try {
      this.generateSeries(mttData);
      this.setupGraph();
    } catch (error) {
      this.errorMessage = this.translateService.instant('pages.securitycase.noDataAvailable');
    }
  }

  private generateSeries(mttData: MttDTO) {
    this.chartSeries.splice(0, this.chartSeries.length);
    const scopeWiseData: ScopeWiseData = this.generateScopeWiseData(mttData.map);
    Object.values(MttScope).forEach( (scope: string, index) => {
      const scopeName = scope === MttScope.COMPANY
        ? this.userAuthService.selectedTenant
        : this.translateService.instant('pages.reports.CSOCReports.ocdAverage');
      this.chartSeries.push({
        name: scopeName,
        data: scopeWiseData[scope],
        type: ChartType.column,
        color: this.priorityColorMap.get(scope === MttScope.INDUSTRY ? 'high' : 'planning')
      })
    });
    
  }

  private setupGraph() {
    // mttResolve has to be displayed in hours. Mtta and mttRespond in minutes
    const yAxisTitle = this.mttType === MttType.MTTRESOLVE
      ? this.translateService.instant('pages.dashboard.averageBusinessElapsedTimeInHours')
      : this.translateService.instant('pages.dashboard.averageBusinessElapsedTime');
    this.chartOptions = this.chartService.initColumnOptionsWithSeriesOptionsType(
      '',
      [...this.priorityMap.keys()],
      yAxisTitle,
      this.chartSeries
    );
    this.widgetService.updateWidgetStyle(this.chartOptions, this.theme);

    // mttResolve has to be displayed in hours. Mtta and mttRespond in minutes
    const minutesTooltip = '<tr><td style="color:{series.color};padding:0">{series.name}: </td><td style="padding:5"><b>{point.y} minutes</b></td></tr>'
    const hoursTooltip = '<tr><td style="color:{series.color};padding:0">{series.name}: </td><td style="padding:5"><b>{point.y} hours</b></td></tr>'
    this.chartOptions.tooltip.pointFormat = this.mttType === MttType.MTTRESOLVE
      ? hoursTooltip
      : minutesTooltip;
  }

  /**
   * Map data from a MttDTO.map sctructure to a ScopWiseData structure
   * and round meantime values
   * @param mtts 
   * @returns ScopeWiseData with rounded meantime values
   */
  protected generateScopeWiseData(mtts: {industry: Mtt, company: Mtt}): ScopeWiseData {
    let result: ScopeWiseData = {industry: [], company: []}
    this.priorityMap = this.getPriorityMap();
    
    Object.values(MttScope).forEach( scope => {
      let scopedMtt = mtts[scope];
      let prioritiesAvg = [];
      this.priorityMap.forEach( priority => {
        prioritiesAvg.push(
          scopedMtt[priority]
            ? this.getRoundedMeanTime(scopedMtt[priority].data[0].average)
            : 0
        )
      });
      result[scope] = prioritiesAvg;
    })
    return result;
  }

  private filterData(filterParams: FilterParameters) {
    switch(filterParams.selectedRadioDate) {
      case 'lastTwelveMonth': this.timePeriod = 12; break;
      case 'lastSixMonth': this.timePeriod = 6; break;
      case 'lastThreeMonth': this.timePeriod = 3; break;
      case 'lastTwoMonth': this.timePeriod = 2; break;
      case 'lastMonth': this.timePeriod = 1; break;
      default: this.timePeriod = 12;
    }
    this.initMtt();
  }

  private getPriorityMap() {
    return this.mttType === MttType.MTTRESPOND ? this.mttRespondPriorityMap : this.mttPriorityMap;
  }

  private getRoundedMeanTime(timeInMinutes: number): number {
    // get mttResolve times in hours and return rounded up value
    return Math.ceil(this.mttType === MttType.MTTRESOLVE ?  timeInMinutes / 60 : timeInMinutes);
  }

}

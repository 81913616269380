<section class="content">
    <div class="header">
        <!-- category -->
        <div class="btn-group" role="group" data-bs-theme="dark">
            <a class="btn btn-outline-secondary me-2" [ngClass]="{'active': selectedCategory === 'feedback'}"
                (click)="switch('feedback')">Feedback</a>
            <a class="btn btn-outline-secondary" [ngClass]="{'active': selectedCategory === 'survey'}"
                (click)="switch('survey')">Survey</a>
        </div>
    </div>

    <section>
        @if(selectedCategory === "feedback") {
        <app-feedback-summary></app-feedback-summary>
        } @else {
        <app-survey-summary></app-survey-summary>
        }
    </section>
</section>
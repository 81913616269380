import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from 'src/app/shared/services/alert.service';
import { WorldWatchService } from 'src/app/shared/services/worldwatch.service';
import { UserAuthService } from 'src/app/shared/services/user-auth.service';
import { environment } from 'src/environments/environment';
import { ItsmService } from 'src/app/shared/services/itsm.service';
import { SupportCaseSearchCriteria } from 'src/app/shared/model/searchCriteria';
import { UserActivityService } from 'src/app/shared/services/user-activity.service';
import { ACTIONS, PAGES, TYPES } from 'src/app/shared/constants';
import { ContextService } from 'src/app/shared/services/context-service';
import { TimeStamp } from '../dashboard/worldwatch.component';
import { WorldWatchUtilService } from '../worldwatch-util-service';

@Component({
  selector: 'app-viewsignal',
  templateUrl: './viewsignal.component.html',
  styleUrls: ['./viewsignal.component.scss']
})
export class ViewSignalComponent implements OnInit {

  advisoryId: string;
  advisory: Advisory;
  loadingSignal: boolean;
  worldWatchGroup = environment.world_watch_group;
  isWorldWatchUser: boolean;
  severityStyle: string;
  haveTicket = false;
  public isDarkTheme: boolean;

  public createBlock: ContentBlock;
  public updateBlocks: ContentBlock[] = [];

  public internal = SourceType.INTERNAL;
  public external = SourceType.EXTERNAL;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private worldwatchService: WorldWatchService,
    private alertService: AlertService,
    private userAuthService: UserAuthService,
    private itsmService: ItsmService,
    private userActivity: UserActivityService,
    private contextService: ContextService,
    private worldWatchUtilService: WorldWatchUtilService
  ) { }

  ngOnInit(): void {
    this.isDarkTheme = this.contextService.isDarkTheme();
    this.route.params.subscribe(params => {
      this.advisoryId = params.signalRef;
      this.loadAdvisory();
    });
  }

  private loadAdvisory() {
    this.loadingSignal = true;

    if (this.userAuthService.userDetails?.groups.indexOf(this.worldWatchGroup) > -1) {

      this.isWorldWatchUser = true;
      this.worldwatchService.getAdvisoryById(this.advisoryId).subscribe({
        next: response => {
          this.advisory = response;
          this.manageResponse();
          this.fetchTickets();
          this.loadingSignal = false;
        }, 
        error: error => {
          this.loadingSignal = false;
          this.alertService.handlerError(error);
        }
      })

    } else {
      // if the user doesn't have worldwatch role
      this.loadingSignal = false;
      this.isWorldWatchUser = false;
    }

    this.userActivity.logActivity(TYPES.resources, PAGES.viewSignal, ACTIONS.visit);
  }

  private manageResponse() {
    this.severityStyle = this.worldWatchUtilService.getSeverityStyle(this.advisory.severity)
    this.advisory.contentBlocks.forEach(block => {
      if(block.index === 0) {
        this.createBlock = block;
      } else {
        this.updateBlocks.push(block)
      }
    })
  }

  public getExternalLink(block: ContentBlock) {
    return block.sources.filter(source => source.type === SourceType.EXTERNAL);
  }

  public getInternalLink(block: ContentBlock) {
    return block.sources.filter(source => source.type === SourceType.INTERNAL);
  }

  downloadPDF(): void {
    this.worldwatchService.getPDFAdvisory(this.advisoryId)
      .subscribe({
        next:(response) => {
          const file = new Blob([response], { type: 'application/pdf' });
          if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
            (window.navigator as any).msSaveOrOpenBlob(file);
          } else {
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
          }
        },
        error:(error) => {
          this.alertService.handlerError(error)
        }
      })
  }

  fetchTickets() {
    const searchCritera = new SupportCaseSearchCriteria();
    searchCritera.worldWatchSignal = this.advisory.tdcId;
    this.itsmService.caseSearch(searchCritera)
      .then(res => {
        if (res.length > 0) {
          this.haveTicket = true;
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  public viewTickets() {
    this.router.navigate(['/support/dashboard/worldwatch'], {queryParams: {signalRef: this.advisory.tdcId}} )
  }
}

export class Advisory {
  id: string;
  tdcId: string;
  title: string;
  severity: string;
  tags: string[];
  threatCategory: string;
  contentBlocks: ContentBlock[];
  timestamp_created: TimeStamp;
  timestamp_updated: TimeStamp;
  license_agreement: string;
}

export class ContentBlock {
  id: number;
  advisory: number;
  index: number;
  title: string;
  tags: string[];
  severity: number;
  analyst: number;
  lastModifiedBy: string;
  threatCategory: string;
  executiveSummary: string;
  whatYouWillHear: string;
  whatItMeans: string;
  whatYouShouldDo: string;
  whatWeAreDoing: string;
  sources: SourceSchema[];
  detectionRules: DetectionRuleSchema[];
  datalakeUrl: DatalakeUrlSchema;
  other: string;
  timestampCreated: TimeStamp;
  timestampUpdated: TimeStamp;
}

export class SourceSchema {
  id: number;
  title: string;
  url: string;
  description: string;
  type: SourceType;
}
export class DetectionRuleSchema {
  id: number;
  title: string;
  description: string;
  content: string
}

export class DatalakeUrlSchema {
  id: number;
  title: string;
  description: string;
  url: string
}

export enum SourceType {
  EXTERNAL = "EXTERNAL",
  INTERNAL = "INTERNAL"
}

import { Component, Input } from '@angular/core';
import { ContextService } from 'src/app/shared/services/context-service';
import { DateTime } from 'luxon';
import { XtendedFiltersService } from '../../xtendedfilters.service';
import { Router } from '@angular/router';
import { Severity, SLANotification } from 'src/app/shared/model/cybersocxdr/facetsValues';
import { AlertService } from 'src/app/shared/services/alert.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-route-withfacets',
  templateUrl: './route-withfacets.component.html',
})
export class RouteWithfacetsComponent {
  @Input() title?: string;
  @Input() actionTitle?: string;
  @Input() countOlderThan1Month?: number;
  @Input() actionRoute?: string;
  @Input() buttonId?: string;

  private today = DateTime.now();
  isDarkThemeEnabled: boolean;

  constructor(
    public globalFilters: XtendedFiltersService,
    private router: Router,
    private alertService: AlertService,
    private contextService: ContextService,
    private translate: TranslateService
  ) {
    this.isDarkThemeEnabled = contextService.isDarkTheme();
  }

  onActionClick() {
    switch (this.buttonId) {
      case 'olderThanAMonth':
        this.changeCreatedFromDate();
        break;
      case 'criticalIncidents':
        this.filterCriticalIncidents();
        break;
      case 'newIncidents':
        this.filterNewIncidents();
        break;
      default:
        this.alertService.addError(this.translate.instant(`pages.cybersocxdr.backlog.defaultActionClick`));
        break;
    }
  }

  getTitle() {
    const titles = {
      olderThanAMonth: 'pages.cybersocxdr.backlog.infoBulleOlderThanAMonth',
      criticalIncidents: 'pages.cybersocxdr.backlog.infoBulleCriticalIncidents',
      newIncidents: 'pages.cybersocxdr.backlog.infoBulleNewIncidents',
    };
    return titles[this.buttonId] || 'pages.cybersocxdr.backlog.defaultInfoBulle';
  }

  changeCreatedFromDate() {
    const startDate = this.today.minus({ months: 7 }).toFormat('yyyy-MM-dd');
    const endDate = this.today.minus({ months: 1 }).toFormat('yyyy-MM-dd');

    this.globalFilters.searchCriteria = {
      ...this.globalFilters.searchCriteria,
      createdFrom: startDate,
      createdTo: endDate,
      severitys: [Severity.HIGH, Severity.CRITICAL],
      slaNotification: [SLANotification.BREAK, SLANotification.AT_RISK, SLANotification.IN],
    };
    this.globalFilters.serializeSeachCriteria();
  }

  filterIncidents(type: 'critical' | 'new') {
    const startDate = this.today.minus({ hours: 24 }).toFormat('yyyy-MM-dd');
    this.globalFilters.searchCriteria = {
      ...this.globalFilters.searchCriteria,
      createdFrom: startDate,
      severitys: type === 'critical' ? [Severity.CRITICAL] : [],
    };
    this.globalFilters.serializeSeachCriteria();
  }

  filterCriticalIncidents() {
    this.filterIncidents('critical');
  }

  filterNewIncidents() {
    this.filterIncidents('new');
  }

  changeCreatedFromDateAndNavigate() {
    this.globalFilters.resetFilters();
    this.onActionClick();
    if (this.actionRoute) {
      this.router.navigate([this.actionRoute]);
    }
  }
}

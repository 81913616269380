import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TenantAccess } from '../model/TenantInfo';

@Injectable({
  providedIn: 'root'
})
export class HttpAccountmapperService {

  private accountMapperURL =  environment.endpoints.itsm;
  private getAccessesURL = `${this.accountMapperURL}/account/tenantAccesses`;

  constructor(private httpClient: HttpClient) { }

  /**
   * GET list of accesses
   */
  async getTenantAccesses(): Promise<TenantAccess> {
    const tenantAccesses$: Observable<TenantAccess> = this.httpClient.get(this.getAccessesURL) as Observable<TenantAccess>;
    return await firstValueFrom(tenantAccesses$);
  }
  
}

import { Component, Input, OnInit, signal } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { HighchartsChartModule } from 'highcharts-angular';
import { AppSharedModule } from 'src/app/app-shared.module';
import { MttReportComponent } from 'src/app/reports/threat-detection-and-response/mtt-report/mtt-report.component';
import { FilterParameters } from 'src/app/shared/model/filterParameters';
import { MttDTO, MttType, ScopeWiseData } from 'src/app/shared/model/itsm';
import { CssTheme } from 'src/app/shared/model/shared-items';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ChartService } from 'src/app/shared/services/chart.service';
import { ContextService } from 'src/app/shared/services/context-service';
import { ItsmService } from 'src/app/shared/services/itsm.service';
import { UserAuthService } from 'src/app/shared/services/user-auth.service';
import { WidgetService } from 'src/app/shared/services/widget.service';

@Component({
  selector: 'app-mtt-widget',
  standalone: true,
  imports: [TranslateModule, HighchartsChartModule, AppSharedModule],
  templateUrl: './mtt-widget.component.html',
  styleUrl: './mtt-widget.component.scss'
})
export class MttWidgetComponent extends MttReportComponent implements OnInit {

  @Input() mttType: MttType = MttType.MTTA;
  @Input() widgetTheme: CssTheme;
  @Input() CSOCAccess: boolean;
  
  public mttLoading = true;
  public incipit: string;
  public companyAverageText: string;
  public companyAverageValue: number;
  public ocdAverageText: string;
  public ocdAverageValue: number;
  public timeUnit: string;
  public errorMessage = '';
  
  protected filterSignalEffect = null;
  protected isWidget = true;

  constructor(
    protected itsmService: ItsmService,
    protected alertService: AlertService,
    protected translateService: TranslateService,
    protected widgetService: WidgetService,
    protected chartService: ChartService,
    protected context: ContextService,
    protected userAuthService: UserAuthService
  ) {
    super(itsmService, alertService, translateService, widgetService, chartService, context, userAuthService);
  }

  ngOnInit(): void {
    this.filterSignal = signal(new FilterParameters());
    this.title = `pages.dashboard.${this.mttType}`;
    this.incipit = `pages.dashboard.widgets.mtt.${this.mttType}Desc`;
    this.companyAverageText = `pages.dashboard.widgets.mtt.${this.mttType}Average`;
    this.ocdAverageText = `pages.dashboard.widgets.mtt.${this.mttType}OCDAverage`;
    this.timeUnit = `pages.dashboard.widgets.mtt.${this.mttType}TimeUnit`;
    if (this.CSOCAccess) {
      this.initMtt();
    } else {
      this.errorMessage = this.translateService.instant('pages.dashboard.widgets.mtt.requireCSOCReportsAccess');
    }
  }

  setupData(mttData: MttDTO) {
    const scopeWiseData: ScopeWiseData = this.generateScopeWiseData(mttData.map);
    this.companyAverageValue = scopeWiseData.company[0];
    this.ocdAverageValue = scopeWiseData.industry[0];
  }

}

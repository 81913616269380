import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { UserAuthService } from 'src/app/shared/services/user-auth.service';
import { constants } from 'src/app/shared/constants';
import { MandatoryNotificationCategory, NotificationCategory, NotificationPreferences } from 'src/app/shared/model/notification';
import { AlertService } from 'src/app/shared/services/alert.service';
import { UserPreferenceService } from 'src/app/shared/services/userpreferences.service';
import { Utilities } from 'src/app/shared/utilities';

@Component({
  selector: 'app-service-settings',
  templateUrl: './service-settings.component.html',
  styleUrls: ['./service-settings.component.scss']
})
export class ServiceSettingsComponent {

  get worldwatchSubscriptionEnabled() {
    return this.serviceSettingsForm.get('worldwatchEmailSubscription');
  } 

  notificationCategories: string[];
  notificationPreferences: NotificationPreferences;
  preferencesForm = new FormGroup({});
  preferences;
  public isTenantAdmin = false;

  private advisoyMaxLevel = 5;
  public advisoriesValues = [];

  serviceSettingsForm: FormGroup<{
    worldwatchEmailSubscription: FormControl<boolean | null>;
    advisoryLevel: FormControl<number | null>;
  }>;

  constructor(
    private userPreferenceService: UserPreferenceService,
    public userAuthService: UserAuthService,
    private alertService: AlertService,
    private translateService: TranslateService,
    private fb: FormBuilder
  ) {

    const allCategories = Utilities.enumToStringArray(NotificationCategory);
    const allMandatoryCategories = Utilities.enumToStringArray(MandatoryNotificationCategory);
    this.notificationCategories = allCategories.filter((cat) => !allMandatoryCategories.includes(cat));

    for(let i = 0; i <= this.advisoyMaxLevel; i++) {
      this.advisoriesValues.push(i);
    }

    this.initServiceSettingsForm();
    this.initNotificationForm();
  }

  private initServiceSettingsForm() {

    const serviceSettingsPreferences = this.userPreferenceService.userPreferences?.serviceSettings;

    const advisoriesLevelValue = serviceSettingsPreferences?.advisoryLevel ? serviceSettingsPreferences?.advisoryLevel : 0;

    // by default, all users are subscribed to ww email subscription
    let isWorldwatchEmailSubscribed = !serviceSettingsPreferences || serviceSettingsPreferences.worldwatchEmailSubscription === false ? false : true

    this.serviceSettingsForm = this.fb.group({
      worldwatchEmailSubscription: this.fb.control(isWorldwatchEmailSubscribed),
      advisoryLevel: this.fb.control({value : advisoriesLevelValue, disabled: !serviceSettingsPreferences?.worldwatchEmailSubscription})
    });

    const advisoriesLevelCtrl = this.serviceSettingsForm.get('advisoryLevel');
    this.serviceSettingsForm.get('worldwatchEmailSubscription').valueChanges.subscribe(change => {
      change ? advisoriesLevelCtrl.enable() : advisoriesLevelCtrl.disable();
    })

    // update database value when one value change
    this.serviceSettingsForm.valueChanges.subscribe(() => {
      this.updateServiceSettingsPreferences();
    })

  }

  private initNotificationForm() {
    const notifications = this.userPreferenceService.userPreferences?.notificationPreference;
    this.notificationPreferences = notifications !== undefined ? notifications : new NotificationPreferences();
    this.notificationCategories.forEach(cat => {
      if (this.notificationPreferences[cat] !== undefined) {
        this.preferencesForm.addControl(cat, new FormControl(this.notificationPreferences[cat] === 1));
      } else {
        this.preferencesForm.addControl(cat, new FormControl(false));
      }
    });

    this.preferencesForm.valueChanges.subscribe(() => {
      this.updateNotificationsPreferences();
    })
  }

  /**
   * update notifications preferences
   */
  private updateNotificationsPreferences() {
    this.notificationPreferences = new NotificationPreferences();
    this.notificationCategories.forEach(cat => {
      if (this.preferencesForm.value[cat] !== undefined) {
        this.notificationPreferences.preferences[cat] = this.preferencesForm.value[cat] ? 1 : 0;
      }
    });

    // update user notification
    if (this.preferencesForm.valid && this.preferencesForm.dirty) {
      this.userPreferenceService.updateUserNotificationPreferences(this.notificationPreferences)
        .then(res => {
          // update context preferences and local storage with new notification preferences
          this.userPreferenceService.userPreferences.notificationPreference = res.notificationPreference;
          this.alertService.addSuccess(this.translateService.instant('pages.dashboard.user.preferencesUpdate'));
          sessionStorage.setItem(constants.local.userPreferences, JSON.stringify(res));
        })
        .catch(err => {
          this.alertService.handlerError(err);
        });
    }
  }

  /**
   * update service Settings preferences
   */
  private updateServiceSettingsPreferences() {
    // update service settings 
    if (this.serviceSettingsForm.valid && this.serviceSettingsForm.dirty) {
      this.userPreferenceService.userPreferencesUpdateSingle('serviceSettings', this.serviceSettingsForm.value)
        .then(() => {
          this.alertService.addSuccess(this.translateService.instant('pages.dashboard.user.preferencesUpdate'));
        })
        .catch(err => {
          this.alertService.handlerError(err);
        });
    }
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { graphconstants } from 'src/app/reports/reports-live/report-live-securitycase/graphconstants';
import { constants } from '../../constants';
import { Case } from '../../model/itsm';
import * as Highcharts from 'highcharts';
import { DataPoint } from 'src/app/reports/reports-live/report-live-securitycase/chartModels';
import { TranslateService } from '@ngx-translate/core';
import { WidgetService } from '../../services/widget.service';

@Component({
  selector: 'app-incidents-by-actors',
  templateUrl: './incidents-by-actors.component.html',
  styleUrls: ['./incidents-by-actors.component.scss']
})
export class IncidentsByActorsComponent implements OnInit {

  //data
  @Input()
  currentCases: Case[];
  @Input()
  previousCases: Case[];
  @Input()
  monthCases: Case[];
  @Input()
  currentPeriodName: string;
  currentPeriodNameExpanded: string;
  @Input()
  previousPeriodName: string;
  previousPeriodNameExpanded: string;

  monthNames = constants.monthNames;
  // calculated data
  currentData = {
    external: 0,
    internal: 0,
    partners: 0,
    unknown: 0,
    total: 0
  };
  previousData = {
    external: 0,
    internal: 0,
    partners: 0,
    unknown: 0,
    total: 0
  };
  highestMonthCases = {
    external: {} as any,
    internal: {} as any,
    partners: {} as any,
    unknown: {} as any,
    total: {} as any
  };
  lowestMonthCases = {
    external: {} as any,
    internal: {} as any,
    partners: {} as any,
    unknown: {} as any,
    total: {} as any
  };
  highestMonth: number;
  lowestMonth: number;

  externalTrend: string;
  internalTrend: string;
  partnersTrend: string;
  unknownTrend: string;
  totalTrend: string;

  //Pie Charts
  Highcharts: typeof Highcharts = Highcharts;
  actorDonutChartOptions = JSON.parse(JSON.stringify(graphconstants.pieChartOption));
  previousActorDonutChartOptions = JSON.parse(JSON.stringify(graphconstants.pieChartOption));
  updateDonutFlag = false;

  //Column Comparison
  columnComparisonChartOptions = JSON.parse(JSON.stringify(graphconstants.columnComparisonChartOptions));

    // Show Cases
  showIncidentByActorsBoxesCases: Case[] = [];
  showIncidentByActorsGraphCases: Case[] = [];
  showIncidentByActorsColumnCases: Case[] = [];
  resetCases(){
    this.showIncidentByActorsBoxesCases = [];
    this.showIncidentByActorsGraphCases = [];
    this.showIncidentByActorsColumnCases = [];
  }


  actors = ['external', 'internal', 'partners', 'unknown'];

  tasView: 'box' | 'ring' | 'column' = 'column';

  constructor(private translateService: TranslateService, private widgetService: WidgetService) { }

  ngOnInit(): void {
    this.currentPeriodNameExpanded = this.currentPeriodName === 'last30days' ? this.translateService.instant('pages.generic.last30days') : this.translateService.instant('pages.generic.lastFullCalendarMonth');
    this.previousPeriodNameExpanded = this.previousPeriodName === 'previous30days' ? this.translateService.instant('pages.generic.previous30days') : this.translateService.instant('pages.generic.previousFullCalendarMonth');

    // current and previous period
    this.currentCases.forEach((item)=>{
      if(item?.actor && this.actors.includes(item.actor.toLowerCase())){
        this.currentData[item.actor.toLowerCase()] += 1;
        this.currentData.total += 1;
      }
    });
    this.previousCases.forEach((item)=>{
      if(item?.actor && this.actors.includes(item.actor.toLowerCase())){
        this.previousData[item.actor.toLowerCase()] += 1;
        this.previousData.total += 1;
      }
    });

    this.externalTrend = this.currentData.external === this.previousData.external ? 'equal' :
                this.currentData.external > this.previousData.external ? 'up' : 'down';
    this.internalTrend = this.currentData.internal === this.previousData.internal ? 'equal' :
                this.currentData.internal > this.previousData.internal ? 'up' : 'down';
    this.partnersTrend = this.currentData.partners === this.previousData.partners ? 'equal' :
                this.currentData.partners > this.previousData.partners ? 'up' : 'down';
    this.unknownTrend = this.currentData.unknown === this.previousData.unknown ? 'equal' :
                this.currentData.unknown > this.previousData.unknown ? 'up' : 'down';
    this.totalTrend = this.currentData.total === this.previousData.total ? 'equal' :
                this.currentData.total > this.previousData.total ? 'up' : 'down';

    // month periods
    const fetchedMonths = {};
    this.monthCases.forEach((item: any) => {
      const date = new Date(item.createdDate);
      const month = date.getMonth();
      const year = date.getFullYear();
      const monthYear = `${month},${year}`;

      if(fetchedMonths[monthYear] == undefined) {
        fetchedMonths[monthYear] = 0;
      }
    });

    const dic = {
      external : JSON.parse(JSON.stringify(fetchedMonths)),
      internal : JSON.parse(JSON.stringify(fetchedMonths)),
      partners : JSON.parse(JSON.stringify(fetchedMonths)),
      unknown : JSON.parse(JSON.stringify(fetchedMonths)),
      total : JSON.parse(JSON.stringify(fetchedMonths))
    };

    this.monthCases.forEach((item: any) => {
      const date = new Date(item.createdDate);
      const actor = item.actor.toLowerCase();
      const month = date.getMonth();
      const year = date.getFullYear();
      const monthYear = `${month},${year}`;
      if(this.actors.includes(actor)){
        dic[actor][monthYear] += 1;
        dic.total[monthYear] += 1;
      }
    });

    const highest = {
      external : -1,
      internal : -1,
      partners : -1,
      unknown: -1,
      total: -1,
    };
    const lowest = {
      external : 1000000,
      internal : 1000000,
      partners : 1000000,
      unknown: 1000000,
      total: 10000000,
    };
    const highestMonths = {
      external : {month:0, year:0, amount:0},
      internal : {month:0, year:0, amount:0},
      partners : {month:0, year:0, amount:0},
      unknown: {month:0, year:0, amount:0},
      total: {month:0, year:0, amount:0},
    };
    const lowestMonths = {
      external : {month:0, year:0, amount:0},
      internal : {month:0, year:0, amount:0},
      partners : {month:0, year:0, amount:0},
      unknown: {month:0, year:0, amount:0},
      total: {month:0, year:0, amount:0}
    };

    Object.keys(dic).forEach((actor)=>{
      Object.keys(dic[actor]).forEach((monthYear)=>{
        const [month, year] = monthYear.split(',');
        const monthYearName = `${constants.monthNames[Number(month)]} ${year}`;

        if(dic[actor][monthYear] > highest[actor]){
          highest[actor] = dic[actor][monthYear];
          highestMonths[actor] = {month: monthYearName, year, amount: highest[actor]};
        }
        if(dic[actor][monthYear] < lowest[actor]){
          lowest[actor] = dic[actor][monthYear];
          lowestMonths[actor] = {month: monthYearName, year, amount: lowest[actor]};
        }
      });
    });

    this.highestMonthCases = highestMonths;
    this.lowestMonthCases = lowestMonths;


    // graphs
    //removing % from tooltip
    this.actorDonutChartOptions.tooltip.pointFormat = '{point.name}: {point.y}';
    this.previousActorDonutChartOptions.tooltip.pointFormat = '{point.name}: {point.y}';
    // build chart data
    const actorsCategories = [
      'external',
      'internal',
      'partners',
      'unknown'
    ];
    const preparedData = this.prepareDataForTwoChart(this.currentCases, this.previousCases, 'actor', 'Actor', actorsCategories);
    const dp1 = new DataPoint();
    const dp2 = new DataPoint();
    dp1.name = 'Actor';
    dp1.type = 'pie';
    dp1.innerSize = '50%';
    dp1.data = preparedData.serie1;
    dp2.name = 'Actor';
    dp2.type = 'pie';
    dp2.innerSize = '50%';
    dp2.data = preparedData.serie2;
    this.actorDonutChartOptions.series = [dp1];
    this.actorDonutChartOptions.title.text = '<strong>' + preparedData.totalCount1 + '<br/>cases</strong>';
    this.previousActorDonutChartOptions.series = [dp2];
    this.previousActorDonutChartOptions.title.text = '<strong>' + preparedData.totalCount2 + '<br/>cases</strong>';

    // add selecting cases
    this.actorDonutChartOptions.plotOptions.series = {
      point : {
        events : {
          click : (e) => {
            this.resetCases();
            const actor = e.point.name.toLowerCase(); // column
            this.currentCases.forEach((item)=> {
              const itemActor = item?.actor.toLowerCase();
              if (itemActor == actor){
                  this.showIncidentByActorsGraphCases.push(item);
              }
            });
          },
        }
      }
    };
    this.previousActorDonutChartOptions.plotOptions.series = {
      point : {
        events : {
          click : (e) => {
            this.resetCases();
            const actor = e.point.name.toLowerCase(); // column
            this.previousCases.forEach((item)=> {
              const itemActor = item?.actor.toLowerCase();
              if (itemActor == actor){
                  this.showIncidentByActorsGraphCases.push(item);
              }
            });
          },
        }
      }
    };
    //column comparison
    const baseActors = [
      { name : 'external', y: 0},
      { name : 'internal', y: 0},
      { name : 'partners', y: 0},
      { name : 'unknown', y: 0},
    ];
    const actorsColors = constants.graphColors;
    const getData = (data,current: boolean) => baseActors.map((actor, i) => ({
      ...actor,
      ...data.find(a => a.name === actor.name),
      color: current ? actorsColors[i] : 'rgba(158, 159, 163, 0.5)'
    }));
    const self = this;
    this.columnComparisonChartOptions.series = [{
      color: 'rgba(158, 159, 163, 0.5)',
      pointPlacement: -0.2,
      linkedTo: 'main',
      data: getData(preparedData.serie2, false),
      name: this.previousPeriodName,
      events :{
        click(e) {self.selectColumnCasesByPeriod('previous', e.point.options.name);}
      }
    }, {
      name: this.currentPeriodName,
      id: 'main',
      dataLabels: [{
          enabled: true,
          inside: true,
          style: {
              fontSize: '16px'
          }
      }],
      data: getData(preparedData.serie1, true),
      events :{
        click(e) {self.selectColumnCasesByPeriod('current', e.point.options.name);}
      }
    }];
    this.widgetService.updateWidgetStyle(this.actorDonutChartOptions, null);
    this.widgetService.updateWidgetStyle(this.previousActorDonutChartOptions, null);
    this.widgetService.updateWidgetStyle(this.columnComparisonChartOptions, null);
  }

  selectCasesByMonth(monthYear: string, selected: string) {
    const [month, year] = monthYear.split(' ');
    const value = this.monthCases.filter( c => {
      const date = new Date(c.createdDate);
      const caseMonth = constants.monthNames[Number(date.getMonth())];
      const caseYear = String(date.getFullYear());
      if(selected === 'total') {
        return caseYear === year && caseMonth === month && this.actors.includes(c.actor.toLowerCase());
      }
      else {
        return caseYear === year && caseMonth === month && selected === c.actor;
      }
    });
    this.showIncidentByActorsBoxesCases = value;
  }

  selectCasesByPeriod(period: 'current' | 'previous', selected: string) {
    if(period === 'current') {
      if(selected === 'total') {
        this.showIncidentByActorsBoxesCases = this.currentCases.filter( c => this.actors.includes(c.actor.toLowerCase()));
      } else {
        this.showIncidentByActorsBoxesCases = this.currentCases.filter( c => c.actor.toLowerCase() === selected);
      }
    } else {
      if(selected === 'total') {
        this.showIncidentByActorsBoxesCases = this.previousCases.filter( c => this.actors.includes(c.actor.toLowerCase()));
      } else {
        this.showIncidentByActorsBoxesCases = this.previousCases.filter( c => c.actor.toLowerCase() === selected);
      }
    }
  }

  selectColumnCasesByPeriod(period: 'current' | 'previous', selected: string) {
    if(period === 'current') {
      this.showIncidentByActorsColumnCases = this.currentCases.filter(c => c.actor.toLowerCase() === selected);
    } else {
      this.showIncidentByActorsColumnCases = this.previousCases.filter(c => c.actor.toLowerCase() === selected);
    }

  }

  prepareDataForTwoChart(data1, data2, fieldName, dispName, categories = null as any){
    const dic = {};
    categories && categories.forEach(category => {
      dic[category] = {data1 :0, data2: 0};
    });

    data1.forEach((item: any) => {
      if (item[fieldName] && (!categories || categories.includes(item[fieldName]))){
        if(dic[item[fieldName].toLowerCase()]!= undefined){
          dic[item[fieldName].toLowerCase()].data1 = dic[item[fieldName].toLowerCase()].data1 + 1;
        }
        else{
          dic[item[fieldName].toLowerCase()] = { data1:1, data2:null};
        }
      }
    });
    data2.forEach((item: any) => {
      if (item[fieldName]&& (!categories || categories.includes(item[fieldName]))){
        if(dic[item[fieldName].toLowerCase()]==undefined) {
          dic[item[fieldName].toLowerCase()] = { data1:null, data2:1};
        }
        else if(dic[item[fieldName].toLowerCase()].data2 != undefined){
          dic[item[fieldName].toLowerCase()].data2 = dic[item[fieldName].toLowerCase()].data2 + 1;
        }
        else{
          dic[item[fieldName].toLowerCase()].data2 = 1;
        }
      }
    });

    let totalCount1 = 0;
    let totalCount2 = 0;
    const serie1 = [];
    const serie2 = [];

    Object.keys(dic).forEach((item: any, i)=>{
      if(dic[item]?.data1) {
        totalCount1 = totalCount1 + dic[item].data1;
      }
      if(dic[item]?.data2) {
        totalCount2 = totalCount2 + dic[item].data2;
      }
    });
    Object.keys(dic).forEach((item: any, i)=>{
      if(dic[item]?.data1) {
        serie1.push({
          name: item,
          y: dic[item].data1,
          color: constants.graphColors[i],
          percentage : dic[item].data1 / totalCount1
        });
      }
      if(dic[item]?.data2) {
        serie2.push({
          name: item,
          y: dic[item].data2,
          color: constants.graphColors[i],
          percentage : dic[item].data2 / totalCount2
        });
      }
    });
    return {serie1, serie2, totalCount1, totalCount2};
  }
}

import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { IncidentDataSource } from 'src/app/shared/model/cybersocxdr/incidentDataSourceEnum';
import { XtendedFiltersService } from '../../xtendedfilters.service';

export interface MenuLink {
  name: string;
  route: string;
  saveSearchDisplay: boolean;
  filterDisplay: boolean;
  haveTabs: boolean;
  sourceDisplay: boolean;
  sourceSelectable: boolean;
  onlyForDataSources?: IncidentDataSource[];
}

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
})
export class SidemenuComponent {
  constructor(private router: Router, private xtendedFiltersService: XtendedFiltersService) {}

  @Input()
  menuLinks: MenuLink[] = [];

  isActiveMenu(menu: MenuLink): boolean {
    return menu.route === this.router.url || (menu.haveTabs && this.router.url.startsWith(menu.route));
  }

  isVisible(menu: MenuLink): boolean {
    if (!menu.onlyForDataSources) {
      return true;
    }
    return this.xtendedFiltersService.clientInfo?.contracts.some((c) =>
      menu.onlyForDataSources?.includes(c.incidentDataSource)
    );
  }
}

import { Component, OnInit, signal } from '@angular/core';
import { MetricsFilterComponent } from "../../shared/components/metrics-filter/metrics-filter.component";
import { FilterParameters } from 'src/app/shared/model/filterParameters';
import { MttReportComponent } from "./mtt-report/mtt-report.component";
import { MttScope, MttType } from 'src/app/shared/model/itsm';
import { Utilities } from 'src/app/shared/utilities';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-threat-detection-and-response',
  standalone: true,
  imports: [
    MetricsFilterComponent,
    MttReportComponent,
    TranslateModule
  ],
  templateUrl: './threat-detection-and-response.component.html',
  styleUrl: './threat-detection-and-response.component.scss'
})
export class ThreatDetectionAndResponseComponent implements OnInit {
  public filterParameters: FilterParameters;
  public mttTypes = MttType;
  public mttTypesArr = Object.values(MttType);
  public filterSignal;

  public rangeRadioButtons: {label: string, key: string}[] = [];

  public dateRadioButtons = [
    { label: 'pages.dashboard.analytics.last12Months', key: 'lastTwelveMonth'},
    { label: 'pages.dashboard.analytics.last6Months', key: 'lastSixMonth'},
    { label: 'pages.dashboard.analytics.last3Months', key: 'lastThreeMonth'},
    { label: 'pages.dashboard.analytics.last2Months', key: 'lastTwoMonth'},
    { label: 'pages.dashboard.analytics.lastMonth', key: 'lastMonth'},
  ];

  ngOnInit(): void {
    this.filterParameters = new FilterParameters(
      Utilities.get1MonthAgo(),
      Utilities.getToday(),
      MttScope.COMPANY,
      'lastMonth'
    )
    this.filterSignal = signal(new FilterParameters());
    this.rangeRadioButtons = [];
  }

  public doFilter(params?: FilterParameters) {
    if (params) {
      this.filterParameters = params;
      this.filterSignal.set(this.filterParameters);
    }
  }

}

import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SeriesOptionsType } from 'highcharts';
import * as Highcharts from 'highcharts';
import { HighchartsChartModule } from 'highcharts-angular';
import { ChartService } from 'src/app/shared/services/chart.service';
import { WidgetService } from 'src/app/shared/services/widget.service';

@Component({
  selector: 'app-closed-choice-answer',
  standalone: true,
  imports: [HighchartsChartModule, TranslateModule],
  templateUrl: './closed-choice-answer.component.html',
  styleUrl: './closed-choice-answer.component.scss'
})
export class ClosedChoiceAnswerComponent implements OnChanges {

  public widget;
  public widgetData;
  public className;
  public highcharts: typeof Highcharts = Highcharts;

  @Input() data: any[];

  constructor(
    private translateService: TranslateService,
    private chartService: ChartService,
    private widgetService: WidgetService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data) {
      // Generate user regions data
      this.widgetData = this.data
        .map(item => ({ name: item._id, y: item.count }))
        .sort((c1, c2) => c2.y - c1.y);

      const regionsSeries: SeriesOptionsType[] = [
        {
          name: null,
          data: this.widgetData,
          type: 'pie'
        },
      ];

      this.widget = this.chartService.initCatPieChartOptions(
        null,
        regionsSeries,
        null
      )

      this.widgetService.updateWidgetStyle(this.widget);

      this.widgetService.addlegend(this.widget);
    }
  }

}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { oktaAuthGuard } from '../app.guard';
import { LayoutComponent } from '../layout/layout/layout.component';
import { ViewCpeComponent } from './vulnerabilities/viewcpe/viewcpe.component';
import { ViewSignalComponent } from './worldwatch/viewsignal/viewsignal.component';
import { ViewTagComponent } from './worldwatch/viewtags/viewtag.component';
import { ViewVulnComponent } from './vulnerabilities/viewvuln/viewvuln.component';
import { VulnerabilitiesComponent } from './vulnerabilities/dashboard/vulnerabilities.component';
import { WorldWatchComponent } from './worldwatch/dashboard/worldwatch.component';
import { BlogComponent } from './rss/blog/blog.component';
import { EventsComponent } from './rss/events/events.component';
import { NewsComponent } from './rss/news/news.component';
import { WhitepapersComponent } from './rss/whitepapers/whitepapers.component';
import { IntegrationsDashboardComponent } from './integrations/dashboard/integrations-dashboard.component';
import { IntegrationsAzureAdSSOComponent } from './integrations/azure-ad-sso/integrations-azure-ad-sso.component';
import { CertToolsComponent } from './cert-tools/page/cert-tools.component';
import { MfdUploadComponent } from './cert-tools/mfd-services/static-analysis/mfd-upload/mfd-upload.component';
import { MfdResultsComponent } from './cert-tools/mfd-services/static-analysis/mfd-results/mfd-results.component';
import { MfdSearchComponent } from './cert-tools/mfd-services/static-analysis/mfd-search/mfd-search.component';
import { MfdHistoryComponent } from './cert-tools/mfd-services/static-analysis/mfd-history/mfd-history.component';
import { DynamicMfdUploadComponent } from './cert-tools/mfd-services/dynamic-analysis/dynamic-mfd-upload/dynamic-mfd-upload.component';
import { OCDServicesComponent } from './ocd-services/ocd-services.component';
import { TrainingDashboardComponent } from './training/trainingdashboard/trainingdashboard.component';
import { TrainingCourseComponent } from './training/training-course/training-course.component';

const routes: Routes = [
  {
    path: 'updates',
    component: LayoutComponent,
    canActivate: [oktaAuthGuard],
    children: [
      { path: '', redirectTo: 'worldwatch', pathMatch: 'full' },
      {
        path: 'worldwatch/viewSignal/:signalRef',
        component: ViewSignalComponent,
        pathMatch: 'full',
        data: {
          title: 'pages.updates.viewSignal',
          urls: [{ title: 'menu.updates.worldwatch', url: '/updates/worldwatch/dashboard' }, {title: 'pages.updates.viewSignal'}],
        }
      },
      {
        path: 'worldwatch/viewTag/:tag',
        component: ViewTagComponent,
        pathMatch: 'full',
        data: {
          title: 'pages.updates.viewTag',
          urls: [{ title: 'menu.updates.worldwatch', url: '/updates/worldwatch/dashboard' }, {title: 'pages.updates.viewTag'}],
        }
      },
      {
        path: 'vulnerabilities/viewVuln/:cveRef',
        component: ViewVulnComponent,
        pathMatch: 'full',
        data: {
          title: 'pages.updates.viewVuln',
          urls: [{ title: 'menu.updates.vulnerabilities', url: '/updates/vulnerabilities/dashboard' }, {title: 'pages.updates.viewVuln'}],
        }
      },
      {
        path: 'vulnerabilities/viewCpe/:cpeRef',
        component: ViewCpeComponent,
        pathMatch: 'full',
        data: {
          title: 'pages.updates.viewCpe',
          urls: [{ title: 'menu.updates.vulnerabilities', url: '/updates/vulnerabilities/dashboard' }, {title: 'pages.updates.viewCpe'}],
        }
      },
      {
        path: 'training',
        component: TrainingDashboardComponent,
        pathMatch: 'full',
        data: {
          title: 'menu.updates.training',
          urls: [{title: 'menu.updates.training'}],
        }
      },
      {
        path: 'training/:id',
        component: TrainingCourseComponent,
        pathMatch: 'full',
        data: {
          title: 'menu.updates.training',
          urls: [{title: 'menu.updates.training'}],
        }
      },
      {
        path: 'blogs',
        component: BlogComponent,
        pathMatch: 'full',
        data: {
          title: 'menu.updates.rss.blog',
          urls: [{title: 'menu.updates.rss.blog'}],
        }
      },
      {
        path: 'events',
        component: EventsComponent,
        pathMatch: 'full',
        data: {
          title: 'menu.updates.rss.events',
          urls: [{title: 'menu.updates.rss.events'}],
        }
      },
      {
        path: 'news',
        component: NewsComponent,
        pathMatch: 'full',
        data: {
          title: 'menu.updates.rss.news',
          urls: [{title: 'menu.updates.rss.news'}],
        }
      },
      {
        path: 'whitepapers',
        component: WhitepapersComponent,
        pathMatch: 'full',
        data: {
          title: 'menu.updates.rss.whitepapers',
          urls: [{title: 'menu.updates.rss.whitepapers'}],
        }
      },
      {
        path: 'services',
        component: OCDServicesComponent,
        pathMatch: 'full',
        data: {
          title: 'menu.updates.ourServices',
          urls: [{ title: 'menu.updates.ourServices' }],
          banner: false,
        }
      },
      {
        path: 'services/certtools',
        component: CertToolsComponent,
        pathMatch: 'full',
        data: {
          title: 'Malicious File Detection',
          urls: [{title: 'Malicious File Detection'}],
        }
      },
      {
        path: 'services/certtools/mfdupload',
        component: MfdUploadComponent,
        pathMatch: 'full',
        data: {
          title: 'Static Malicious File Detection',
          urls: [{title: 'Static Malicious File Detection'}],
        }
      },
      {
        path: 'services/certtools/dynamicMFDupload',
        component: DynamicMfdUploadComponent,
        pathMatch: 'full',
        data: {
          title: 'Dynamic Malicious File Detection',
          urls: [{title: 'Dynamic Malicious File Detection'}],
        }
      },
      {
        path: 'services/certtools/mfdresults/:uploadId',
        component: MfdResultsComponent,
        pathMatch: 'full',
        data: {
          title: 'Malicious File Detection',
          urls: [{title: 'Malicious File Detection'}],
        }
      },
      {
        path: 'services/certtools/mfdsearch',
        component: MfdSearchComponent,
        pathMatch: 'full',
        data: {
          title: 'Malicious File Detection',
          urls: [{title: 'Malicious File Detection'}],
        }
      },
      {
        path: 'services/certtools/mfdhistory',
        component: MfdHistoryComponent,
        pathMatch: 'full',
        data: {
          title: 'Malicious File Detection',
          urls: [{title: 'Malicious File Detection'}],
        }
      },
      {
        path: 'integrations',
        component: IntegrationsDashboardComponent,
        pathMatch: 'full',
        data: {
          title: 'menu.updates.integrations.title',
          urls: [{title: 'menu.updates.integrations.title'}],
        }
      },
      {
        path: 'integrations/azureadsso',
        component: IntegrationsAzureAdSSOComponent,
        pathMatch: 'full',
        data: {
          title: 'menu.updates.integrations.title',
          urls: [{title: 'menu.updates.integrations.title'}],
        }
      }
    ]
  },
  {
    path: 'updates',
    component: LayoutComponent,
    canActivate: [oktaAuthGuard],
    children: [
      { path: '', redirectTo: 'worldwatch', pathMatch: 'full' },
      {
        path: 'worldwatch/dashboard/:loadpage',
        component: WorldWatchComponent,
        pathMatch: 'full',
        data: {
          title: 'menu.updates.worldwatch',
          urls: [{ title: 'menu.updates.worldwatch' }]
        }
      },
      {
        path: 'worldwatch/dashboard',
        component: WorldWatchComponent,
        pathMatch: 'full',
        data: {
          title: 'menu.updates.worldwatch',
          urls: [{ title: 'menu.updates.worldwatch' }],
          helpTip: 'menu.updates.worldwatchHeaderDesc'
        }
      },
      {
        path: 'vulnerabilities/dashboard',
        component: VulnerabilitiesComponent,
        pathMatch: 'full',
        data: {
          title: 'menu.updates.vulnerabilities',
          urls: [{ title: 'menu.updates.vulnerabilities' }],
          subtitle : 'pages.updates.vulnerabilities.vulnSubtitle'
        }
      },
      {
        path: 'vulnerabilities/dashboard/:loadpage',
        component: VulnerabilitiesComponent,
        pathMatch: 'full',
        data: {
          title: 'menu.updates.vulnerabilities',
          urls: [{ title: 'menu.updates.vulnerabilities' }],
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UpdatesRoutingModule { }

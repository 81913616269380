import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { HttpItsmService } from '../../httpServices/http-itsm.service';
import { constants } from 'src/app/shared/constants';
import { UserAuthService } from 'src/app/shared/services/user-auth.service';
import { AlertService } from '../../services/alert.service';

@Component({
  selector: 'app-upload-files',
  templateUrl: './upload-files.component.html',
  styleUrls: ['./upload-files.component.scss']
})
export class UploadFilesComponent {

  @Input()
  caseRef: string;

  @Output()
  flagEmitter = new EventEmitter<any>();

  @Input()
  user: string;

  @Input()
  existingAttachments: any[];

  selectedFiles: FileList;
  maxFileSize = constants.fileUploadRestrictions.maxSize; // 100meg
  maxFileSizeMB = this.maxFileSize / (1024 * 1024);
  uploading = false;

  fileInfos: Observable<any>;

  constructor(
    private translateService: TranslateService,
    private HttpItsmService: HttpItsmService,
    private userAuthService: UserAuthService,
    private alertService: AlertService
  ) { }

  selectFiles(event): void {
    this.selectedFiles = event.target.files;
    this.flagEmitter.emit(null);
  }

  upload(file): Promise<void> {

    const user = this.user ? this.user : this.userAuthService.userDetails.email;

    return this.HttpItsmService.postCaseAttachement(file, this.caseRef, user)
    .catch( response => {
      if (response.error) {
        this.flagEmitter.emit(this.mapUploadErrorCode(file, response.error.code));
        throw(response.error);
      }
    });
  }

  /**
  * upload the selected file
  */
  uploadFiles(): void {
    this.uploading = true;
    let errorRaised: string;
    let handledFilesCount = 0;

    for (const file of Array.from(this.selectedFiles)) {
      errorRaised = this.validFiles(file);
      if (errorRaised) {
        this.uploading = false;
        this.selectedFiles = null;
        this.flagEmitter.emit(errorRaised);
        this.alertService.addError(errorRaised);
        break;
      }
    }
    if (!errorRaised) {
      for (const file of Array.from(this.selectedFiles)) {
        this.upload(file).then( () => {
          handledFilesCount += 1;
          this.alertService.addSuccess(`${this.translateService.instant('pages.support.view.fileUploaded')}: ${file.name}`);
          if (handledFilesCount === this.selectedFiles.length) {
            this.uploading = false;
            this.selectedFiles = null;
            this.flagEmitter.emit(this.translateService.instant('pages.shared.uploadControlOK'));
          }
        })
        .catch( () => {
          this.uploading = false;
          this.selectedFiles = null;
        });
      }
    }
  }

  validFiles(selectedFile): string {
    
    // initialize values for check process
    const fileNameAlreadyExist = this.existingAttachments?.length > 0 && this.existingAttachments.filter(e => e.name === selectedFile.name).length > 0;
    const invalidSize = selectedFile.size > this.maxFileSize;
    const allowedFileType = environment.allowedFileTypes.includes(selectedFile.type);
    const extensionFiles = selectedFile.type === '' && selectedFile.name.split('.')[selectedFile.name.split('.').length-1];
    
    // check file has not already been uploaded
    if(fileNameAlreadyExist) {
      return this.translateService.instant('pages.shared.invalidFilename');
    } else if (invalidSize) {
      return this.translateService.instant('pages.shared.invalidSize', {maxSize: this.maxFileSizeMB});
    } else if (!allowedFileType && extensionFiles !== 'p7b' && extensionFiles !== 'pcap') {
      return this.translateService.instant('pages.shared.invalidFileType');
    }
  }

  mapUploadErrorCode(file: File, errorCode: number): string {
    let result: string;
    switch(errorCode) {
      case 2: 
        result = this.translateService.instant('pages.shared.maliciousContent', { fileName : file.name });
        break;
      case 3:
        result = this.translateService.instant(
          'pages.shared.uploadFailedCause.size',
          {
            fileName : file.name,
            maxSize: this.maxFileSize
          }
        );
        break;
      case 4: 
        result = this.translateService.instant(
          'pages.shared.uploadFailedCause.fileName',
          {
            fileName : file.name,
            illegalCharacters: constants.fileUploadRestrictions.illegalCharacters
          }
        );
        break;
      case 5: 
        result = this.translateService.instant('pages.shared.uploadFailedCause.mimeType', { fileName : file.name });
        break;
      default:
        result = this.translateService.instant('pages.shared.uploadFailed', { fileName : file.name });
    }
    return result;
  }

}

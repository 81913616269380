import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LayoutComponent } from './layout/layout/layout.component';
import { analyticsGuard, oktaAuthGuard } from './app.guard';
import { CallbackComponent } from './callback.component';
import { NotFoundComponent } from './shared/404/notfound.component';
import { TenantComponent } from './tenant/tenant.component';
import { NotAssignedComponent } from './notassigned/not-assigned.component';
import { NoMenuLayoutComponent } from './layout/nomenulayout/nomenulayout.component';
import { TermsOfUseComponent } from './shared/components/policies/terms-of-use/terms-of-use.component';
import { CookiePolicyComponent } from './shared/components/policies/cookie-policy/cookie-policy.component';
import { PrivacyPolicyComponent } from './shared/components/policies/privacy-policy/privacy-policy.component';
import { NotAuthorizedComponent } from './shared/403/not-authorized.component';
import { CustomizeDashboardComponent } from './dashboard/customize-dashboard/customize-dashboard.component';
import { AnalyticsComponent } from './dashboard/analytics/analytics.component';
import { UserAccountComponent } from './dashboard/user-account/user-account/user-account.component';
import { AssignedApplicationsComponent } from './assignedapplications/assigned-applications.component';
import { GlobalFeedbacksComponent } from './dashboard/global-feedbacks/global-feedbacks.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [oktaAuthGuard],
    children: [
      {
        path: 'tenants',
        component: TenantComponent,
        pathMatch: 'full',
        data: {
          title: 'Tenants',
          urls: [{ title: 'menu.dashboard' }],
        },
      },
      { path: 'support', loadChildren: () => import(`./support/support.module`).then((m) => m.SupportModule) },
      { path: 'updates', loadChildren: () => import(`./updates/updates.module`).then((m) => m.UpdatesModule) },
      { path: 'user', loadChildren: () => import(`./user/user.module`).then((m) => m.UserModule) },
      { path: 'activity', loadChildren: () => import(`./activity/activity.module`).then((m) => m.ActivityModule) },
      {
        path: 'asset',
        loadChildren: () => import(`./assetmanagement/assetmanagement.module`).then((m) => m.AssetManagementModule),
      },
      { path: 'reports', loadChildren: () => import(`./reports/reports.module`).then((m) => m.ReportsModule) },
      {
        path: 'xtended-soc',
        loadChildren: () => import(`./cybersocxdr/cybersocxdr.module`).then((m) => m.CybersocxdrModule),
      },
    ],
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [oktaAuthGuard],
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent,
        data: {
          urls: [{ title: 'menu.dashboard' }],
          fullWidth: true,
          title: 'dashboard',
        },
      },
      {
        path: 'termsofservice',
        component: TermsOfUseComponent,
        data: {
          title: 'Terms of service',
        },
      },
      {
        path: 'assignedapplications',
        component: AssignedApplicationsComponent,
        data: {
          title: 'Assigned Applications',
        },
      },
      {
        path: 'cookiepolicy',
        component: CookiePolicyComponent,
        data: {
          title: 'Cookie policy',
        },
      },
      {
        path: 'privacypolicy',
        component: PrivacyPolicyComponent,
        data: {
          title: 'Privacy policy',
        },
      },
      {
        path: 'customizedashboard',
        component: CustomizeDashboardComponent,
        data: {
          title: 'Customize dashboard',
        },
      },
      {
        path: 'useraccount',
        component: UserAccountComponent,
        data: {
          title: 'My account',
        },
      },
      {
        path: 'analytics',
        component: AnalyticsComponent,
        pathMatch: 'full',
        data: {
          title: 'Analytics',
          urls: [{ title: 'Analytics' }],
        },
      },
      {
        path: 'feedbacks',
        component: GlobalFeedbacksComponent,
        canActivate: [analyticsGuard],
        pathMatch: 'full',
        data: {
          title: 'Feedbacks',
          urls: [{ title: 'Feedbacks' }],
        },
      },
    ],
  },

  {
    path: 'callback',
    component: CallbackComponent,
  },
  {
    path: '',
    component: NoMenuLayoutComponent,
    canActivate: [oktaAuthGuard],
    children: [
      {
        path: 'not-assigned',
        component: NotAssignedComponent,
        data: {
          title: '401',
          urls: [{ title: '401' }],
        },
      },
    ],
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [oktaAuthGuard],
    children: [
      {
        path: 'not-authorized',
        component: NotAuthorizedComponent,
        data: {
          title: 'Access Denied',
        },
      },
    ],
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [oktaAuthGuard],
    children: [
      {
        path: '**',
        component: NotFoundComponent,
        data: {
          title: '404',
          urls: [{ title: '404' }],
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { anchorScrolling: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import { Component, Input, OnInit } from '@angular/core';
import { Charts } from 'src/app/shared/model/activity';
import * as Highcharts from 'highcharts';
import { FilterParameters, WidgetsOptions, defaultFilterParameters } from '../analytics.component';
import { ChartService } from 'src/app/shared/services/chart.service';
import { CountResponse, UserActivityService } from 'src/app/shared/services/user-activity.service';
import { TranslateService } from '@ngx-translate/core';
import { WidgetService } from 'src/app/shared/services/widget.service';
import { ContextService } from 'src/app/shared/services/context-service';
import { SubPages } from 'src/app/shared/model/shared-items';

@Component({
  selector: 'app-base-analytics',
  templateUrl: './base-analytics.component.html',
  styleUrl: './base-analytics.component.scss',
  providers: [ChartService, UserActivityService, TranslateService, WidgetService, ContextService]
})
export class BaseAnalyticsComponent extends Charts implements OnInit {

  @Input() filterParameters: FilterParameters;
  @Input() currentTab: SubPages.actionsAnalytics | SubPages.activityAnalytics;

  public pageLoading = false;
  public componentLoaded = false;
  public widgetsOptions: WidgetsOptions[] = [];

  public routesCount: { id: string; count: number}[];
  public highcharts: typeof Highcharts = Highcharts;

  constructor(
    protected userActivityService: UserActivityService,
    protected widgetService: WidgetService,
    protected contextService: ContextService
  ) {
    super();
  }

  ngOnInit(): void {
    this.componentLoaded = true;
    this.filter(this.filterParameters);
  }

  public filter(params: FilterParameters): void {
    if (!params) {
      params = defaultFilterParameters;
    }
    this.pageLoading = true;
    this.userActivityService.getActivitiesSummaries(
      this.currentTab,
      params.createdFrom,
      params.createdTo,
      params.selectedRadioRange
    ).then( res => this.filterCallBack(res));
  }

  protected filterCallBack(res: CountResponse) {
    /* Overridden in children components. Declared here to allow filter() factorization */
  }

  public loadWidgets(options: Highcharts.Options[]) {
    const defaultClass = 'defaultChart';
    this.widgetsOptions = [];
    options.forEach( option => {
      this.widgetService.updateWidgetStyle(option, this.contextService.userSelectedTheme);
      this.widgetsOptions.push(new WidgetsOptions(option, defaultClass));
    });
  }

  protected generateChartsOptions(): Highcharts.Options[] {
    return [Highcharts.defaultOptions];
  }
}

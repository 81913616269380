import { Component, Input, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-rating-answer',
  standalone: true,
  imports: [],
  templateUrl: './rating-answer.component.html',
  styleUrl: './rating-answer.component.scss'
})
export class RatingAnswerComponent {

  @Input() data: any[];
  public average: number;
  public maxRange = 10;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data) {
      // Generate user regions data
      if(this.data[0]?.average) {
        this.average = Number(this.data[0].average.toFixed(2));
      }
    }
  }

}

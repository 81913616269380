import { Component, Input } from '@angular/core';
import { ContextService } from 'src/app/shared/services/context-service';
import { XtendedFaqListComponent } from './faq/list/xtended-faq-list.component';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { XtendedFaqPreviewComponent } from './faq/preview/xtended-faq-preview.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'app-xtended-help',
  standalone: true,
  imports: [CommonModule, XtendedFaqListComponent, XtendedFaqPreviewComponent, TranslateModule, FontAwesomeModule],
  templateUrl: './xtended-help.component.html',
  styleUrls: ['./xtended-help.component.scss'],
})
export class XtendedHelpComponent {
  @Input() isHelpOpen: boolean = false;

  faqs: Array<{ question: string; answer: string }> = [
    {
      question: 'pages.cybersocxdr.faq.questions.mainSections.question',
      answer: 'pages.cybersocxdr.faq.questions.mainSections.answer',
    },
    {
      question: 'pages.cybersocxdr.faq.questions.homePage.question',
      answer: 'pages.cybersocxdr.faq.questions.homePage.answer',
    },
    {
      question: 'pages.cybersocxdr.faq.questions.incidentManagement.question',
      answer: 'pages.cybersocxdr.faq.questions.incidentManagement.answer',
    },
    {
      question: 'pages.cybersocxdr.faq.questions.fourAThreeW.question',
      answer: 'pages.cybersocxdr.faq.questions.fourAThreeW.answer',
    },
    {
      question: 'pages.cybersocxdr.faq.questions.slaMetrics.question',
      answer: 'pages.cybersocxdr.faq.questions.slaMetrics.answer',
    },
    {
      question: 'pages.cybersocxdr.faq.questions.dashboardCustomization.question',
      answer: 'pages.cybersocxdr.faq.questions.dashboardCustomization.answer',
    },
    {
      question: 'pages.cybersocxdr.faq.questions.contractSelection.question',
      answer: 'pages.cybersocxdr.faq.questions.contractSelection.answer',
    },
    {
      question: 'pages.cybersocxdr.faq.questions.feedback.question',
      answer: 'pages.cybersocxdr.faq.questions.feedback.answer',
    },
  ];

  selectedFaqIndex: number | null = null;

  constructor(public contextService: ContextService) {}

  viewFaq(index: number): void {
    this.selectedFaqIndex = index;
  }

  onBack(): void {
    this.selectedFaqIndex = null;
  }
}

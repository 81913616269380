import { LocalStorageItem } from './model/shared-items';
import { stopWords } from './stopwords';

export const constants = {
    monthNames : ['January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December' ],
    accessLevels : {
        0: 'Internal',
        1: 'Confidential',
        2: 'Restricted'
    },
    changeTypes : {
        standardChange: 'Standard Change',
        normalChange: 'Normal Change',
        emergencyChange: 'Emergency Change'
    },
    recordsPerPage : {
        signals : 12,
        vulns: 12,
        activity: 6,            // portal dashboard
        activitydashboard: 12,  // activity dashboard
        cases: 12,
        microsocticket: 15,
        assets: 12,
        notifications: 12,
        searchBar: 12
    },
    scrollIterationLoading : 12,
    ignoreList : stopWords,
    graphColors : [
        '#1961ac',
        '#4bb4e6',
        '#bcdff7',
        '#007836',
        '#50be87',
        '#bbdecf',
        '#f193bd',
        '#ffb4e6',
        '#fce5f1',
        '#482683',
        '#a885d8',
        '#d6c1df',
        '#f9b000',
        '#fff8bd',
        '#ffd200',
        '#595959',
        '#dadada',
        '#8f8f8f'
    ],
    graphSixColors : [
        '#1961ac',
        '#4bb4e6',
        '#bcdff7',
        '#007836',
        '#50be87',
        '#bbdecf'
    ],
    graphContrastingColors : [
        '#4bb4e6',
        '#50be87',
        '#ffb4e6',
        '#a885d8',
        '#ffd200',
        '#8f8f8f'
    ],
    graphCustomColors1: [
        '#FF7900',
        '#50BE87'
    ],
    local : {
        userPreferences: 'userPreferences',
        notifications: 'notifications',
        notificationPreferences: 'notificationPreferences',
        selectedWidgets: 'selectedWidgets',
        libraryWidgets : 'libraryWidgets',
        userPreferenceLanguage: 'language',
        userPreferenceRegion: 'region',
        userPreferenceTheme: 'theme',
        flags: {
            signals : 'signal',
            vulns: 'vulns',
            support: 'support',
            assets: 'asset',
            assetCases: 'assetCases',
            worldwatch: 'worldwatch',
            tag: 'tag',
            cpe: 'cpe'
        },
        views: {
            vuln: 'vulnViewType',
            signals: 'signalViewType',
            support: 'supportViewType',
            asset: 'assetViewType',
            columnsPrefs : 'supportViewColumns'
        },
        watch : {
            vuln: 'products'
        }
    },
    fields : {
        signals : {
            category : 'mainCategory',
            urgency: 'signalUrgency',
            publishDate: 'publishDate',
            lastUpdateDate: 'date'
        },
        vulns : {
            severity : 'impact.baseMetricV3.cvssV3.baseSeverity'
        }
    },
    activity : {
        types : {
            signal: {
                type: 'Signals',
                title: 'Signals from World Watch',
                lastLoginUrl: '/updates/worldwatch/dashboard/lastlogin',
                viewUrl: '/updates/worldwatch/viewSignal/',
                baseReference: 'SIG-',
                regex: 'SIG-[0-9]'
            },
            vuln: {
                type: 'Vulnerabilities',
                title: 'Vulnerabilities from NVD database',
                lastLoginUrl: '/updates/vulnerabilities/dashboard/lastlogin',
                lastDays: '/updates/vulnerabilities/dashboard/7days',
                viewUrl: '/updates/vulnerabilities/viewVuln/'
            },
            support : {
                updated : {
                    type: 'Case',
                    title: 'Cases updated',
                    lastLoginUrl: '/support/dashboard/lastloginupdated',
                    viewUrl: '/support/view/'
                },
                created : {
                    type: 'Case',
                    title: 'New cases created',
                    lastLoginUrl: '/support/dashboard/lastlogincreated',
                    viewUrl: '/support/view/'
                }
            }
        }
    },
    itsm: {
        globalStates: {
            open : ['Pending (Customer)', 'Pending (Orange Cyberdefense)', 'Pending (Vendor)']
        },
        states : {
            pendingCustomer: 'Pending (Customer)',
            pendingOcd: 'Pending (Orange Cyberdefense)',
            pendingVendor: 'Pending (Vendor)',
        },
        approvalStates : {
            approvalNotRequested: 'not requested',
            authorizedApprovalStatus: ['requested'],    
        },
        priorities : {
            high: 'High',
            critical: 'Critical',
        }
    },
    mileStoneReferences: {
        firstresponse: '557b0000000GmknAAC',
        resolution: '5570X0000004FbrQAE'
    },
    incidentStatus: {
        truepositive: ['Closed', 'Resolved', 'True Positive'],
        falsepositive: ['Resolved – False positive', 'False Positive']
    },
    statusGroups: {
        'Pending (Orange Cyberdefense)' : ['New', 'Open', 'Pending (Contact)', 'Pending (External)', 'Work in progress'],
        'Pending (Customer)': ['Pending (Customer)', 'Pending Auto-close'],
        'Pending (Due Date)': ['Pending (Due Date)', 'Scheduled', 'Pending (Monitoring)']
    },
    creatorPortalReferences: ['APIPortal','APIPortalUK', 'GlobalPortal', 'Global customer portal'],
    mailToPortalSupport: 'portalsupport@orangecyberdefense.com',
    mailToInfoSec: 'infosec@orangecyberdefense.com',
    mailToPrivacy: 'privacy@orangecyberdefense.com',
    networks : {
      twitterLabel: 'Twitter',
      linkedinLabel: 'linkedin',
      twitter: 'https://twitter.com/orangecyberdef',
      linkedin: 'https://www.linkedin.com/company/orange-cyberdefense/'
    },
    azureExample : 'https://company.azuread.com/app/examplessourl/sso/saml',
    portalSatisfactionSurveyUrl: 'https://msurvey.orange.com/PortalSatisfactionSurvey',
    languages: {
      enGB: 'en-GB'
    },
    cookies : {
      subgroup: 'Okta.com'
    },
    nucleusStatus: 'Active',
    nucleusServiceName: 'Managed Vulnerability Intelligence [Identify]',
    maliciousFileDetectionKey: 'Malicous Content Detected',
    profilePictureMaxMoSize: 500,
    fileMaxMbSize: 500,
    fileUploadRestrictions: {
        maxSize: 1024 * 1024 * 100, // 100meg
        illegalCharacters: '~#@*+%{}<>\\[]"^'
    },
    rssFeed: {
        defaultSortType: 'date',
        defaultSortOrder: 'asc'
    },
    servicesContactFormUrl: "https://www4.orangecyberdefense.com/l/865272/2023-09-20/bshnmn",
    regex: {
        /*
         * Following chars ('+\-=@') can be used at the beginning of a string for csv injection.
         * See https://owasp.org/www-community/attacks/CSV_Injection
         * this regex avoid first character to be one these
         */
        avoidCsvInjection: /^([^+\-=@])(.|\s)*/,
        // allow only alphanumeric characters (any language, with accents, umlauts, etc.), '-', '_', and '.'
        onlyAlphanumericValues: /^[\p{L}\p{M}0-9\-_.,\s]*$/u
    },
    // Timezone we have to use to send time to snow as Europe/London because SNow API account has this time zone. 
    snowServiceTimeZone: 'Europe/London'
};

export const REGIONS = {
  belgium : { name : 'Belgium', value: 'be'},
  china : { name: 'China', value: 'cn'},
  denmark : { name: 'Denmark', value: 'dk'},
  france : { name: 'France', value: 'fr'},
  germany : { name: 'Germany', value: 'de'},
  global : { name: 'Global', value: 'global'},
  maghrebWestAfrica : { name: 'Maghreb & West Africa', value: 'ar-ma'},
  netherlands : { name: 'Netherlands', value: 'nl'},
  norway : { name: 'Norway', value: 'no'},
  southAfrica : { name: 'South Africa', value: 'za'},
  sweden : { name: 'Sweden', value: 'se'},
  uniteKingdom : { name: 'United Kingdom', value: 'uk'}
};

export const LANGUAGES = {
  english : { name : 'EN', label: 'English version', lang: 'en'},
  france : { name: 'FR', label: 'Version française', lang: 'fr'},
  netherlands : { name: 'NL', label: 'Nederlandse versie', lang: 'nl'},
  sweden : { name: 'SE', label: 'Svensk version', lang: 'se'},
  germany : { name: 'DE', label: 'Deutsche version', lang: 'de'}
};

export const TYPES = {
    assetManagement: 'Asset Management',
    dashboard : 'Dashboard',
    services : 'Services',
    resources : 'Resources',
    user: 'User',
    support: 'Support',
    reports: 'Reports',
    activity: 'Activity',
    training: 'Training'
};

export const PAGES = {
    assetDashboard: 'Asset Dashboard',
    assetView: 'Asset View',
    certToolbox : 'Malicious File Detection',
    mfdUpload : 'MFD Upload',
    mfdHistory : 'MFD History',
    mfdResult : 'MFD Result',
    dynamicMfdUpload: 'Dynamic MFD Upload',
    integrations : 'Integrations',
    ssoAzureActiveDirectory : 'SSO Azure Active Directory',
    orangeCyberdefenseServices : 'Orange Cyberdefense Services',
    vulnerabilities : 'Vulnerabilities',
    viewVulnerability : 'View Vulnerability',
    worldwatch : 'Worldwatch',
    viewSignal : 'View Signal',
    dashboard : 'Dashboard',
    customizeDashboard : 'Customize Dashboard',
    analytics : 'Analytics',
    contacts : 'Contacts',
    addContact : 'Add Contact',
    supportOverview : 'Support Overview',
    supportResolutionReport : 'Support Resolution Report',
    logCase : 'Log Case',
    supportView : 'Support View',
    changeTenant : 'Change Tenant',
    securityIncidentsBenchmarking: 'Security incidents benchmarking',
    securityIncidentsReport: 'Security incidents report',
    securityIncidentTimeline: 'Security Incident Timeline',
    reportsAndDocuments: 'Reports and documents',
    reportsSupportResolution: 'Support resolution report',
    activityDashboard: 'Activity Dashboard',
    trainingDashboard: 'Training Dashboard'
};

export const ACTIONS = {
    visit : 'Visit',
    comment : 'Case comments',
    attachmentUpload: 'Attachments uploaded ',
    attachmentDownload: 'Attachments downloaded',
    logCase: 'Logged cases',
    generateReport: 'Reports generated',
    scheduleReport: 'Reports scheduled ',
    downloadReport: 'Reports downloaded',
    fileScan: 'Files scanned',
    WidgetAdd: 'Widget Added',
    WidgetRemoval: 'Widget Removed',
};

export const FEEDBACKFORM = {
    feedback: {
        pages: [
            {
                labelKey: 'menu.dashboard',
                value: 'Dashboard',
                subpages : []
            }, {
                labelKey: 'menu.support.title',
                value : 'Support',
                subpages : [
                    {
                        labelKey: 'menu.support.overview',
                        value: 'Support overview'
                    },
                    {
                        labelKey: 'menu.support.log',
                        value: 'Log case'}
                    ]
                }, {
                    value : 'Activity',
                    labelKey: 'menu.activity.title',
                    subpages : [
                        {
                            labelKey: 'menu.activity.overview',
                            value: 'Activity dashboard'
                        },
                        {
                            labelKey: 'Security Incident Timeline',
                            value: 'menu.dashboard.securityincidentTimeline'}
                        ]
                    }, {
                        value : 'Asset Management',
                        labelKey: 'menu.assets.title',
                        subpages : []
                    }, {
                        value : 'Reports',
                        labelKey: 'menu.reports.title',
                        subpages : [
                            {
                                labelKey: 'menu.reports.standard',
                                value: 'Reports & Document'
                            }, {
                                labelKey: 'menu.reports.securityCase',
                                value: 'Security Incident Report'
                            }, {
                                labelKey: 'menu.reports.resolution',
                                value: 'Support Resolution Report'
                            }, {
                                labelKey: 'menu.reports.benchmarking',
                                value: 'Support Security Benchmarking'
                            }, {
                                labelKey: 'menu.reports.reportScheduling',
                                value: 'Report Scheduling'
                            }
                        ]
                    }, {
                        value : 'Resources',
                        labelKey: 'menu.updates.title',
                        subpages : [
                            {
                                labelKey: 'menu.updates.worldwatch',
                                value: 'Worlwatch'
                            }, {
                                labelKey: 'menu.updates.vulnerabilities',
                                value: 'Vulnerabilities'
                            }, {
                                labelKey: 'menu.updates.training',
                                value: 'Training'
                            }, {
                                labelKey: 'menu.updates.rss.blog',
                                value: 'Blogs'
                            }, {
                                labelKey: 'menu.updates.rss.events',
                                value: 'Events'
                            }, {
                                labelKey: 'menu.updates.rss.news',
                                value: 'News'
                            }, {
                                labelKey: 'menu.updates.rss.whitepapers',
                                value: 'Whitepapers'
                            }, {
                                labelKey: 'menu.updates.integrations.title',
                                value: 'Integration'
                            }
                        ]
                    }, {
                        value : 'Services',
                        labelKey: 'pages.dashboard.services',
                        subpages : [
                            {
                                labelKey: 'pages.dashboard.threatDefenseCenter',
                                value: 'Threat Defense Center'
                            }, {
                                labelKey: 'menu.updates.ourServices',
                                value: 'Orange Cyberdefense Services'
                            } , {
                                labelKey: 'menu.updates.certToolbox',
                                value: 'Malicious File Detection'
                            }
                        ]
                    }, {
                        value : 'Admin',
                        labelKey: 'pages.dashboard.admin',
                        subpages : [
                            {
                                labelKey: 'menu.admin.users',
                                value: 'Users'
                            }
                        ]
                    }, {
                        value : 'Other',
                        labelKey: 'modals.feedbackMenu.feedback.other',
                        subpages : []
                    }],
                        category: [
                            {
                                labelKey: 'modals.feedbackMenu.feedback.improvement',
                                value: 'Improvement'
                            }, {
                                labelKey: 'modals.feedbackMenu.feedback.bug',
                                value: 'Bug'
                            }, {
                                labelKey: 'modals.feedbackMenu.feedback.feedback',
                                value: 'Feedback'
                            }
                        ]
                    }
};

export const oktaConstants = {
     // create localStorage exclude array (transform enum into string)
  localStorageExcludedKeys: [
    LocalStorageItem.gpAlerts.toString(),
    LocalStorageItem.profilePicture.toString(),
    LocalStorageItem.oktaSelectedTenant.toString()
  ]
}

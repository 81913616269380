<div class="customize">
  <div>
    <table class="table table-hover">
      <caption class="visually-hidden"></caption>
      <thead>
        <tr>
          <th scope="col">Widget</th>
          <th scope="col">Display</th>
        </tr>
      </thead>
      <tbody>
        @for (widget of availableWidgets; track widget.name) {
        <tr (click)="updateWidget(widget)">
          <td><label class="thiner" [for]="widget.name + '_switch'">{{ widget.displayName }}</label></td>
          <td>
            <div class="form-switch">
              <input
                class="form-check-input"
                type="checkbox"
                role="switch"
                [id]="widget.name + '_switch'"
                [(ngModel)]="widget.selected"
                [attr.aria-checked]="widget.selected"/>
            </div>
          </td>
        </tr>
        }
      </tbody>
    </table>
  </div>
  <div>
    <div class="row"
      dndDropzone
      (dndDrop)="onDrop($event)"
      [dndHorizontal]="false">
      <div dndPlaceholderRef class="placeholder"></div>
      @for (widget of selectedWidgets; track widget._id) {
      <div
        class="card mb-1"
        [ngClass]="'col-'+widget.width"
        [dndDraggable]="widget"
        [dndEffectAllowed]="'linkMove'">
        <button type="button" class="btn btn-secondary btn-icon btn-sm close-button" (click)="deleteWidget(widget.name)">X</button>
        @switch (widget.name) {
          @case ('latestActivity') { <app-fake-latest-activity></app-fake-latest-activity> }
          @case ('recentSupportActivity') { <app-fake-recent-support-activity></app-fake-recent-support-activity> }
          @case ('widget1') { <app-fake-widget1></app-fake-widget1> }
          @case ('widget2') { <app-fake-widget2></app-fake-widget2> }
          @case ('worldwatch') { <app-fake-worldwatch-carousel></app-fake-worldwatch-carousel> }
          @case ('pendingCasesOcd') { <app-fake-pending-cases-ocd></app-fake-pending-cases-ocd> }
          @case ('openCases') { <app-fake-open-cases></app-fake-open-cases> }
          @case ('truePositivesVSFalsePositives') { <app-fake-true-positives-vs-false-positives></app-fake-true-positives-vs-false-positives> }
          @case ('mtta') { <app-fake-mtta></app-fake-mtta> }
          @case ('mttRespond') { <app-fake-mtt-respond></app-fake-mtt-respond> }
          @case ('mttResolve') { <app-fake-mtt-resolve></app-fake-mtt-resolve> }
        }
      </div>
      }
    </div>
  </div>

</div>


import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { UserAuthService } from 'src/app/shared/services/user-auth.service';
import {UserPreferenceService} from './shared/services/userpreferences.service';

@Component({template: ``})
export class CallbackComponent implements OnInit {

  constructor(
    private userAuthService: UserAuthService,
    private userPref: UserPreferenceService,
    private translate: TranslateService) {
  }

  ngOnInit(): void {
    // Handles the response from Okta and parses tokens
    this.userAuthService.handleAuthentication()
      .then((res) => {
        if (res) {
          // load and store user preferences local
          this.userPref.loadPreferences()
            .then(() => {
              if (this.userPref.userPreferences.language) {
                // set the default language to en
                this.translate.setDefaultLang(this.userPref.userPreferences.language.toLowerCase());
                this.translate.use(this.userPref.userPreferences.language.toLowerCase());
              } else {
                // set the default language to en
                this.translate.setDefaultLang('en');
                this.translate.use('en');
              }
            });
        }
      });
  }
}

import { Component, OnInit } from '@angular/core';
import { UserAuthService } from 'src/app/shared/services/user-auth.service';
import { DefaultLayoutComponent } from '../default-layout/default-layout.component';
import { ContextService } from 'src/app/shared/services/context-service';

@Component({
  selector: 'app-nomenulayout',
  templateUrl: './nomenulayout.component.html',
  styleUrls: [
    './nomenulayout.component.scss',
    './../default-layout/default-layout.component.scss'
  ]
})
export class NoMenuLayoutComponent extends DefaultLayoutComponent implements OnInit {
  isCollapsedNav = true;
  userAdmin = false;
  changeTenants = false;
  tenantNameAdmin: string;

  constructor(
    public userAuthService: UserAuthService,
    public contextService: ContextService
  ) {
    super();
  }

  ngOnInit(): void {

      if (
        this.userAuthService.userDetails?.groups.filter((g) => g.startsWith('ADMINS_')).length > 0
      ) {
        this.tenantNameAdmin = this.userAuthService.userDetails.groups
          .filter((g) => g.startsWith('ADMINS_'))[0]
          .split('_')[1];

        // check to see if multiple tenants are available, and if one has been selected
        const availableTenants = this.userAuthService.availableTenants;

        // if the user is an admin, and can see multiple tenants then enable the change tenants feature
        if (availableTenants?.length > 1) {
          // allow the changing of tenants
          this.changeTenants = true;

          // if the user is an admin of the selected tenant then allow the admin menu item
          if (this.userAuthService.selectedTenant === this.tenantNameAdmin
          ) {
            this.userAdmin = true;
          }
        } else {
          // if the user is an admin but is only a user for one tenant then allow it
          this.userAdmin = true;
        }
      } else {
        // if the user is not an admin
        this.userAdmin = false;
      }

  }
}

import { Component } from '@angular/core';

@Component({
  selector: 'app-activitysearch',
  templateUrl: './activitysearch.component.html',
  styleUrls: ['./activitysearch.component.scss']
})
export class ActivitySearchComponent {

}

<div #grid class="grid">
  @if (widgetSettings) {
  <div #worldwatch class="worldwatch-carousel">
    <div *ngIf="widgetSettings.worldwatch.display" class="widget-section">
      <app-worldwatch-carousel [theme]="widgetSettings.worldwatch.theme"></app-worldwatch-carousel>
    </div>
  </div>
  <div #recentSupportActivity class="recent-support-activity">
    <div *ngIf="widgetSettings.recentSupportActivity.display" class="widget-section">
      <app-recent-support-activity [theme]="widgetSettings.recentSupportActivity.theme"></app-recent-support-activity>
    </div>
  </div>
  <div #latestActivity class="latest-activity">
    <div *ngIf="widgetSettings.latestActivity.display" class="widget-section">
      <app-latest-activity [theme]="widgetSettings.latestActivity.theme"></app-latest-activity>
    </div>
  </div>
  <div #pendingCasesOcd class="pending-cases-ocd">
    <div *ngIf="widgetSettings.pendingCasesOcd.display" class="widget-section">
      <app-pending-cases-ocd [theme]="widgetSettings.pendingCasesOcd.theme"></app-pending-cases-ocd>
    </div>
  </div>
  <div #openCases class="open-cases">
    <div *ngIf="widgetSettings.openCases.display" class="widget-section">
      <app-open-cases [theme]="widgetSettings.openCases.theme"></app-open-cases>
    </div>
  </div>
  <div #trueFalsePositives class="trueFalsePositives">
    <div *ngIf="widgetSettings.truePositivesVSFalsePositives.display" class="widget-section">
      <app-true-positives-vs-false-positives
        [smallScreenMode]="smallScreenMode"
        [theme]="widgetSettings.truePositivesVSFalsePositives.theme">
      </app-true-positives-vs-false-positives>
    </div>
  </div>
  <!-- CSOC reports -->
  <div #mtta class="mtta">
    <div *ngIf="widgetSettings.mtta.display" class="widget-section">
      @if(CSOCAccessChecked) {
      <app-mtt-widget [smallScreenMode]="smallScreenMode"
      [mttType]="mttTypes.MTTA"
      [widgetTheme]="widgetSettings.mtta.theme"
      [CSOCAccess]="hasCSOCReportsAccess"></app-mtt-widget>
      }
    </div>
  </div>
  <div #mttRespond class="mttRespond">
    <div *ngIf="widgetSettings.mttRespond.display" class="widget-section">
      @if(CSOCAccessChecked) {
      <app-mtt-widget [smallScreenMode]="smallScreenMode"
        [mttType]="mttTypes.MTTRESPOND"
        [widgetTheme]="widgetSettings.mttRespond.theme"
        [CSOCAccess]="hasCSOCReportsAccess"></app-mtt-widget>
      }
    </div>
  </div>
  <div #mttResolve class="mttResolve">
    <div *ngIf="widgetSettings.mttResolve.display" class="widget-section">
      @if(CSOCAccessChecked) {
      <app-mtt-widget [smallScreenMode]="smallScreenMode"
        [mttType]="mttTypes.MTTRESOLVE"
        [widgetTheme]="widgetSettings.mttResolve.theme"
        [CSOCAccess]="hasCSOCReportsAccess"></app-mtt-widget>
      }
    </div>
  </div>
  }
</div>

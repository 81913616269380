import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Asset } from '../../model/itsm';

@Component({
  selector: 'app-asset-grid',
  templateUrl: './asset-grid.component.html',
  styleUrls: ['./asset-grid.component.scss']
})
export class AssetGridComponent {

  @Input()
  assets: Asset[];

  @Input()
  flagRefs: any[];

  @Input()
  flaggingRef: string;

  @Output()
  flagEmitter = new EventEmitter<any>();

  @Input()
  loaded: boolean;

  flagAsset(event, assetRef: string): void {
    event.stopPropagation();
    this.flagEmitter.emit(assetRef);
  }

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ACTIONS, LANGUAGES, REGIONS } from '../constants';
import { UserPreferenceService } from './userpreferences.service';
import { SubPages } from '../model/shared-items';

export type CountResponseItem = {id: string; count: number};
export type Action = { action: string; count: number; page?: string; type?: string; widget?: string};

export type CountResponse = {
 types?: CountResponseItem[];
 days?: CountResponseItem[];
 pages?: {page: string; type: string; count: number}[];
 regions?: CountResponseItem[];
 langages?: CountResponseItem[];
 users?: CountResponseItem[];
 actions?: Action[];
 actionsPerDay?: CountResponseItem[];
 widgets?: Action[];
 widgetsUsage?: CountResponseItem[];
};

@Injectable({
  providedIn: 'root'
})
export class UserActivityService {

  private userPrefLogActivity = environment.endpoints.userpreferences + 'logActivity';
  private userPrefActivitiesCount = environment.endpoints.userpreferences + 'activitiesCount';

  constructor(
    private http: HttpClient,
    private userPreferenceService: UserPreferenceService
  ) { }

  /** log an activity into user preferences */
  public logActivity(type: string, page: string, action: string, widgetName: string = null, trainingCourse: string = null) {
    const up = this.userPreferenceService.userPreferences;

    const log = {
        langage: up?.language ?? LANGUAGES.english.lang,
        region: up?.region ?? REGIONS.global.value,
        type,
        page,
        action,
        widget: [ACTIONS.WidgetAdd, ACTIONS.WidgetRemoval].includes(action) ? widgetName : null
    };

    return this.http
        .post(this.userPrefLogActivity, log)
        .toPromise()
        .then(response => response)
        .catch(this.handleError);
  }

  /** count all types from user activities */
  getActivitiesTypesCount(startDate: string, endDate: string, range: string): Promise<{ id: string; count: number}[]> {

    return this.http
        .post(this.userPrefActivitiesCount, {startDate, endDate, range, counts: ['types']})
        .toPromise()
        .then((response: any) => response.types as { id: string; count: number}[])
        .catch(this.handleError);
  }

  /** count activities by day */
  async getActivitiesDaysCount(startDate: string, endDate: string, range: string): Promise<{ id: string; count: number}[]> {
    return this.http
        .post(this.userPrefActivitiesCount, {startDate, endDate, range, counts: ['days']})
        .toPromise()
        .then((response: any) => response.days as { id: string; count: number}[])
        .catch(this.handleError);
  }

  /** get all counts for all categories */
  async getActivitiesSummaries(
    tab: SubPages.actionsAnalytics | SubPages.activityAnalytics,
    startDate: string,
    endDate: string,
    range: string
  ): Promise<CountResponse> {
    const body = tab === SubPages.activityAnalytics
    ? {startDate, endDate, range, counts: ['types', 'days', 'pages', 'regions', 'langages', 'users', 'widgetsUsage']}
    : {startDate, endDate, range, counts: ['actions', 'actionsPerDay', 'widgets']}
    return this.http
      .post(this.userPrefActivitiesCount, body)
      .toPromise()
      .then((response: any) => response as CountResponse)
      .catch(this.handleError);
  }

  /** count actions by day */
  async getActionsDaysCount(startDate: string, endDate: string, range: string): Promise<{ id: string; count: number}[]> {
    return this.http
        .post(this.userPrefActivitiesCount, {startDate, endDate, range, counts: ['actionsPerDay']})
        .toPromise()
        .then((response: any) => response.actionsPerDay as { id: string; count: number}[])
        .catch(this.handleError);
  }

  /** count activities by action */
  async getActivitiesActions(startDate: string, endDate: string, range: string): Promise<{ id: string; count: number}[]> {
    return this.http
        .post(this.userPrefActivitiesCount, {startDate, endDate, range, counts: ['actions']})
        .toPromise()
        .then((response: any) => response.actions as { id: string; count: number}[])
        .catch(this.handleError);
  }

  /** count widgets actions */
  async getWidgetsCount(startDate: string, endDate: string, range: string): Promise<CountResponse> {
    return this.http
        .post(this.userPrefActivitiesCount, {startDate, endDate, range, counts: ['widgets']})
        .toPromise()
        .then((response: any) => response.widgets as CountResponse)
        .catch(this.handleError);
  }

  /**
   * Generic error handler for this service
   *
   * @param error
   */
   private handleError(error: any): Promise<any> {
    return Promise.reject(error.message || error);
  }
}

import jsPDF from 'jspdf';

/** @returns The default options */
export const getDefaultHighchartOptions = (useDarkTheme = false): Highcharts.Options => {
  const textColor = useDarkTheme ? 'white' : 'black';
  return {
    colors: ['#FF7900', '#D6D6D6', '#FFD200', '#A885D8', '#FFB4E6', '#50BE87', '#4BB4E6', '#b5996d', '#8F8F8F'],
    chart: {
      backgroundColor: '#FFFFFF00',
      borderColor: null,
    },
    credits: { enabled: false },
    exporting: { enabled: false },
    title: {
      style: {
        color: textColor,
        font: 'bold 16px "Trebuchet MS", Verdana, sans-serif',
      },
    },
    subtitle: {
      style: {
        color: textColor,
        font: 'bold 12px "Trebuchet MS", Verdana, sans-serif',
        textOutline: null,
      },
    },
    legend: {
      itemStyle: {
        font: '9pt Trebuchet MS, Verdana, sans-serif',
        color: textColor,
        textOutline: null,
      },
      itemHoverStyle: {
        color: textColor,
        textOutline: textColor,
      },
    },
    yAxis: {
      labels: {
        style: { color: textColor },
      },
    },
    xAxis: {
      labels: {
        style: { color: textColor },
      },
    },
    plotOptions: {
      series: {
        shadow: false,
        borderColor: null,
        dataLabels: {
          style: { color: textColor, textOutline: null },
        },
      },
      column: {
        borderRadius: 0,
        shadow: false,
      },
      pie: {
        borderRadius: 0,
        dataLabels: {
          connectorShape: 'fixedOffset',
        },
      },
    },
  };
};

/* Basic functions to manipulate anonymous mode, although a store would be better on the long run */
export const isAnonymousEnabled = () => sessionStorage.getItem('cybersocxdr_anonymous') === 'true';

export const setAnonymousEnabled = (isEnabled: boolean) => {
  sessionStorage.setItem('cybersocxdr_anonymous', isEnabled.toString());
  // Technical debt on wheels
  window.location.reload();
};

export type GraphLayout = {
  pages: {
    columns: number[];
  }[];
  marginLeft: number;
  marginTop: number;
  marginRight: number;
  marginBottom: number;
};

const svgToImage = (svg: Element | string): Promise<string> =>
  new Promise<string>((resolve, reject) => {
    let width = 0;
    let height = 0;
    let serializedSvgString: string;

    if (typeof svg == 'string') {
      serializedSvgString = svg;
    } else {
      const serializer: XMLSerializer = new XMLSerializer();
      serializedSvgString = serializer.serializeToString(svg);
      width = parseInt(svg.getAttribute('width'), 10);
      height = parseInt(svg.getAttribute('height'), 10);
    }

    const svgDataBase64: string = btoa(unescape(encodeURIComponent(serializedSvgString)));
    const svgDataUrl = `data:image/svg+xml;charset=utf-8;base64,${svgDataBase64}`;

    const image: HTMLImageElement = width === 0 || height === 0 ? new Image() : new Image(width, height);
    image.src = svgDataUrl;

    image.addEventListener('load', () => {
      if (width === 0 || height === 0) {
        width = image.width;
        height = image.height;
      }

      const canvas: HTMLCanvasElement = document.createElement('canvas');
      canvas.setAttribute('width', width.toString());
      canvas.setAttribute('height', height.toString());

      const context: CanvasRenderingContext2D = canvas.getContext('2d');
      context.drawImage(image, 0, 0, width, height);

      const dataUrl: string = canvas.toDataURL('image/png');
      resolve(dataUrl);
    });
  });

/**
 * Generate a pdf file ready for download by taking every chart and rendering them offscreen
 *
 * @param createdFrom The starting date
 * @param createdTo The ending date
 */
export const generateHightchartPdf = async (
  createdFrom: string,
  createdTo: string,
  enabledDarkTheme: boolean,
  graphLayout: GraphLayout
) => {
  const logo: string = await (
    await fetch(`${window.location.origin}/assets/images/logos/OCD_${enabledDarkTheme ? 'White' : 'Dark'}.svg`)
  ).text();

  const svgElements: HTMLCollection = document.getElementsByClassName('highcharts-root');
  const tooltips: HTMLCollection = document.getElementsByClassName('highcharts-tooltip');
  const tooltipArray = Array.from(tooltips);
  tooltipArray.forEach((tooltip) => {
    tooltip.removeAttribute('transform');
    tooltip.setAttribute('opacity', '0');
  });

  const doc: jsPDF = new jsPDF();
  doc.setFontSize(15);

  const addPageHeader = async (document: jsPDF) => {
    document.addPage();
    if (enabledDarkTheme) {
      doc.setFillColor('#000000');
      doc.rect(0, 0, 10000, 10000, 'F');
      document.setTextColor('#FFFFFF');
    }
    document.addImage(await svgToImage(logo), 10, 10, 114.8, 30);

    document.text(`Period: ${createdFrom} to ${createdTo}`, 18, 40);
  };

  let graphIndex = 0;
  for (const layout of graphLayout.pages) {
    for (let rowIndex = 0; rowIndex < layout.columns.length; ++rowIndex) {
      for (
        let columnIndex = 0;
        columnIndex < layout.columns[rowIndex] && graphIndex < svgElements.length;
        ++columnIndex
      ) {
        if (rowIndex === 0 && columnIndex === 0) {
          await addPageHeader(doc);
          console.log('add page header');
        }

        const svg: SVGGraphicsElement = svgElements[graphIndex] as SVGGraphicsElement;
        const image = await svgToImage(svg);
        const originalWidth = svg.getBBox().width;
        const originalHeight = svg.getBBox().height;

        const allowedWidth =
          (doc.internal.pageSize.width - (graphLayout.marginLeft + graphLayout.marginRight)) / layout.columns[rowIndex];

        const allowedHeight =
          (doc.internal.pageSize.height - (graphLayout.marginTop + graphLayout.marginBottom)) / layout.columns.length;

        const scaler = Math.max(originalWidth / allowedWidth, originalHeight / allowedHeight);
        const scaledWidth = originalWidth / scaler;
        const scaledHeight = originalHeight / scaler;

        let x = allowedWidth * columnIndex;
        let y = allowedHeight * rowIndex;

        // The scaled size cannot be bigger than the allowed size
        x += (allowedWidth - scaledWidth) / 2;
        y += (allowedHeight - scaledHeight) / 2;

        x += graphLayout.marginLeft;
        y += graphLayout.marginTop;

        doc.addImage(image, x, y, scaledWidth, scaledHeight);
        graphIndex++;
      }
    }
  }

  const dateNow: Date = new Date();
  const dateNowString = `${dateNow.getFullYear()}-${dateNow.getMonth() + 1}-${dateNow.getDate()}`;

  doc.deletePage(1);
  doc.save(`report_${dateNowString}_ocd.pdf`);
};

/**
 * Wait for a node to be added, triggers the callback with the node
 * Translated to TS from https://stackoverflow.com/questions/38881301/observe-mutations-on-a-target-node-that-doesnt-exist-yet
 */
export function waitForAddedNode(params: {
  observedId: string;
  parent: Element;
  recursive: boolean;
  done: (element: HTMLElement) => void;
}) {
  // Early return if the node already exists by chance
  const element = document.getElementById(params.observedId);
  if (element) {
    params.done(element);
    return;
  }

  // Otherwise wait
  new MutationObserver(function (mutations) {
    const element = document.getElementById(params.observedId);
    if (element) {
      this.disconnect();
      params.done(element);
    }
  }).observe(params.parent || document, {
    subtree: params.recursive || !params.parent,
    childList: true,
  });
}


/** The return type from Promise.all */
type NativePromiseAllReturnType<T> = { -readonly [P in keyof T]: Awaited<T[P]> };
/**
 * Similar to Promise.allSettled, but with a custom error handler
 * When one of the promises is rejected, the error handler is called with the reason of the rejection
 *
 * @param promises The promises to execute and handle eventual rejections from
 * @param errorHandler The handler for rejected promises. Defaults to console.error
 * @returns A promise which always resolves to an array of the results of the promises. If a sub-promise was rejected, the result in the array is undefined
 */
export async function handlePromises<T extends unknown[] | []>(
  promises: T,
  errorHandler: (reason: PromiseRejectedResult['reason']) => void = handleRejectedPromise
): Promise<NativePromiseAllReturnType<T>> {
  const results = await Promise.allSettled(promises);
  // Forced typecast, as the value from "promises" are not known 
  const returnValue = [] as NativePromiseAllReturnType<T> ;
  for (let i = 0; i < results.length; ++i) {
    const result = results[i];
    returnValue[i] = result.status === 'fulfilled' ? result.value : undefined;

    // Handle rejected promises
    if (result.status === 'rejected') {
      errorHandler(result.reason);
    }
  }
  
  return returnValue;
}

/** Default rejected promises handler */
function handleRejectedPromise(reason: PromiseRejectedResult['reason']) {
  console.error(reason);
}

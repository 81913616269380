<div>
  <div class="card">
    <div class="card-body">

      <div class="selection mx-3 mb-3">

        <!-- filter activity on range -->
        @if (rangeRadioButtons.length > 0) {
          <div class="scope">
            <label class="mb-2" for="selectRadioDate">{{'pages.dashboard.analytics.filterActivityOn' | translate }}</label>
            <select class="form-select" id="selectRadioRange" (change)='onRangeRadioChange($event.target.value)'>
              @for (radio of rangeRadioButtons; track radio.key) {
              <option
                [id]="radio.key + '_scope'"
                [value]="radio.key">
                {{radio.label | translate }}
              </option>
              }
            </select>
          </div>
          <!-- Set an empty grid-area between the two select -->
          <div class="empty-area"></div>
          }

          

          <!-- filter activity on date -->
          <div class="date-range">
            <label class="mb-2" for="selectRadioDate">{{'pages.dashboard.analytics.filterOn' | translate }}</label>
            <select class="form-select" id="selectRadioDate" (change)='onDateRadioChange($event.target.value)'>
              @for (radio of dateRadioButtons; track radio.key) {
              <option
                [id]="radio.key + '_date'"
                [value]="radio.key"
                [selected]="defaultParameters.selectedRadioDate === radio.key">
                {{radio.label | translate }}
              </option>
              }
            </select>
          </div>

      </div>

      <!-- filter on custom time range -->
      @if (selectedRadioDate === 'custom') {
      <div class="custom-time-range mx-3">

        <div class="from-label">
          <label class="mb-2" for="visitedFrom"> {{'pages.dashboard.analytics.visitsOccuredFrom' | translate }} </label>
        </div>

        <div class="custom-date-inputs">
          <div class="from-date">
            <input type="date" class="form-control" name="createdFrom" id="visitedFrom"
              [(ngModel)]="createdFrom" />
          </div>
          <div class="to-label justify-content-center my-2">
            <label for="visitedTo">{{'pages.dashboard.analytics.to' | translate }}</label>
          </div>
          <div class="to-date">
            <input type="date" class="form-control" class="form-control" name="createdTo" id="visitedTo"
              [(ngModel)]="createdTo" />
          </div>
        </div>
        
        <div class="apply-btn mt-2 mb-2">
          <button class="btn btn-primary filter" (click)="onFilter()">{{ 'pages.generic.apply' | translate
            }}</button>
        </div>

      </div>
      }

    </div>
  </div>
</div>

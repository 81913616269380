import { inject } from '@angular/core';
import { Router, CanActivateFn } from '@angular/router';
import { UserAuthService } from './shared/services/user-auth.service';
import { HttpAccountmapperService } from './shared/httpServices/http-accountmapper.service';


/**
 * Okta Auth Guard
 */
export const oktaAuthGuard: CanActivateFn = async (route, state) => {

  const userAuthService = inject(UserAuthService);

  const authenticated = await userAuthService.isAuthenticated();
  if (authenticated) { 
    return true; 
  } else {
    // Redirect to login flow.
    userAuthService.login(state.url);
    return false;
  }
}

/**
 * Analytics Guard
 */
export const analyticsGuard: CanActivateFn = () => {

  const userAuthService = inject(UserAuthService);
  const router = inject(Router);

  return userAuthService.accesses.analytics ? true : router.parseUrl('/not-authorized');
};

/**
 * SLA Auth Guard
 */
export const slaAuthGuard: CanActivateFn = async () => {

  const httpAccountmapperService = inject(HttpAccountmapperService);
  const router = inject(Router);

  let accesses = await httpAccountmapperService.getTenantAccesses()
  return accesses.sla ? true : router.parseUrl('/not-authorized');
}

/**
 * Threat detection Auth Guard
 */
export const threatDetectionAuthGuard: CanActivateFn = async () => {

  const userAuthService = inject(UserAuthService);
  const router = inject(Router);

  const userDetails = await userAuthService.loadUserDetails();
  const userGroups = userAuthService.getUserGroupsFromDetails(userDetails);
  if (userGroups?.includes('Access_CSOC_Reports')) {
    return true;
  }
  return router.parseUrl('/not-authorized');
}

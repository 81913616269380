import { Component, OnInit } from '@angular/core';
import { HttpUsersPreferencesService } from 'src/app/shared/httpServices/http-users-preferences.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ModalService } from 'src/app/shared/modals/modal.service';
import { GlobalFeedbackParameters } from 'src/app/shared/modals/modal';
import { Feedback } from '../add-feedback/add-feedback.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { IDropdownSettings, NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { CommonModule } from '@angular/common';
import { FeedbackPipes } from 'src/app/shared/pipes/feedback.pipe';
import { FormsModule } from '@angular/forms';


@Component({
  selector: 'app-feedback-summary',
  standalone: true,
  imports: [CommonModule, FormsModule, FeedbackPipes, TranslateModule, NgMultiSelectDropDownModule],
  templateUrl: './feedback-summary.component.html',
  styleUrls: ['./feedback-summary.component.scss']
})
export class FeedbackSummaryComponent implements OnInit {

  public feedbacks: Feedback[];
  public loaded = false;
  public mode = 'open';

  public actionlabel: string;

  //dropdowns
  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    selectAllText: 'Select all',
    unSelectAllText: 'Unselect all',
    enableCheckAll: true,
    itemsShowLimit: 4,
    allowSearchFilter: false,
  };

  feedbackFilter = new FeedbackFilter();

  public pageType = [];
  public categoryTypes = [];

  constructor(
    private httpUsersPreferencesService: HttpUsersPreferencesService,
    private modalService: ModalService,
    private alertService: AlertService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.loadFeedbacks();
    this.actionlabel = this.translateService.instant('buttons.action.archive');
  }

  /**
   * load all opened or archived feedback
   */
  private loadFeedbacks() {
    this.loaded = false;
    this.httpUsersPreferencesService.getfeedback(this.mode)
      .then(response => {

        this.initFilters(response);

        response.forEach(feedback => {
          let metadata = '#' + feedback.page;
          if (feedback.subpage) {
            metadata += ' #' + feedback.subpage;
          }
          metadata += ' #' + feedback.category;
          feedback.metadata = metadata;
        });
        this.feedbacks = response;
      })
      .catch(error => {
        this.alertService.handlerError(error);
      })
      .finally(() => {
        this.loaded = true;
      });
  }

  /**
   * init page and category multiselects and filters
   */
  private initFilters(feedbacks: Feedback[]) {
    this.pageType = [... new Set(feedbacks.map(e => e.page))];
    this.feedbackFilter.pages = this.pageType;
    this.categoryTypes = [... new Set(feedbacks.map(e => e.category))];
    this.feedbackFilter.categories = this.categoryTypes;
  }

  /**
   * open an existing feedback
   */
  public openFeedback(feedback) {
    const params = new GlobalFeedbackParameters('feedback', feedback, this.mode);
    const self = this;
    params.closeCallback = () => {
      self.loadFeedbacks();
    };
    this.modalService.openGlobalFeedback(params);
  }

  /**
   * update state of an existing feedback
   */
  public updateFeedbackState(feedback: Feedback) {
    feedback.state = this.mode === 'open' ? 'archived' : 'open';
    this.httpUsersPreferencesService.postfeedback(feedback)
      .then(() => {
        if(this.mode === 'open') {
          this.alertService.addSuccess(this.translateService.instant('modals.feedbackMenu.feedback.archiveSucceed'));
        } else {
          this.alertService.addSuccess(this.translateService.instant('modals.feedbackMenu.feedback.openSucceed'));
        }
      })
      .catch(() => {
        this.alertService.addError(this.translateService.instant('modals.feedbackMenu.feedback.archiveFailed'));
      })
      .then(() => {
        this.loadFeedbacks();
      });
  }

  /**
   * delete an existing feedback
   */
  public deleteFeedback(id: string) {
    this.httpUsersPreferencesService.deletefeedback(id)
      .then(() => {
        this.alertService.addSuccess(this.translateService.instant('modals.feedbackMenu.feedback.deleteSucceed'));
      })
      .catch(() => {
        this.alertService.addError(this.translateService.instant('modals.feedbackMenu.feedback.deleteFailed'));
      })
      .then(() => {
        this.loadFeedbacks();
      });
  }

  /**
   * switch view between open feedbacks or archived feedbacks
   * @param mode : open or archived
   */
  public changeMode(mode: string) {
    if (this.mode !== mode) {
      this.mode = mode;
      if (this.mode === 'open') {
        this.actionlabel = this.translateService.instant('buttons.action.archive');
      } else {
        this.actionlabel = this.translateService.instant('buttons.action.open');
      }

      this.loadFeedbacks();
    }
  }


}

export class FeedbackFilter {
  search: string;
  pages: string[];
  categories: string[];

  constructor() {
    this.search = '';
    this.pages = [];
    this.categories = [];
  }
}

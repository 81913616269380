import { Component } from '@angular/core';
import * as Highcharts from 'highcharts';
import HC_heatmap from 'highcharts/modules/heatmap';
import { BaseChartComponent } from '../basechart/basechart.component';
import { getDefaultHighchartOptions } from '../../utils';
import { XtendedFacet } from 'src/app/shared/model/cybersocxdr/facets';
HC_heatmap(Highcharts);

@Component({
  selector: 'app-incidentbyseveritycategory',
  templateUrl: '../basechart/basechart.component.html',
})
export class CybersocxdrIncidentBySeverityCategoryComponent extends BaseChartComponent<
  Array<[string, string | number, number]>
> {
  severityCategories = ['low', 'medium', 'high', 'critical'];

  draw(renderingId: string) {
    // Build a set of categories
    const xdrCategories = [];
    const severities = [];
    for (let data of this.data) {
      if (xdrCategories.findIndex((category) => category === data[0]) === -1) {
        xdrCategories.push(data[0]);
      }
      if (severities.findIndex((severity) => severity === data[1]) === -1) {
        severities.push(data[1]);
      }
    }

    this.options = {
      chart: {
        type: 'heatmap',
        marginTop: 40,
        marginBottom: 80,
        plotBorderWidth: 1,
      },

      title: {
        text: this.title,
      },

      xAxis: {
        type: 'category',
        categories: xdrCategories,
      },

      yAxis: {
        categories: severities,
        title: null,
        reversed: true,
      },

      accessibility: {
        point: {
          descriptionFormatter: (point) => point.value.toString(),
        },
      },

      colorAxis: {
        min: 0,
        minColor: this.context.isDarkTheme() ? '#999' : '#FFFFFF',
        maxColor: getDefaultHighchartOptions().colors[0],
      },

      legend: {
        align: 'right',
        layout: 'vertical',
        margin: 0,
        verticalAlign: 'top',
        y: 25,
        symbolHeight: 280,
      },

      tooltip: {
        formatter() {
          return (
            '<b>There are <b>' +
            this.point.value +
            '<b> incidents <b>' +
            getPointCategoryName(this.point, 'y') +
            '</b><br><b>' +
            ' for category ' +
            getPointCategoryName(this.point, 'x')
          );
        },
      },

      series: [
        {
          type: 'heatmap',
          name: 'Incidents',
          borderWidth: 1,
          dataLabels: {
            enabled: true,
            color: '#000000',
          },
          data: this.data.map(([x, y, value]) => {
            return {
              x: xdrCategories.findIndex((category) => category === x),
              y: severities.findIndex((severity) => severity === y),
              id: `${x};${y}`,
              value: value,
              facets: [XtendedFacet.CATEGORY, XtendedFacet.SEVERITY],
              facetValues: [x, y],
            };
          }),
        },
      ],

      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              yAxis: {
                labels: {
                  formatter() {
                    return this.value.toString().charAt(0);
                  },
                },
              },
            },
          },
        ],
      },
    };
  }
}

const getPointCategoryName = (point, dimension) => {
  const series = point.series;
  const isY = dimension === 'y';
  const axis = series[isY ? 'yAxis' : 'xAxis'];
  return axis.categories[point[isY ? 'y' : 'x']];
};

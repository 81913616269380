import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { SeriesColumnOptions } from 'highcharts';
import { Subscription } from 'rxjs';
import { PieDrillDownSerie, RepartitionChartItem } from 'src/app/shared/model/cybersocxdr/charts/common';
import { Contract } from 'src/app/shared/model/cybersocxdr/clientInfo';
import { XtendedFacet } from 'src/app/shared/model/cybersocxdr/facets';
import { IncidentDataSource } from 'src/app/shared/model/cybersocxdr/incidentDataSourceEnum';
import { OpenedIncident } from 'src/app/shared/model/cybersocxdr/openedIncident';
import { constants } from '../../../shared/constants';
import { CybersocXDRDashboardSearchCriteria } from '../../../shared/model/searchCriteria';
import { CybersocxdrService } from '../../../shared/services/xtendedsoc/cybersocxdr.service';
import { Tab } from '../../components/tabbar/tabbar.component';
import { XtendedSearchable } from '../../pages/xtended-page';
import { XtendedHighchartService } from '../../xtended-highchart.service';
import { XtendedFiltersService } from '../../xtendedfilters.service';
import { handlePromises } from '../../utils';

@Component({
  selector: 'app-xtended-dashboard-page',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class XtendedSOCDashboardComponent implements OnInit, XtendedSearchable {
  incidentsOverTimedata: any;
  incidentsRepartitionData: Highcharts.SeriesOptionsType[];
  categoryHeatMapData: any;
  incidentsByEntityData: SeriesColumnOptions[];
  incidentsByCountryData: SeriesColumnOptions[];

  bubbleHostnameData: any;

  groupedDashboardIncidentsRepartition: Highcharts.SeriesPieOptions[];
  groupedDashboardIncidentsRepartitionDrilldown: PieDrillDownSerie[];

  repartitionClassifierNames: string[] = [];

  private contractChangeSubscription: Subscription;
  contract: Contract | null = null;

  tab: string;

  tabs: Tab[] = [
    { titleKey: 'pages.cybersocxdr.dashboard.tabOverview', value: 'overview', url: '/xtended-soc/dashboard/overview' },
  ];
  topIncidentByCountry: RepartitionChartItem[];
  topIncidentByEntity: RepartitionChartItem[];
  top5OpenedIncidents: OpenedIncident[];

  constructor(
    private cybersocxdrService: CybersocxdrService,
    private globalFilters: XtendedFiltersService,
    private route: ActivatedRoute,
    private router: Router,
    private xtendedHighchartService: XtendedHighchartService
  ) {}

  /** Trigger a search for pretty much every graph data */
  async onSearch(searchCriteria: CybersocXDRDashboardSearchCriteria = undefined) {
    if (!searchCriteria) searchCriteria = this.globalFilters.searchCriteria;
    searchCriteria.limit = constants.recordsPerPage.microsocticket;

    if (this.tab === 'overview') {
      await this.searchOverView(searchCriteria);
      if (this.repartitionClassifierNames.length > 0) await this.searchSplitView(searchCriteria);
    } else {
      await this.searchSplitView(searchCriteria);
      await this.searchOverView(searchCriteria);
    }
  }

  /** Update all the fields used to display the high level view tab */
  async searchOverView(criteria: CybersocXDRDashboardSearchCriteria) {
    [this.incidentsOverTimedata, this.categoryHeatMapData, this.bubbleHostnameData, this.top5OpenedIncidents] =
      await handlePromises([
        this.cybersocxdrService.getIncidentsOverTime({ ...criteria, groupbyField: XtendedFacet.SEVERITY }),
        this.cybersocxdrService.getCategoryHeatMap(criteria),
        (
          await this.cybersocxdrService.getVolumeBubbles(criteria)
        ).map((data) =>
          this.xtendedHighchartService.packedBubbleChartSerie(data, [XtendedFacet.SEVERITY, XtendedFacet.CATEGORY])
        ),
        this.cybersocxdrService.getTop5OpenedIncidents(criteria),
      ]);
  }

  /** Update all fields related to the backlog view tab */
  async searchSplitView(criteria: CybersocXDRDashboardSearchCriteria) {
    [this.incidentsRepartitionData, this.incidentsByCountryData, this.topIncidentByCountry] = await Promise.all([
      this.cybersocxdrService.getIncidentsRepartitions(criteria),
      this.xtendedHighchartService.barOvertimeChartSerie(
        await this.cybersocxdrService.getCasesGroupByOvertime(criteria, XtendedFacet.GROUPLEVEL1),
        XtendedFacet.GROUPLEVEL1
      ),
      this.cybersocxdrService.getIncidentsRepartitions(criteria),
    ]);
    if (this.repartitionClassifierNames.length > 1) {
      [this.incidentsByEntityData, this.topIncidentByEntity] = await Promise.all([
        this.xtendedHighchartService.barOvertimeChartSerie(
          await this.cybersocxdrService.getCasesGroupByOvertime(criteria, XtendedFacet.GROUPLEVEL2),
          XtendedFacet.GROUPLEVEL2
        ),
        this.cybersocxdrService.getCasesGroupBy(criteria, XtendedFacet.GROUPLEVEL2),
      ]);
    }

    const searchCriteria = this.globalFilters.searchCriteria;

    const data = (await this.cybersocxdrService.getIncidentsRepartitions(searchCriteria)).slice(0, 5);

    this.groupedDashboardIncidentsRepartition = [
      this.xtendedHighchartService.pieChartSerie(data, XtendedFacet.GROUPLEVEL1),
    ];

    this.groupedDashboardIncidentsRepartitionDrilldown = this.xtendedHighchartService.pieChartDrilldownSerie(data);
  }

  async ngOnInit() {
    this.tab = 'overview';

    this.contract = this.globalFilters.selectedContract;
    this.contractChangeSubscription = this.globalFilters.contractChangeEvent.subscribe((contract) => {
      this.contract = contract;
      if (this.contract?.incidentDataSource === IncidentDataSource.HUBZH) {
        this.tab = 'overview';
        this.router.navigate(['/xtended-soc', 'dashboard', 'overview']);
      }
    });

    //Liste des classifiers
    this.repartitionClassifierNames = await this.cybersocxdrService.getGroupByFilterTypes();

    this.onSearch();

    if (this.repartitionClassifierNames.length > 0) {
      this.tabs.push({
        titleKey: 'pages.cybersocxdr.dashboard.tabSplitView',
        value: 'splitView',
        url: '/xtended-soc/dashboard/splitView',
        isVisible: () => this.contract?.incidentDataSource !== IncidentDataSource.HUBZH,
      });
    }

    this.route.paramMap.subscribe((params: ParamMap) => {
      const tab = params.get('tab');
      if (this.contract?.incidentDataSource === IncidentDataSource.HUBZH) {
        this.router.navigate(['/xtended-soc', 'dashboard', 'overview']);
      } else if ((this.repartitionClassifierNames.length > 0 && tab === 'splitView') || tab === 'overview') {
        this.tab = tab;
      } else {
        this.router.navigate(['/xtended-soc', 'dashboard', 'overview']);
      }
    });
  }

  ngOnDestroy() {
    if (this.contractChangeSubscription) this.contractChangeSubscription.unsubscribe();
  }
}

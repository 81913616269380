<div class="m-auto" style="max-width: 1800px">
  <div class="container bg-body-tertiary border-0 mw-100">
    <div class="row justify-content-between">
      <div class="col-12 col-lg-7">
        <app-card
          class="h-100"
          title="pages.cybersocxdr.incidents.reportedIncidents"
          actionTitle="pages.cybersocxdr.home.viewAllIncidents"
          actionRoute="/xtended-soc/incidents"
        >
          <div class="row">
            @for (severity of severitiesCount; track $index ){
            <div class="col-12 col-sm-6 col-lg-3 mt-lg-0 mt-2">
              <div
                [class]="
                  'rounded-3 h-100 p-3 position-relative ' +
                  (xtendedHighchartService.severityFontWhite(severity)
                    ? 'text-white'
                    : 'text-dark')
                "
                [style]="
                  'background-color: ' +
                  xtendedHighchartService.severityColor(severity)
                "
              >
                <div class="justify-content-between text-center">
                  <div class="fs-1 fw-bold mb-2">
                    {{ incidentsCounts?.[severity]?.periodTotal }}
                  </div>
                  <div>
                    {{ severity | titlecase }}
                    <svg
                      *ngIf="incidentsCounts?.critical?.difference < 0"
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      class="solaris-icon si-download"
                      viewBox="0 0 1000 1000"
                    >
                      <path
                        [attr.d]="incidentsCounts?.[severity]?.difference < 0 
                        ? 'M300 524V174a75.221 75.221 0 0 1 75-75h250a75.221 75.221 0 0 1 75 75v350h225L567.862 871.862A98.064 98.064 0 0 1 500 899a98.065 98.065 0 0 1-67.857-27.141L75 524z' 
                        : 'M700 450v350a75.22 75.22 0 0 1-75 75H375a75.22 75.22 0 0 1-75-75V450H75l357.138-347.862A98.064 98.064 0 0 1 500 75a98.066 98.066 0 0 1 67.857 27.141L925 450z'"
                        style="fill-rule: evenodd"
                        [attr.fill]="
                          xtendedHighchartService.severityFontWhite(severity)
                            ? '#ffffff'
                            : '#000000'
                        "
                      />
                    </svg>

                    {{ incidentsCounts?.[severity]?.difference }}
                  </div>
                </div>
              </div>
            </div>
            }
          </div>
        </app-card>
      </div>
      <div class="col-12 col-lg-5 mt-3 m-lg-0">
        <app-card
          class="h-100"
          title="pages.cybersocxdr.incidents.incidentOpenedAndClosed"
        >
          <div class="row">
            <div class="col mt-sm-0 mt-2">
              <div
                class="rounded-3 h-100 p-3 border border-primary bg-primary"
                style="--bs-bg-opacity: 0.2"
              >
                <div class="mb-2">
                  <span class="fs-5 fw-bold">{{
                    'pages.cybersocxdr.incidents.openedIncidents' | translate
                  }}</span>
                </div>
                <div class="d-flex gap-3">
                  <div class="fs-1 fw-bold">
                    {{ backlogCurrentPage?.total }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col mt-sm-0 mt-2">
              <div
                class="rounded-3 h-100 p-3 border border-light bg-light"
                style="--bs-bg-opacity: 0.2"
              >
                <div class="mb-2">
                  <span class="fs-5 fw-bold">{{
                    'pages.cybersocxdr.incidents.closedIncidents' | translate
                  }}</span>
                </div>
                <div class="d-flex gap-3">
                  <div class="fs-2 fw-bold">
                    {{
                      (incidentsCurrentPage?.total ?? 0) -
                        (backlogCurrentPage?.total ?? 0)
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </app-card>
      </div>
    </div>
    @if(contract?.incidentDataSource === IncidentDataSource.ELASTIC){
    <div class="row">
      <div class="col-12 col-md-7">
        <app-filtermenu>
          <app-card title="pages.cybersocxdr.incidents.openedIncidentsByStatus">
            <div class="mb-4">
              <app-barovertime
                [isStacking]="false"
                [data]="incidentsByEntityData"
                suffix="jours"
                titleY="Opened incident"
              />
            </div>
          </app-card>
        </app-filtermenu>
      </div>

      <div class="col-12 col-md-5 mt-3 mt-md-0">
        <app-filtermenu>
          <app-card
            title="{{
              'pages.cybersocxdr.dashboard.openedIncidentsBy'
                | translate : { groupBy: groupBy.replace('_', ' ') }
            }}"
          >
            <select
              class="form-select rounded"
              style="max-width: fit-content"
              (change)="updateGroupBy($event.target.value)"
              action
            >
              <option *ngFor="let option of groupByOptions" [value]="option">
                {{ option.replace('_', ' ') | titlecase }}
              </option>
            </select>
            <div class="mb-4">
              <app-pie [data]="groupedIncidents" suffix="" innerSize="0" />
            </div>
          </app-card>
        </app-filtermenu>
      </div>
    </div>
    }
  </div>
</div>

<div class="app-incident" id="incidents-table" name="incidents-table">
  <div *ngIf="page === 'backlog' || page === 'flagged' || page === 'all'">
    <app-card class="mw-100">
      <div class="row px-2 mb-2 overflow-x-auto">
        <ul class="nav nav-underline flex-nowrap">
          <li class="nav-item">
            <button
              class="nav-link active d-flex align-items-center gap-2 tab-button"
              [ngClass]="{ active: page === 'backlog' }"
              (click)="page = 'backlog'; loadPages();"
              aria-current="page"
            >
              {{ 'pages.cybersocxdr.incidents.backlog' | translate }}
              <span
                class="notif tag tag-sm border text-body"
                [ngClass]="{
                  'bg-primary border-primary': page === 'backlog',
                  'bg-light border-light': page !== 'backlog',
                }"
                style="--bs-bg-opacity: 0.2"
                >{{ backlogCurrentPage?.total }}</span
              >
            </button>
          </li>
          <li class="nav-item">
            <button
              class="nav-link d-flex align-items-center gap-2 tab-button"
              [ngClass]="{ active: page === 'all' }"
              (click)="page = 'all'; loadPages()"
            >
              {{ 'pages.cybersocxdr.incidents.all' | translate }}
              <span
                class="notif tag tag-sm border text-body"
                [ngClass]="{
                  'bg-primary border-primary': page === 'all',
                  'bg-light border-light': page !== 'all',
                }"
                style="--bs-bg-opacity: 0.2"
                >{{ incidentsCurrentPage?.total }}</span
              >
            </button>
          </li>
          <li class="nav-item">
            <button
              class="nav-link d-flex align-items-center gap-2 tab-button"
              [ngClass]="{ active: page === 'flagged' }"
              (click)="page = 'flagged'; loadPages()"
            >
              {{ 'pages.cybersocxdr.incidents.flagged' | translate }}
              <span
                class="notif tag tag-sm border text-body"
                [ngClass]="{
                  'bg-primary border-primary': page === 'flagged',
                  'bg-light border-light': page !== 'flagged',
                }"
                style="--bs-bg-opacity: 0.2"
                >{{ flagCurrentPage?.total }}</span
              >
            </button>
          </li>
        </ul>
      </div>

      <div class="col-12 mt-3">
        <app-card class="mw-100">
          <app-pagination-incident-list
            [currentPage]="getDisplayPage()"
            (fetchPage)="loadAppropriateIncidentPage($event)"
            [title]="'pages.cybersocxdr.incidents.' + page"
            [casesCountPrettier]="casesCountPrettier"
            (sortEmitter)="sortCases($event)"
            (newFlagEmitter)="onFlag($event)"
            [selectableColumns]="true"
            [exportable]="
              contract?.incidentDataSource === IncidentDataSource.ELASTIC
            "
            (exportEmitter)="exportCases($event)"
          ></app-pagination-incident-list>
        </app-card>
      </div>
    </app-card>
  </div>
</div>

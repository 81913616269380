import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ModalParameters } from '../shared/modals/modal';
import { ModalService } from '../shared/modals/modal.service';
import { UserAuthService } from 'src/app/shared/services/user-auth.service';
import { LocalStorageItem } from '../shared/model/shared-items';

@Component({
  selector: 'app-tenant',
  templateUrl: './tenant.component.html',
  styleUrls: ['./tenant.component.scss']
})
export class TenantComponent implements OnInit {
  availableTenants: string[];
  selectedTenant: string;
  tenantToSelect: string;

  constructor(
    private router: Router,
    private modalService: ModalService,
    private translate: TranslateService,
    private userAuthService: UserAuthService
  ) {
  }

  ngOnInit() {
    if(this.userAuthService?.availableTenants?.length > 1){
      this.selectedTenant =  this.userAuthService.selectedTenant;
      this.tenantToSelect =  this.userAuthService.selectedTenant;
      this.availableTenants = this.userAuthService.availableTenants;
    }
  }

  /** select a new tenant before confirming */
  selectTenant(){
    this.selectedTenant = this.tenantToSelect;

    const params = new ModalParameters();
    params.title = this.translate.instant('pages.generic.confirm');
    params.bodyMessage = `${this.translate.instant('pages.tenants.confirm')} <b>${this.selectedTenant}</b>?`;
    params.closeBtnLabel = this.translate.instant('pages.generic.cancel');
    params.successBtnLabel = this.translate.instant('pages.generic.confirm');
    params.successCallback = this.switch.bind(this);

    this.modalService.confirmModal(params);
  }

  /** triggers switch into a new tenant */
  switch(){

    this.userAuthService.selectedTenant = this.selectedTenant;
    this.userAuthService.clearStore();

    localStorage.setItem(LocalStorageItem.oktaSelectedTenant, JSON.stringify(this.selectedTenant));
    
    this.router.navigateByUrl('/dashboard').then(() => {
      window.location.reload();
    });

  }

}

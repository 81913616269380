<section>
  <!-- loading -->
  @if(loading) {
  <div class="text-center">
    <div class="spinner-border spinner-border-lg text-primary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
  } @else {

    <!-- import button / drop zone -->
    <div class="dragZone btn-hover" [ngClass]="{'selected': file}" appDnd (fileDropped)="onFileDropped($event)">
      <input type="file" #fileDropRef id="fileDropRef" (change)="fileBrowseHandler($event.target.files)" />
      <div class="btn-custom">
        @if(!file) {
        <label for="fileDropRef">{{ 'pages.reports.internalFileTransfer.upload' | translate }}</label>
        } @else {
        <label>{{file.name}}</label>
        }
        <fa-icon class="p-1 symbol" [icon]="['fas', 'upload']" size="2xl"></fa-icon>
      </div>
    </div>
    @if(error) {
      <div class="alert alert-info mt-2" role="alert" (click)="removeError()">
        <span class="alert-icon"><span class="visually-hidden">Error</span></span>
        <p>{{error}}</p>
      </div>
    }
  }
</section>
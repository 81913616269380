import { AfterViewChecked, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TrainingItem } from '../trainingdashboard/trainingdashboard.component';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import trainingConstants from 'src/assets/training-list.json';
import { AlertService } from 'src/app/shared/services/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, fromEvent } from 'rxjs';
import { UserActivityService } from 'src/app/shared/services/user-activity.service';
import { ACTIONS, TYPES } from 'src/app/shared/constants';

@Component({
  selector: 'app-training-course',
  templateUrl: './training-course.component.html',
  styleUrls: ['./training-course.component.scss']
})
export class TrainingCourseComponent implements OnInit, AfterViewChecked, OnDestroy {

  @ViewChild('iframeDiv') iframeDiv: ElementRef;
  @ViewChild('endCourseBtn') endCourseBtn: ElementRef;

  public parentItem: TrainingItem;
  public items: TrainingItem[] = [];
  public selectedItem: TrainingItem;
  public showEmbedded: boolean;
  public showEndOfCourseBtn: boolean;
  public url: SafeUrl;

  private resizeSubscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer,
    private alertService: AlertService,
    private translateService: TranslateService,
    private userActivity: UserActivityService) {
  }
  ngAfterViewChecked(): void {
    this.setupBackToTrainingDashboardButton();
  }

  ngOnDestroy(): void {
    this.resizeSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.route.params.subscribe( params => {
      this.parentItem = this.getTraining(params.id);
      this.items.push(this.parentItem);
      if (this.parentItem.children) {
        this.parentItem.children.forEach( itemId => this.items.push(this.getTraining(itemId)));
      }
    });
    this.resizeSubscription = fromEvent(window, 'resize').subscribe(
      () => this.setupBackToTrainingDashboardButton()
    );
    this.userActivity.logActivity(TYPES.training, this.parentItem.title, ACTIONS.visit);
  }

  private getTraining(trainingId: string) {
    const allTrainings =  trainingConstants.trainingList;
    return allTrainings.find( train => train.id === trainingId);
  }

  openCourse(trainingId: string) {
    this.selectedItem = this.items.find( item => item.id === trainingId);
    if (!this.selectedItem.link.includes(trainingConstants.easyGeneratorBaseUrl)) {
      this.alertService.addError(this.translateService.instant('pages.updates.training.unsafeUrlWarning'));
    } else {
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.selectedItem.link);
      this.showEmbedded = true;
      // Scroll to display full embed section
      const scrollAnchor = document.getElementsByTagName('footer')[0];
      scrollAnchor.scrollIntoView({behavior: 'smooth'});
      this.setupBackToTrainingDashboardButton();
    }
  }

  /**
   * Setup the position of the return button overlapping the training-course iframe
   * Meant to be called at iframe display event and at window resized events
   */
  setupBackToTrainingDashboardButton() {
    if (this.showEmbedded) {
      const rightMarginPx = 250;
      const topMarginPercent = 2.5;
      const sectionRect = this.iframeDiv.nativeElement.getBoundingClientRect();
      const xPos = sectionRect.width - (rightMarginPx);
      const yPos = -sectionRect.height + (sectionRect.height * topMarginPercent/100);
      this.endCourseBtn.nativeElement.style.left = `${xPos}px`;
      this.endCourseBtn.nativeElement.style.top = `${yPos}px`;
      this.showEndOfCourseBtn = true;
    }
  }

  closeCourse() {
    this.showEmbedded = false;
    this.showEndOfCourseBtn = false;
  }

  goToTrainingDashboard() {
    this.router.navigate([`/updates/training`]);
  }

}

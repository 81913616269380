@if (isHelpOpen) {
<div
  [ngClass]="contextService.isDarkTheme() ? 'bg-dark' : 'bg-white'"
  class="xtended-help border border-light rounded shadow-sm position-absolute bottom-100 end-0 mb-2 d-flex flex-column"
>
  <div class="d-flex align-items-center mt-3 position-relative">
    @if (selectedFaqIndex !== null) {
    <button
      class="btn btn-link ms-3 text-decoration-none position-absolute start-0"
      (click)="onBack()"
    >
      <fa-icon [icon]="['fas', 'chevron-left']" class="me-2"></fa-icon>
      {{ 'pages.cybersocxdr.faq.back' | translate }}
    </button>
    }

    <h3
      class="w-100 text-center mb-0"
      [ngClass]="contextService.isDarkTheme() ? 'text-light' : 'text-dark'"
    >
      {{ 'pages.cybersocxdr.faq.title' | translate }}
    </h3>
  </div>
  <div class="flex-grow-1 overflow-auto">
    <div class="pb-4">
      @if (selectedFaqIndex === null) {
      <app-xtended-faq-list [faqs]="faqs" (faqSelected)="viewFaq($event)" />
      } @else {
      <app-xtended-faq-preview [faq]="faqs[selectedFaqIndex]" />
      }
    </div>
  </div>
</div>
}
